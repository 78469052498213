/// TYPES ///
import { IHistoryActionType } from "api/types/history";
/// SHOP ACTIONS ///
import { createShopCounter } from "api/actions/shop_quote";
import { updateLocalShopCounter } from "api/actions/shop_quote";
import { updateShopCounter } from "api/actions/shop_quote";
import { createLocalShopCounter } from "api/actions/shop_quote";
import { updateLocalShopCutShapes } from "api/actions/shop_quote";
import { deleteShopCounter } from "api/actions/shop_quote";
import { deleteLocalShopCounter } from "api/actions/shop_quote";
import { deleteLocalShopCutShapes } from "api/actions/shop_quote";
import { createBulkCutShapes } from "api/actions/sketch/cut_shape";
import { createBulkShopCutShapes } from "api/actions/shop_quote";
import { createShopCooktop } from "api/actions/shop_quote";
import { updateShopCooktop } from "api/actions/shop_quote";
import { updateLocalShopCooktop } from "api/actions/shop_quote";
import { deleteShopCooktop } from "api/actions/shop_quote";
import { createShopSink } from "api/actions/shop_quote";
import { updateShopSink } from "api/actions/shop_quote";
import { updateLocalShopSink } from "api/actions/shop_quote";
import { deleteShopSink } from "api/actions/shop_quote";
import { deleteLocalShopSink } from "api/actions/shop_quote";
/// STORE ACTIONS ///
import { createCounter } from "api/actions/sketch/counter";
import { deleteCounter } from "api/actions/sketch/counter";
import { updateCounter } from "api/actions/sketch/counter";
import { createLocalCounter } from "api/actions/sketch/counter";
import { deleteLocalCounter } from "api/actions/sketch/counter";
import { updateLocalCounter } from "api/actions/sketch/counter";
import { updateLocalCutShapes } from "api/actions/sketch/cut_shape";
import { deleteLocalCutShapes } from "api/actions/sketch/cut_shape";
import { createCooktop } from "api/actions/sketch/cooktop";
import { updateCooktop } from "api/actions/sketch/cooktop";
import { updateLocalCooktop } from "api/actions/sketch/cooktop";
import { deleteCooktop } from "api/actions/sketch/cooktop";
import { createSink } from "api/actions/sketch/sink";
import { updateSink } from "api/actions/sketch/sink";
import { updateLocalSink } from "api/actions/sketch/sink";
import { deleteSink } from "api/actions/sketch/sink";
import { deleteLocalSink } from "api/actions/sketch/sink";

export function historyAction(
  action_type: string,
  data: any,
  data2: any,
  dispatch: (({ ...any }) => void)) {
  if (action_type === IHistoryActionType.CREATE_SHOP_COUNTER) {
    dispatch(createShopCounter(data, data2));
    dispatch(createLocalShopCounter(data));
    dispatch(updateLocalShopCutShapes(data2, data.uuid));
  }
  else if (action_type === IHistoryActionType.DELETE_SHOP_COUNTER) {
    dispatch(deleteShopCounter(data?.uuid));
    dispatch(deleteLocalShopCounter(data?.uuid));
    dispatch(deleteLocalShopCutShapes(data?.uuid));
  }
  else if (action_type === IHistoryActionType.MOVE_SHOP_COUNTER) {
    dispatch(updateShopCounter(data));
    dispatch(updateLocalShopCounter(data));
  }
  else if (action_type === IHistoryActionType.UPDATE_SHOP_COUNTER) {
    dispatch(updateShopCounter(data));
    dispatch(updateLocalShopCounter(data));
    dispatch(createBulkShopCutShapes(data2));
    dispatch(updateLocalShopCutShapes(data2, data.uuid));
  }
  else if (action_type === IHistoryActionType.CREATE_SHOP_COOKTOP) {
    dispatch(createShopCooktop(data));
  }
  else if (action_type === IHistoryActionType.UPDATE_SHOP_COOKTOP) {
    dispatch(updateShopCooktop(data));
  }
  else if (action_type === IHistoryActionType.DELETE_SHOP_COOKTOP) {
    dispatch(deleteShopCooktop(data));
  }
  else if (action_type === IHistoryActionType.CREATE_SHOP_SINK) {
    dispatch(createShopSink(data));
  }
  else if (action_type === IHistoryActionType.UPDATE_SHOP_SINK) {
    dispatch(updateShopSink(data));
  }
  else if (action_type === IHistoryActionType.DELETE_SHOP_SINK) {
    dispatch(deleteShopSink(data));
    dispatch(deleteLocalShopSink(data));
  }
  else if (action_type === IHistoryActionType.CREATE_COUNTER) {
    dispatch(createCounter(data, data2));
    dispatch(createLocalCounter(data));
    dispatch(updateLocalCutShapes(data2, data.uuid));
  }
  else if (action_type === IHistoryActionType.DELETE_COUNTER) {
    dispatch(deleteCounter(data?.uuid));
    dispatch(deleteLocalCounter(data?.uuid));
    dispatch(deleteLocalCutShapes(data?.uuid));
  }
  else if (action_type === IHistoryActionType.MOVE_COUNTER) {
    dispatch(updateCounter(data));
    dispatch(updateLocalCounter(data));
  }
  else if (action_type === IHistoryActionType.UPDATE_COUNTER) {
    dispatch(updateCounter(data));
    dispatch(updateLocalCounter(data));
    dispatch(createBulkCutShapes(data2));
    dispatch(updateLocalCutShapes(data2, data.uuid));
  }
  else if (action_type === IHistoryActionType.CREATE_COOKTOP) {
    dispatch(createCooktop(data));
  }
  else if (action_type === IHistoryActionType.UPDATE_COOKTOP) {
    dispatch(updateCooktop(data));
  }
  else if (action_type === IHistoryActionType.DELETE_COOKTOP) {
    dispatch(deleteCooktop(data));
  }
  else if (action_type === IHistoryActionType.CREATE_SINK) {
    dispatch(createSink(data));
  }
  else if (action_type === IHistoryActionType.UPDATE_SINK) {
    dispatch(updateSink(data));
  }
  else if (action_type === IHistoryActionType.DELETE_SINK) {
    dispatch(deleteSink(data));
    dispatch(deleteLocalSink(data));
  }
}

export function restoreAction(
  action_type: string,
  data: any,
  data2: any,
  dispatch: (({ ...any }) => void)) {
  if (action_type === IHistoryActionType.UPDATE_SHOP_COUNTER) {
    dispatch(updateLocalShopCounter(data));
  }

  else if (action_type === IHistoryActionType.UPDATE_SHOP_COOKTOP) {
    dispatch(updateLocalShopCooktop(data));
  }
  else if (action_type === IHistoryActionType.UPDATE_SHOP_SINK) {
    dispatch(updateLocalShopSink(data));
  }
  else if (action_type === IHistoryActionType.UPDATE_COUNTER) {
    dispatch(updateLocalCounter(data));
  }
  else if (action_type === IHistoryActionType.UPDATE_COOKTOP) {
    dispatch(updateLocalCooktop(data));
  }
  else if (action_type === IHistoryActionType.UPDATE_SINK) {
    dispatch(updateLocalSink(data));
  }
}

