import { Bump } from "api/types/sketch";

export enum ActionType {
  CREATE_LOCAL_BUMP = "CREATE_LOCAL_BUMP",
  UPDATE_LOCAL_BUMP = "UPDATE_LOCAL_BUMP",
  DELETE_LOCAL_BUMP = "DELETE_LOCAL_BUMP"
}

interface ICreateLocalBump {
  type: ActionType.CREATE_LOCAL_BUMP,
  payload: Bump
}

interface IUpdateLocalBump {
  type: ActionType.UPDATE_LOCAL_BUMP,
  payload: Bump,
  corner_uuid: string,
  counter_uuid: string
}

interface IDeleteLocalBump {
  type: ActionType.DELETE_LOCAL_BUMP,
  payload: Bump
}

export type Action =
  ICreateLocalBump |
  IUpdateLocalBump |
  IDeleteLocalBump;
