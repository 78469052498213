/// REACT COMPONENTS ///
import { Fragment } from "react";
/// PDF /// 
import { Image } from "@react-pdf/renderer";
import { StyleSheet } from "@react-pdf/renderer";
import { Text } from "@react-pdf/renderer";
import { View } from "@react-pdf/renderer";
/// FUNCTIONS ///
import { getDate } from "functions/date";
import { getExpiryDate } from "functions/date";
/// COLORS ///
import { primary_color } from "styles/style";
import { light_grey_color } from "styles/style";
import { white_color } from "styles/style";

interface Props {
  title: string,
  number: string,
  logo_url: string,
  seller_name: string,
  seller_address: string,
  seller_city: string,
  seller_province: string,
  seller_postal_code: string,
  seller_phone_number: string,
  seller_email: string,
  buyer_name: string,
  buyer_address: string,
  buyer_city: string,
  buyer_province: string,
  buyer_postal_code: string,
  buyer_email: string,
  buyer_phone_number: string,
  project_address: string,
  project_city: string,
  project_province: string,
  project_postal_code: string,
  project_phone_number: string,
  project_email: string,
  date: string
}

export default function WOPrintHeader({
  title,
  number,
  logo_url,
  seller_name,
  seller_address,
  seller_city,
  seller_province,
  seller_postal_code,
  seller_email,
  seller_phone_number,
  buyer_name,
  buyer_address,
  buyer_city,
  buyer_province,
  buyer_postal_code,
  buyer_email,
  buyer_phone_number,
  project_address,
  project_city,
  project_province,
  project_postal_code,
  project_phone_number,
  project_email,
  date
}: Props) {
  const styles = StyleSheet.create({
    big_title: {
      fontFamily: "Montserrat",
      color: white_color,
      fontSize: 20
    },
    title: {
      fontWeight: "bold",
      fontSize: 10.8,
      fontFamily: "Montserrat",
      textTransform: "uppercase"
    },
    body2: {
      fontFamily: "Montserrat",
      fontSize: 10.5
    },
    body2_white: {
      fontFamily: "Montserrat",
      fontSize: 10,
      color: white_color
    },
    logo: {
      height: "60px",
      width: "120px",
      objectFit: "contain",
      objectPosition: "left"
    },
    row: {
      marginTop: "5px",
      display: "flex",
      flexDirection: "row"
    }
  });

  return (
    <Fragment>
      <View style={{ display: "flex", flexDirection: "row", alignItems: "center", paddingLeft: "8px" }}>
        <View style={{ width: "50%", display: "flex", justifyContent: "center", alignItems: "flex-start", height: "70px" }}>
          {
            logo_url !== null && logo_url !== undefined && logo_url !== "" ?
              <Image src={logo_url} style={styles.logo} /> :
              null
          }
        </View>
        <View style={{ display: "flex", width: "50%", height: "70px", justifyContent: "center", alignItems: "center", backgroundColor: primary_color }}>
          <Text style={styles.big_title}>
            {title}
          </Text>
          <Text style={styles.body2_white}>
            Invoice #: {number}
          </Text>
        </View>
      </View>
      <View style={{ width: "100%", height: "10px" }}></View>
      <View style={{ display: "flex", flexDirection: "row", paddingLeft: "10px" }}>
        <View style={{ width: "50%", height: "100px", paddingTop: "5px" }}>
          <Text style={styles.title}>
            Issue From
          </Text>
          <Text style={styles.body2}>
            {seller_name}
          </Text>
          <Text style={styles.body2}>
            {seller_address}
          </Text>
          <Text style={styles.body2}>
            {seller_city}, {seller_province}
          </Text>
          <Text style={styles.body2}>
            {seller_postal_code}
          </Text>
          <Text style={styles.body2}>
            {seller_email}
          </Text>
          <Text style={styles.body2}>
            {seller_phone_number}
          </Text>
        </View>
        <View style={{ textAlign: "right", width: "50%", height: "100px", backgroundColor: light_grey_color, paddingTop: "5px", paddingRight: "10px" }}>
          <Text style={styles.title}>
            Issued To
          </Text>
          <Text style={styles.body2}>
            {buyer_name}
          </Text>
          <Text style={styles.body2}>
            {buyer_address}
          </Text>
          <Text style={styles.body2}>
            {`${buyer_city}, ${buyer_province}`}
          </Text>
          <Text style={styles.body2}>
            {buyer_postal_code}
          </Text>
          <Text style={styles.body2}>
            {buyer_email}
          </Text>
          <Text style={styles.body2}>
            {buyer_phone_number}
          </Text>
        </View>
      </View>
      <View style={{ display: "flex", flexDirection: "row", paddingLeft: "10px", paddingTop: "20px" }}>
        <View style={{ width: "50%", height: "90px", paddingTop: "5px" }}>
          <Text style={styles.title}>
            Project Info
          </Text>
          <Text style={styles.body2}>
            {project_address}
          </Text>
          <Text style={styles.body2}>
            {project_city}, {project_province}
          </Text>
          <Text style={styles.body2}>
            {project_postal_code}
          </Text>
          <Text style={styles.body2}>
            {project_phone_number}
          </Text>
          <Text style={styles.body2}>
            {project_email}
          </Text>
        </View>
        <View style={{ width: "50%", paddingRight: "10px", textAlign: "right" }}>
          <Text style={styles.body2}>
            Created on: {getDate(date)}
          </Text>
          <Text style={styles.body2}>
            Expires on: {getExpiryDate(date)}
          </Text>
        </View>
      </View>
      <View style={styles.row}>
      </View>
    </Fragment>
  );
}
