import { v4 } from "uuid";
import { Point } from "api/types/sketch";
import { Seam } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { getDistance } from "../math";
import { getDistantPoint } from "../math";
import { getAngle } from "../math";
import { toRadians } from "../math";
import { getLateralLeftVertex } from "../math";
import { getLateralRightVertex } from "../math";
import { getLineIntersection } from "../math";

export function drawCornerSeamPreview(
  seam_path: Path2D,
  previous_point: Point,
  point: Point,
  previous_location: Vector2,
  next_location: Vector2,
  zoom: number
): Seam | null {
  const prev_left_wing: Vector2 = getLateralLeftVertex(previous_location, previous_point.angle, previous_point.width * zoom);
  const prev_right_wing: Vector2 = getLateralRightVertex(previous_location, previous_point.angle, previous_point.width * zoom);
  const next_left_wing: Vector2 = getLateralLeftVertex(next_location, point.angle, point.width * zoom);
  const next_right_wing: Vector2 = getLateralRightVertex(next_location, point.angle, point.width * zoom);
  const start: Vector2 = getLineIntersection(
    prev_left_wing,
    getDistantPoint(prev_left_wing, previous_point.angle, 2000),
    next_left_wing,
    getDistantPoint(next_left_wing, point.angle, 2000)
  );
  const end: Vector2 = getLineIntersection(
    prev_right_wing,
    getDistantPoint(prev_right_wing, previous_point.angle, 2000),
    next_right_wing,
    getDistantPoint(next_right_wing, point.angle, 2000)
  );

  seam_path.moveTo(start.X, start.Y);
  seam_path.lineTo(end.X, end.Y);

  if (point.uuid) {
    return {
      point_uuid: point.uuid,
      point_angle: point.angle,
      point_width: point.width,
      distance_from_point: 0,
      is_on_corner: true,
      uuid: v4()
    }
  }
  return null;
}

export function seamPreview(
  seam_path: Path2D,
  closest_point: Point,
  point_location: Vector2,
  mouse: Vector2,
  zoom: number
): Seam | null {
  const angle_to_mouse: number = getAngle(point_location, mouse);
  const angle_difference: number = Math.abs(angle_to_mouse - closest_point.angle);
  const distance_to_mouse: number = Math.abs(getDistance(mouse, point_location));
  const distance_from_point: number = Math.abs(Math.sin(toRadians(angle_difference)) * distance_to_mouse);

  const middle_mouse: Vector2 = getDistantPoint(point_location, closest_point.angle, Math.abs(distance_from_point));
  const start: Vector2 = getDistantPoint(middle_mouse, closest_point.angle + 90, closest_point.width / 2 * zoom);
  const end: Vector2 = getDistantPoint(middle_mouse, closest_point.angle - 90, closest_point.width / 2 * zoom);

  seam_path.moveTo(start.X, start.Y);
  seam_path.lineTo(end.X, end.Y);

  if (closest_point.uuid) {
    return {
      point_uuid: closest_point.uuid,
      point_angle: closest_point.angle,
      point_width: closest_point.width,
      distance_from_point: distance_from_point,
      is_on_corner: false,
      uuid: v4()
    };
  }
  return null;
}

export function drawSeam(
  seam_path: Path2D,
  point_location: Vector2,
  distance_from_point: number,
  angle: number,
  width: number
) {
  const seam_center: Vector2 = getDistantPoint(point_location, angle, distance_from_point);
  const start: Vector2 = getDistantPoint(seam_center, angle + 90, width / 2);
  const end: Vector2 = getDistantPoint(seam_center, angle - 90, width / 2);
  seam_path.moveTo(start.X, start.Y);
  seam_path.lineTo(end.X, end.Y);
}

export function drawCornerSeam(
  seam_path: Path2D,
  previous_point: Point,
  point: Point,
  previous_location: Vector2,
  next_location: Vector2,
  zoom: number
): Seam | null {
  const prev_left_wing: Vector2 = getLateralLeftVertex(previous_location, previous_point.angle, previous_point.width * zoom);
  const prev_right_wing: Vector2 = getLateralRightVertex(previous_location, previous_point.angle, previous_point.width * zoom);
  const next_left_wing: Vector2 = getLateralLeftVertex(next_location, point.angle, point.width * zoom);
  const next_right_wing: Vector2 = getLateralRightVertex(next_location, point.angle, point.width * zoom);
  const start: Vector2 = getLineIntersection(
    prev_left_wing,
    getDistantPoint(prev_left_wing, previous_point.angle, 2000),
    next_left_wing,
    getDistantPoint(next_left_wing, point.angle, 2000)
  );
  const end: Vector2 = getLineIntersection(
    prev_right_wing,
    getDistantPoint(prev_right_wing, previous_point.angle, 2000),
    next_right_wing,
    getDistantPoint(next_right_wing, point.angle, 2000)
  );

  seam_path.moveTo(start.X, start.Y);
  seam_path.lineTo(end.X, end.Y);

  if (point.id) {
    return {
      point_uuid: point.uuid,
      point_angle: point.angle,
      point_width: point.width,
      distance_from_point: 0,
      is_on_corner: true,
      uuid: v4()
    }
  }
  return null;
}
