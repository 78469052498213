/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
/// ACTIONS ///
import { getWO } from "api/actions/wo/wo";
import { getWOAreas } from "api/actions/wo/area";
import { getOrderCounters } from "api/actions/order/counter";
import { getOrderSlabs } from "api/actions/order/slab";
import { setBreadcrumbs } from "api/actions/ui/set";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import LoadingPage from "components/universal/loading_page";
import WOSummary from "components/universal/order/wo_summary";
import OrderCountersDiagram from "components/universal/diagrams/order_counter/areas";
import OrderSlabsDiagram from "components/universal/diagrams/order_cut_shape/areas";
import TermsConditions from "components/universal/quote/terms";
import WOHeader from "components/universal/order/wo_header";
/// FM ///
import { motion } from "framer-motion";
/// ICONS ///
import { faPrint } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
/// URLS ///
import { detail_url } from "components/navigation/endpoints";
import { print_url } from "components/navigation/endpoints";
import { wo_url } from "components/navigation/endpoints";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// BREADCRUMBS ///
import { wo_list } from "values/breadcrumbs/wo";
import { wo } from "values/breadcrumbs/wo";

export default function WO() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const {
    areas,
    areas_loaded,
    counters,
    counters_loaded,
    order,
    slabs,
    slabs_loaded
  } = useAppSelector((state) => state.order);

  useEffect(() => {
    if (id) {
      dispatch(getWO(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(setBreadcrumbs([
      wo_list,
      { ...wo, label: order?.name ?? "Work Order", url: wo.url.replace("id", id) }
    ]));
  }, [dispatch, order, id]);

  useEffect(() => {
    if (id) {

      dispatch(getWOAreas(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      dispatch(getOrderCounters(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      dispatch(getOrderSlabs(id));
    }
  }, [dispatch, id]);

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <LoadingPage loading={!areas_loaded || !counters_loaded || !slabs_loaded}>
        <Grid container>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end" sx={{ paddingBottom: "10px" }}>
              <BaseButton text="Print" icon={faPrint} clickEvent={() => window.open(`/${wo_url}/${detail_url}/${id}/${print_url}`, '_blank')} />
            </Box>
          </Grid>
          <WOHeader
            invoice_number={order?.order_number ?? ""}
            dealer={order?.vendor_data ?? {}}
            fabricator={order?.fabricator_data ?? {}}
            job={order?.job_data ?? {}}
            created={order?.created ?? ""} />
          <WOSummary order={order} areas={areas} />
          <Grid item xs={12}>
            <OrderCountersDiagram areas={areas} counters={counters} counters_loaded={counters_loaded} />
          </Grid>
          <Grid item xs={12}>
            <OrderSlabsDiagram areas={areas} slabs={slabs} shapes_loaded={slabs_loaded} />
          </Grid>
          <TermsConditions payment_terms={order?.fabricator_data?.payment_terms ?? ""} title="Fabricator Terms & Conditions" />
        </Grid>
      </LoadingPage>
    </motion.div>
  )
}
