/// REDuX ///
import { useAppSelector } from "api/hooks/apiHook";
/// MUI  ///
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Fab from "@mui/material/Fab";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// ICONS ///
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
/// ICONS ///
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { faFont } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { actionBarStyle } from "styles/sketch";
import { secondary_color } from "styles/style";

interface Props {
  disabled: boolean,
  loaded: boolean,
  zoomIn: () => void,
  zoomOut: () => void,
  openLabel: () => void,
  redo: () => void,
  undo: () => void,
  copying: boolean,
  toggleCopy: () => void
}

export default function SketchActions({
  disabled,
  loaded,
  zoomIn,
  zoomOut,
  openLabel,
  redo,
  undo,
  copying,
  toggleCopy
}: Props) {
  const { undo_history } = useAppSelector(state => state.history);
  const { redo_history } = useAppSelector(state => state.history);

  return (
    <Box sx={actionBarStyle}>
      <Tooltip title="Zoom In">
        <Fab
          size="small"
          disabled={disabled}
          color="primary"
          aria-label="zoom-in"
          onClick={zoomIn}>
          <AddIcon />
        </Fab>
      </Tooltip>
      <Tooltip title="Zoom Out">
        <Fab
          size="small"
          disabled={disabled}
          color="primary"
          aria-label="zoom-out"
          onClick={zoomOut}>
          <RemoveIcon />
        </Fab>
      </Tooltip>
      <Tooltip title="Create Label">
        <Fab
          size="small"
          disabled={disabled}
          color="primary"
          aria-label="label"
          onClick={openLabel}>
          <FontAwesomeIcon icon={faFont} />
        </Fab>
      </Tooltip>
      <Tooltip title="Undo">
        <Fab
          size="small"
          disabled={!loaded || disabled || undo_history.length < 1}
          color="primary"
          aria-label="undo"
          onClick={undo}>
          <UndoIcon />
        </Fab>
      </Tooltip>
      <Tooltip title="Redo">
        <Fab
          size="small"
          disabled={!loaded || disabled || redo_history.length < 1}
          color="primary"
          aria-label="redo"
          onClick={redo}>
          <RedoIcon />
        </Fab>
      </Tooltip>
      {
        <Tooltip title="Copy Mode">
          <Fab
            sx={{ boxShadow: copying ? `0px 0px 1px 4px ${secondary_color}` : "none" }}
            size="small"
            color="primary"
            aria-label="copy_mode"
            onClick={toggleCopy}>
            <FontAwesomeIcon icon={faCopy} />
          </Fab>
        </Tooltip>
      }
    </Box>
  )
}
