/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
/// MUI ///
import Snackbar from "@mui/material/Snackbar";
/// ACTIONS ///
import { closeAlert } from "api/actions/history";

interface Props {
  index: number,
  active: boolean,
  message: string
}

export default function HistorySnackbar({ index, active, message }: Props) {
  const dispatch = useAppDispatch();

  return (
    <Snackbar
      sx={{ marginLeft: `50px`, marginBottom: "25px" }}
      open={active}
      autoHideDuration={3500}
      onClose={() => dispatch(closeAlert())}
      message={message} />
  );
}
