/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
/// ACTIONS ///
import { getWO } from "api/actions/wo/wo";
import { getWOCounters } from "api/actions/wo/counter";
import { getWOAreas } from "api/actions/wo/area";
import { getWOSlabs } from "api/actions/wo/slab";
/// MUI COMPONENTS ///
import Box from "@mui/material/Box";
/// COMPONENTS ///
import LoadingPage from "components/universal/loading_page";
import PrintLineItems from "components/universal/print/line_items";
import WOPrintHeader from "components/universal/print/header/wo";
import PrintSummaryHeaders from "components/universal/print/summary_headers";
import PrintSummary from "components/universal/print/summary";
import PrintTerms from "components/universal/print/terms";
import OrderPrintAreas from "components/universal/diagrams/print_order_counter/areas";
import OrderPrintShapeAreas from "components/universal/diagrams/print_order_cut_shapes/areas";
/// PDF ///
import { PDFViewer } from "@react-pdf/renderer";
import { Page } from "@react-pdf/renderer";
import { Document } from "@react-pdf/renderer";
import { StyleSheet } from "@react-pdf/renderer";
import { Font } from "@react-pdf/renderer";
import { Image } from "@react-pdf/renderer";
/// IMAGE ///
import Logo from "static/logo_icon.png";
/// FM ///
import { motion } from "framer-motion";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// STYLES ///
import { content_container_style } from "styles/universal/page";

export default function WOPrint() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [logo, setLogo] = useState<string | null>(null);
  const [logo_read, setLogoRead] = useState<boolean>(false);
  const {
    areas,
    areas_loaded,
    counters,
    counters_loaded,
    order,
    slabs,
    slabs_loaded
  } = useAppSelector((state) => state.order);

  useEffect(() => {
    if (id) {
      dispatch(getWO(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      dispatch(getWOAreas(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      dispatch(getWOCounters(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      dispatch(getWOSlabs(id));
    }
  }, [dispatch, id]);

  Font.register({
    family: "Montserrat",
    fonts: [
      {
        src: "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-Y3tcoqK5.ttf",
      },
      {
        src: "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-Y3tcoqK5.ttf",
        fontWeight: "bold"
      }
    ]
  });

  useEffect(() => {
    if (order?.vendor_data?.logo && typeof (order?.vendor_data?.logo) === "string") {
      fetch(order?.vendor_data?.logo).then(response => {
        return response.blob();
      }).then(blob => {
        const blob_url: string = URL.createObjectURL(blob);
        setLogo(blob_url);
        setLogoRead(true);
      }).catch(() => {
        setLogoRead(true);
      })
    }
    else {
      setLogoRead(true);
    }
  }, [order?.vendor_data?.logo]);

  const styles = StyleSheet.create({
    page: {
      paddingLeft: "40px",
      paddingRight: "40px",
      paddingTop: "40px",
      paddingBottom: "40px"
    },
    title: {
      fontWeight: "bold",
      fontSize: 14
    },
    body2: {
      fontSize: 14
    },
    logo: {
      height: "40px",
      width: "80px",
      objectFit: "contain",
      objectPosition: "left"
    },
    row: {
      marginTop: "25px",
      display: "flex",
      flexDirection: "row"
    },
    font: {
      fontFamily: "Montserrat"
    },
    footer_image: {
      position: "absolute",
      right: 10,
      bottom: 5,
      height: "20px",
      width: "20px"
    }
  });

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <LoadingPage loading={!order?.id || !logo_read || !counters_loaded || !areas_loaded || !slabs_loaded}>
        <Box display="flex" justifyContent="center">
          <PDFViewer width="795px" height="1123px">
            <Document>
              <Page size="A4" style={styles.page}>
                <WOPrintHeader
                  title="WORK ORDER"
                  number={order?.order_number ?? ""}
                  logo_url={logo ? logo : ""}
                  buyer_name={order?.name ?? ""}
                  buyer_address={order?.vendor_data?.address ?? ""}
                  buyer_city={order?.vendor_data?.city ?? ""}
                  buyer_province={order?.vendor_data?.province ?? ""}
                  buyer_postal_code={order?.vendor_data?.postal_code ?? ""}
                  buyer_phone_number={order?.vendor_data?.phone_number ?? ""}
                  buyer_email={order?.vendor_data?.email ?? ""}
                  seller_name={order?.fabricator_data?.business_name ?? ""}
                  seller_address={order?.fabricator_data?.address ?? ""}
                  seller_city={order?.fabricator_data?.city ?? ""}
                  seller_province={order?.fabricator_data?.province ?? ""}
                  seller_postal_code={order?.fabricator_data?.postal_code ?? ""}
                  seller_phone_number={order?.fabricator_data?.phone_number ?? ""}
                  seller_email={order?.fabricator_data?.email ?? ""}
                  project_address={order?.job_data?.geo_address ?? ""}
                  project_city={""}
                  project_province={""}
                  project_postal_code={""}
                  project_phone_number={order?.job_data?.phone_number ?? ""}
                  project_email={order?.job_data?.email ?? ""}
                  date={order?.created ?? ""} />
                <PrintSummaryHeaders />
                <PrintLineItems areas={areas} />
                <PrintSummary
                  total={order?.total ?? 0}
                  tax_name={order?.tax_name ?? "Tax"}
                  tax={order?.tax ?? 0}
                  second_tax_name={order?.second_tax_name ?? "Tax2"}
                  second_tax={order?.second_tax ?? 0}
                  discount={order?.discount ?? 0} />
                <OrderPrintAreas counters={counters} areas={areas} />
                <OrderPrintShapeAreas slabs={slabs} areas={areas} />
                <PrintTerms payment_terms={order?.fabricator_data?.payment_terms ?? ""} title="Fabricator Terms & Conditions" />
                <Image src={Logo} style={styles.footer_image} fixed />
              </Page>
            </Document>
          </PDFViewer>
        </Box>
      </LoadingPage>
    </motion.div>
  );
}
