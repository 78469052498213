/// REACT ///
import { SyntheticEvent } from "react";
import { MouseEvent as ReactMouseEvent } from "react";
import { useEffect } from "react";
import { useState } from "react";
/// ACTIONS ///
import { createShopLabel } from "api/actions/shop_quote";
import { deleteShopLabel } from "api/actions/shop_quote";
import { updateShopLabel } from "api/actions/shop_quote";
import { updateLocalShopLabel } from "api/actions/shop_quote";
import { updateLocalShopCounter } from "api/actions/shop_quote";
import { updateShopCounter } from "api/actions/shop_quote";
/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import useDrawShop from "hooks/draw_shop";
import useMousePosition from "hooks/mouse_position";
/// HANDLERS ///
import { handleCreateShopCounter } from "handler/counter/create";
import { handleDeleteShopCounter } from "handler/counter/delete";
import { handleRotateShopCounter } from "handler/counter/rotate";
// TYPES ///
import { IShopCorner } from "api/types/shop_quote/corner";
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopLabel } from "api/types/shop_quote/label";
import { IShopSlab } from "api/types/shop_quote/slab";
import { HighlightData } from "types/draw";
import { Line } from "api/types/sketch";
import { MouseData } from "types/mouse_position";
import { Vector2 } from "api/types/sketch";
/// MUI ///
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
/// COMPONENTS ///
import ShopAreaBar from "../bar/area";
import CreateShopArea from "../dialog/area/create";
import UpdateShopBump from "../drawer/update_bump";
import LabelMenu from "components/quote/menu/label";
import UpdateShopLabel from "../dialog/label/update";
import UpdateShopCorner from "../drawer/update_corner";
import UpdateShopInnerCorner from "../drawer/update_inner_corner";
import SketchMenu from "components/quote/menu/sketch";
import UpdateShopArea from "../drawer/update_area";
/// FUNCTIONS ///
import { checkIndicatorSelected } from "functions/sketch/check/indicator";
import { getShopCornerByUuid } from "functions/sketch/get/corner";
import { getShopCounterByUuid } from "functions/sketch/get/counter";
import { getNextShopCorner } from "functions/sketch/get/corner";
import { getMouseAtZoom } from "functions/sketch";
import { checkShopCounterClicked } from "functions/sketch/check/counter";
import { duplicateShopCounter } from "functions/sketch/duplicate/counter";
import { getFirstShopSlabInAreas } from "functions/sketch/get/shop_slab";
import { moveVector } from "functions/sketch/move/vector";
import { updateShopCorner } from "functions/sketch/update/shop_corner";
/// STYLES ///
import { sketch_style } from "styles/sketch";
import { canvasStyle } from "styles/sketch";
/// VALUES ///
import { CORNER } from "values/text";
import { getCopyMessage } from "values/text";

interface Props {
  index: number,
  zoom: number,
  current_area: number,
  setCurrentArea: (area: number) => void,
  copying: boolean,
  setCopying: (copying: boolean) => void
}

export default function ShopAngles({ index, zoom, current_area, setCurrentArea, copying, setCopying }: Props) {
  const dispatch = useAppDispatch();
  const [selected_counter, setSelectedCounter] = useState<IShopCounter | null>(null);
  const [selected_line, setSelectedLine] = useState<Line | null>(null);
  const mouse_data: MouseData = useMousePosition();
  const ref = mouse_data.ref;
  const { quote } = useAppSelector((state) => state.shop_quote);
  const { areas } = useAppSelector((state) => state.shop_quote);
  const { counters } = useAppSelector((state) => state.shop_quote);
  const { cut_shapes } = useAppSelector((state) => state.shop_quote);
  const { labels } = useAppSelector((state) => state.shop_quote);
  const [selected_label, setSelectedLabel] = useState<IShopLabel | null>(null);
  const [lifting_label, setLiftingLabel] = useState<boolean>(false);
  const [in_operation, setInOperation] = useState<boolean>(false);
  const [menu_open, setMenuOpen] = useState<boolean>(false);
  const [label_menu_open, setLabelMenuOpen] = useState<boolean>(false);
  const [edit_label_open, setEditLabelOpen] = useState<boolean>(false);
  const [menu_location, setMenuLocation] = useState<Vector2>({ X: 0, Y: 0 });
  const [corner_open, setCornerOpen] = useState<boolean>(false);
  const [inner_corner_open, setInnerCornerOpen] = useState<boolean>(false);
  const [bump_open, setBumpOpen] = useState<boolean>(false);
  const [area_open, setAreaOpen] = useState<boolean>(false);
  const [create_area_open, setCreateAreaOpen] = useState<boolean>(false);
  const [current_corner, setCurrentCorner] = useState<IShopCorner | null>(null);
  const [copy, setCopy] = useState<HighlightData | null>(null);

  const highlight_data: HighlightData = useDrawShop(
    index,
    selected_counter,
    areas[current_area]?.uuid,
    mouse_data,
    corner_open || bump_open || inner_corner_open,
    false,
    false,
    in_operation,
    zoom,
    {
      show_corners: true,
      lines_selectable: true,
      text_selectable: !corner_open && !bump_open && !inner_corner_open
    }
  );

  const handleEvent = (event: SyntheticEvent) => {
    const mouse_event: MouseEvent | null = event.nativeEvent instanceof MouseEvent ? event.nativeEvent as MouseEvent : null;

    const mouse: Vector2 = mouse_data.position;

    if ((mouse_event && mouse_event.type === "mousedown" && mouse_event.button === 0)) {
      if (copying && !copy) {
        if (highlight_data?.text) {
          if (highlight_data?.text?.type === CORNER) {
            setCopy(highlight_data);
          }
        }
        setInOperation(false);
        return;
      }
      else if (copying && copy) {
        if (highlight_data?.text?.type === CORNER && copy?.text?.type === CORNER && highlight_data?.text?.mirror === copy?.text?.mirror) {
          const old_counter: IShopCounter | null = getShopCounterByUuid(copy?.text?.counter_uuid, counters);
          const corner: IShopCorner | null = getShopCornerByUuid(copy?.text?.corner_uuid, old_counter);
          const new_counter: IShopCounter | null = getShopCounterByUuid(highlight_data?.text?.counter_uuid, counters);
          const new_corner: IShopCorner | null = getShopCornerByUuid(highlight_data?.text?.corner_uuid, new_counter);
          const update_counter: IShopCounter | null = updateShopCorner(new_counter, {
            ...new_corner,
            corner_type: corner.corner_type,
            corner_length: corner.corner_length,
            corner_depth: corner.corner_depth,
            corner_radius: corner.corner_radius,
          });
          if (update_counter) {
            dispatch(updateLocalShopCounter(update_counter));
            dispatch(updateShopCounter(update_counter));
          }
        }
        setInOperation(false);
        return;
      }
      if (!corner_open && !bump_open && !inner_corner_open && !in_operation) {
        for (let i = 0; i < labels.length; i++) {
          if (checkIndicatorSelected(labels[i].location, getMouseAtZoom(mouse, zoom), true, 55)) {
            setSelectedLabel(labels[i]);
            setLiftingLabel(true);
            return;
          }
        }

        if (highlight_data?.text && !menu_open) {
          if (highlight_data?.text?.type === CORNER) {
            if (highlight_data?.text?.mirror) {
              setCornerOpen(true);
              const counter: IShopCounter | null = getShopCounterByUuid(highlight_data.text.counter_uuid, counters);
              setSelectedCounter(counter);
              if (counter) {
                const corner: IShopCorner | null = getShopCornerByUuid(highlight_data.text.corner_uuid, counter);
                setCurrentCorner(corner);
              }
            }
            else {
              setInnerCornerOpen(true);
              const counter: IShopCounter | null = getShopCounterByUuid(highlight_data.text.counter_uuid, counters);
              setSelectedCounter(counter);
              if (counter) {
                const corner: IShopCorner | null = getShopCornerByUuid(highlight_data.text.corner_uuid, counter);
                setCurrentCorner(corner);
              }
            }
            return;
          }
        }

        if (highlight_data.corner && !menu_open) {
          const selected_line: Line | null = getNextShopCorner(counters, highlight_data.corner);

          if (selected_line) {
            setSelectedLine(selected_line);
            setBumpOpen(true);
          }
          return;
        }

        const target: HTMLDivElement = event.target as HTMLDivElement;
        if (!target.id.startsWith("menu")) {
          setMenuOpen(false);
        }
      }
    }
    else if ((mouse_event && mouse_event.type === "mousedown" && mouse_event.button === 2)) {
      if (copying) {
        setCopying(false);
        setCopy(null);
        setInOperation(false);
        return;
      }
      for (let i = 0; i < labels.length; i++) {
        if (checkIndicatorSelected(labels[i].location, getMouseAtZoom(mouse, zoom), true)) {
          setSelectedLabel(labels[i]);
          setMenuLocation({
            X: mouse_event.pageX,
            Y: mouse_event.pageY
          });
          setLabelMenuOpen(true);
          return;
        }
      }

      const counter: IShopCounter | null = checkShopCounterClicked(counters.filter(c => c.area_uuid === areas[current_area].uuid), getMouseAtZoom(mouse, zoom));
      if (counter) {
        setSelectedCounter(counter);
        setMenuLocation(mouse_data.screen_position);
        setMenuOpen(true);
      }
    }
    else if ((mouse_event && mouse_event.type === "mouseup" && mouse_event.button === 0)) {
      if (lifting_label && selected_label) {
        const label: IShopLabel = { ...selected_label };
        const vector: Vector2 = moveVector(selected_label.location, getMouseAtZoom(mouse, zoom));
        dispatch(updateShopLabel({ ...label, location: vector }, label.id));
        dispatch(updateLocalShopLabel({ ...label, location: vector }));
        setSelectedLabel(null);
        setLiftingLabel(false);
      }
      setInOperation(false);
      return;
    }
  }

  useEffect(() => {
    if (selected_label && lifting_label) {
      const vector: Vector2 = moveVector(selected_label.location, getMouseAtZoom(mouse_data.position, zoom));
      dispatch(updateLocalShopLabel({ ...selected_label, location: vector }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mouse_data.position]);

  const deleteSelectedCounter = () => {
    setInOperation(true);
    if (selected_counter) {
      const uuid: string = selected_counter.uuid;
      const id: number | undefined = selected_counter.id;
      handleDeleteShopCounter(
        dispatch,
        setSelectedCounter,
        uuid,
        id,
        selected_counter,
        cut_shapes.filter(shape => shape.counter_uuid === uuid)
      );
    }
    setInOperation(false);
    setMenuOpen(false);
  }

  const rotate = (rotation: number) => {
    if (selected_counter) {
      handleRotateShopCounter(
        dispatch,
        setSelectedCounter,
        { ...selected_counter },
        rotation
      );
    }
  }

  const handleDuplicateCounter = () => {
    setInOperation(true);
    if (selected_counter) {
      const counter: IShopCounter = duplicateShopCounter(selected_counter);
      const slab: IShopSlab | null = getFirstShopSlabInAreas(areas, counter.area_uuid);
      handleCreateShopCounter(
        dispatch,
        quote.id,
        counter,
        counter.area_uuid,
        slab.uuid
      );
    }
    setInOperation(false);
    setMenuOpen(false);
  }

  const handleChangeAreaOpen = () => {
    setAreaOpen(true);
    setMenuOpen(false);
  }

  const handleOpenEditLabel = () => {
    setEditLabelOpen(true);
    setLabelMenuOpen(false);
  }

  const duplicateLabel = () => {
    if (selected_label) {
      dispatch(createShopLabel({ text: selected_label.text, area: selected_label.area, size: selected_label.size, location: { X: 450, Y: 450 }, quote: quote.id }));
    }
    setLabelMenuOpen(false);
  }

  const handleDeleteLabel = () => {
    const id: number = selected_label.id;
    dispatch(deleteShopLabel(id));
    setLabelMenuOpen(false);
  }

  return (
    <div>
      <ShopAreaBar current_area={current_area} setArea={setCurrentArea} />
      <Box onContextMenu={(e) => { e.preventDefault(); }}
        sx={sketch_style(copy ? "crosshair" : copying ? "grab" : "normal")}
        onMouseDown={(event: ReactMouseEvent) => handleEvent(event)}
        onMouseUp={(event: ReactMouseEvent) => handleEvent(event)}
        ref={ref}>
        <SketchMenu
          open={menu_open}
          menu_location={menu_location}
          changeArea={handleChangeAreaOpen}
          deleteCounter={deleteSelectedCounter}
          duplicateCounter={handleDuplicateCounter}
          rotate={rotate} />
        <LabelMenu
          open={label_menu_open}
          menu_location={menu_location}
          openEditLabel={handleOpenEditLabel}
          duplicateLabel={duplicateLabel}
          deleteLabel={handleDeleteLabel} />
        <canvas id="canvas" className={JSON.stringify(canvasStyle)} />
        <UpdateShopCorner
          open={corner_open}
          setOpen={setCornerOpen}
          original_corner={current_corner}
          selected_counter={selected_counter} />
        <UpdateShopInnerCorner
          open={inner_corner_open}
          setOpen={setInnerCornerOpen}
          original_corner={current_corner}
          selected_counter={selected_counter} />
        <UpdateShopBump
          open={bump_open}
          setOpen={setBumpOpen}
          selected_line={selected_line}
          setSelectedLine={setSelectedLine} />
        <UpdateShopArea
          open={area_open}
          setOpen={setAreaOpen}
          counter={selected_counter} />
        <CreateShopArea
          open={create_area_open}
          setOpen={setCreateAreaOpen} />
        <UpdateShopLabel
          open={edit_label_open}
          setOpen={setEditLabelOpen}
          selected_label={selected_label} />
      </Box>
      {
        copying && copy ?
          <Stack sx={{ position: "absolute", top: mouse_data.position.Y + 30, left: mouse_data.position.X + 20, zIndex: 2000, backgroundColor: "#fff", border: "1px solid #666666", paddingLeft: "10px", paddingRight: "10px", paddingTop: "3px", paddingBottom: "3px" }}>
            <Typography variant="body2">
              {getCopyMessage(copy)}
            </Typography>
            <Typography variant="body2" sx={{ fontSize: 10 }} color="error">
              Right click to cancel
            </Typography>
          </Stack> :
          null
      }
    </div>
  )
}
