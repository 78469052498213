/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
/// ACTIONS ///
import { createRestorePoint } from "api/actions/history";
import { replaceRestorePoint } from "api/actions/history";
import { updateLocalSink } from "api/actions/sketch/sink";
import { updateSink } from "api/actions/sketch/sink";
import { restore } from "api/actions/history";
import { markQuoteChanged } from "api/actions/quotes/quote";
/// TYPES ///
import { CutoutError } from "api/types/sketch";
import { Counter } from "api/types/sketch";
import { IHistoryActionType } from "api/types/history";
import { Sink } from "api/types/sketch";
/// COMPONENTS ///
import SaveSink from "components/quote/drawer/sink";
/// VALUES ///
import { UNDERMOUNT } from "values/values";
import { RECTANGLE } from "values/values";

interface Props {
  setSelectedSink: (sink: Sink | null) => void,
  open: boolean,
  setOpen: (open: boolean) => void,
  setSelectedCounter: (selected_counter: Counter | null) => void,
  selected_sink: Sink,
  current_sink: Sink
}

export default function UpdateStoreSink({
  setSelectedSink,
  selected_sink,
  setSelectedCounter,
  open,
  setOpen
}: Props) {
  const dispatch = useAppDispatch();
  const [sink, setSink] = useState<Sink>({});
  const [error, setError] = useState<CutoutError>({});

  useEffect(() => {
    if (open && selected_sink) {
      setSink(selected_sink);
      dispatch(replaceRestorePoint(
        {
          undo_action: IHistoryActionType.UPDATE_SINK,
          desc: "Cancel Update Sink",
          undo_data: { ...selected_sink }
        }
      ));
    }
  }, [open]);

  const cancel = () => {
    dispatch(restore());
    setOpen(false);
    setSelectedSink(null);
  }

  const save = () => {
    let error: CutoutError = {};
    if (!sink?.length) {
      error.length_error = "Length must be greater than 0";
      error.length_has_error = true;
    }
    if (!sink?.width) {
      error.width_error = "Width must be greater than 0";
      error.width_has_error = true;
    }

    if (error.length_has_error || error.width_has_error) {
      setError(error);
      return;
    }

    dispatch(updateSink(sink, sink.id));
    dispatch(markQuoteChanged());

    dispatch(createRestorePoint(
      {
        undo_action: IHistoryActionType.UPDATE_SINK,
        redo_action: IHistoryActionType.UPDATE_SINK,
        desc: "Update Sink",
        undo_data: { ...selected_sink },
        redo_data: sink
      }
    ));
    setSelectedSink(null);
    setSelectedCounter(null);
    setOpen(false);
  }

  const update = (updated_sink: Sink) => {
    setSink(updated_sink);
    dispatch(updateLocalSink(updated_sink));
  }

  return (
    <SaveSink
      open={open}
      setOpen={setOpen}
      sink_type={sink?.sink_type ?? UNDERMOUNT}
      setSinkType={sink_type => update({ ...sink, sink_type })}
      shape={sink?.shape ?? RECTANGLE}
      setShape={shape => update({ ...sink, shape })}
      faucet_hole_count={sink?.faucet_hole_count ?? 1}
      setFaucetHoleCount={faucet_hole_count => update({ ...sink, faucet_hole_count })}
      length={sink?.length ?? 30}
      setLength={length => update({ ...sink, length })}
      length_error={error?.length_error ?? ""}
      length_has_error={error?.length_has_error ?? false}
      width={sink?.width ?? 26}
      setWidth={width => update({ ...sink, width })}
      width_error={error?.width_error ?? ""}
      width_has_error={error?.width_has_error ?? false}
      save={save}
      cancel={cancel}
      title="Update Sink Cutout" />
  );
}
