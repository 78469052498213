/// TYPES ///
import { User } from "api/types/authentication";
import { ICustomer } from "api/types/customer";
import { IFabricationJob } from "api/types/customer";
/// COMPONENTS ///
import { Fragment } from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
/// FUNCTIONS ///
import { getDate } from "functions/date";
import { getExpiryDate } from "functions/date";
/// STYLES ///
import { po_title_container_style } from "styles/po";
import { po_title_style } from "styles/po";
import { po_subtitle_style } from "styles/po";
import { po_info_container_style } from "styles/po";
import { po_txt_style } from "styles/po";
/// COLORS ///
import { light_grey_color } from "styles/style";

interface Props {
  invoice_number: string,
  seller: User,
  customer: ICustomer,
  job: IFabricationJob,
  created: string
}

export default function POHeader({ invoice_number, seller, customer, job, created }: Props) {
  return (
    <Fragment>
      <Grid item xs={6} sx={{ height: "80px" }}>
        <img src={typeof (seller.logo) === "string" ? seller.logo : ""} width={130} height={65}
          style={{ objectFit: "contain", objectPosition: "left" }} alt="Business Logo" />
      </Grid>
      <Grid item xs={6} sx={po_title_container_style}>
        <Stack display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h2" sx={po_title_style}>
            PURCHASE ORDER
          </Typography>
          <Typography variant="body2" sx={po_subtitle_style}>
            Invoice #: {invoice_number}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={6} sx={po_info_container_style}>
        <Stack>
          <Typography variant="h6">
            Vendor
          </Typography>
          <Typography variant="body2" sx={po_txt_style}>
            {seller?.business_name ?? ""}
          </Typography>
          <Typography variant="body2" sx={po_txt_style}>
            {seller?.address ?? ""}
          </Typography>
          <Typography variant="body2" sx={po_txt_style}>
            {seller?.city ?? ""}, {seller?.city !== "" ? " " : ""}{seller?.province ?? ""}
          </Typography>
          <Typography variant="body2" sx={po_txt_style}>
            {seller?.postal_code ?? ""}
          </Typography>
          <Typography variant="body2" sx={po_txt_style}>
            {seller?.email ?? ""}
          </Typography>
          <Typography variant="body2" sx={po_txt_style}>
            {seller?.phone_number ?? ""}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={6} sx={{ marginTop: "15px", height: "155px", padding: "5px", paddingRight: "15px", backgroundColor: light_grey_color }}>
        <Stack alignItems="end">
          <Typography variant="h6">
            Bill To
          </Typography>
          <Typography variant="body2" textAlign="right" sx={po_txt_style}>
            {customer?.is_business ? customer?.business_name : `${customer?.first_name ?? ""} ${customer?.last_name ?? ""}`}
          </Typography>
          <Typography variant="body2" textAlign="right" sx={po_txt_style}>
            {customer?.address ?? ""}
          </Typography>
          <Typography variant="body2" textAlign="right" sx={po_txt_style}>
            {customer?.city ?? ""}, {customer?.city !== "" ? " " : ""}{customer?.province ?? ""}
          </Typography>
          <Typography variant="body2" textAlign="right" sx={po_txt_style}>
            {customer?.postal_code ?? ""}
          </Typography>
          <Typography variant="body2" sx={po_txt_style}>
            {customer?.email ?? ""}
          </Typography>
          <Typography variant="body2" sx={po_txt_style}>
            {customer?.phone_number ?? ""}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={6} sx={{ marginTop: "20px", height: "130px" }}>
        <Stack alignItems="start">
          <Typography variant="body2" textAlign="right" sx={po_txt_style}>
            {job?.geo_address ?? ""}
          </Typography>
          <Typography variant="body2" textAlign="right" sx={po_txt_style}>
            {job?.email ?? ""}
          </Typography>
          <Typography variant="body2" textAlign="right" sx={po_txt_style}>
            {job?.phone_number ?? ""}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={6} sx={{ marginTop: "20px", height: "130px", paddingRight: "5px" }}>
        <Stack>
          <Typography variant="body2" align="right" sx={{ paddingRight: 0.5, fontFamily: "Montserrat" }}>
            Created on: {getDate(created)}
          </Typography>
          <Typography variant="body2" align="right" sx={{ paddingRight: 0.5, fontFamily: "Montserrat" }}>
            Expires on: {getExpiryDate(created)}
          </Typography>
        </Stack>
      </Grid>
    </Fragment>
  )
}
