/// HOOKS ///
import useMarketQuoteTotals from "hooks/quote/market_totals";
/// TYPES ///
import { Area } from "api/types/quote";
import { FabricatorQuote } from "api/types/market";
import { QuoteTotals } from "api/types/quote";
/// REACT FRAGMENTS ///
import { Fragment } from "react";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import QuoteSummaryHeader from "components/universal/quote/summary_header";
import QuoteSummaryRow from "components/universal/quote/summary_row";
/// STYLES ///
import { item_style } from "styles/quote_preview/section";
import { header_style } from "styles/universal/section_header";

interface Props {
  quote: FabricatorQuote,
  areas: Area[],
}

export default function MarketQuoteSummary({ quote, areas }: Props) {
  const totals: QuoteTotals = useMarketQuoteTotals(quote);

  return (
    <Fragment>
      <Grid item xs={12} sx={{ marginTop: "20px" }}>
        <Typography variant="h6" sx={item_style(false)}>
          Summary
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={header_style}></Box>
      </Grid>
      {
        areas.map(area =>
          <Fragment>
            <QuoteSummaryHeader name={area?.name ?? ""} key={area.id} />
            {
              area.line_items.map(item =>
                <QuoteSummaryRow key={item.id} item={item} openEdit={() => void 0} />
              )
            }
          </Fragment>
        )
      }
      <Grid item xs={8}>
        <Typography variant="body2" align="right" sx={item_style()}>
          <b>Subtotal</b>
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2" align="right" sx={item_style()}>
          ${totals.subtotal.toFixed(2)}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="body2" align="right" sx={item_style()}>
          <b>{quote?.quote_data?.tax_name ?? "Tax"}</b>
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2" align="right" sx={item_style()}>
          ${totals.tax1.toFixed(2)}
        </Typography>
      </Grid>
      {
        quote?.second_tax ?
          <Fragment>
            <Grid item xs={8}>
              <Typography variant="body2" align="right" sx={item_style()}>
                <b>{quote?.quote_data?.second_tax_name ?? "Tax2"}</b>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" align="right" sx={item_style()}>
                ${totals.tax2.toFixed(2)}
              </Typography>
            </Grid>
          </Fragment> : null
      }
      <Grid item xs={8}>
        <Typography variant="body2" align="right" sx={item_style()}>
          <b>Total</b>
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2" align="right" sx={item_style()}>
          ${(totals.subtotal + totals.tax1 + totals.tax2 - totals.discount).toFixed(2)}
        </Typography>
      </Grid>
    </Fragment>
  )
}
