/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
/// TYPES ///
import { IShopCounter } from "api/types/shop_quote/counter";
import { IHistoryActionType } from "api/types/history";
/// ACTIONS ///
import { updateShopCounter } from "api/actions/shop_quote";
import { updateLocalShopCounter } from "api/actions/shop_quote";
import { createRestorePoint } from "api/actions/history";
import { markShopQuoteChanged } from "api/actions/shop_quote";
/// COMPONENTS ///
import UpdateOutletsDrawer from "components/quote/drawer/update_outlet";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  counter: IShopCounter | null
}

export default function UpdateShopOutlets({
  open,
  setOpen,
  counter
}: Props) {
  const dispatch = useAppDispatch();
  const [outlet_cutout_count, setOutletCutoutCount] = useState<number>(0);

  useEffect(() => {
    if (open && counter) {
      setOutletCutoutCount(counter?.outlet_cutout_count);
    }
  }, [open, dispatch]);

  const save = () => {
    if (counter) {
      dispatch(updateShopCounter({ ...counter, outlet_cutout_count }));
      dispatch(updateLocalShopCounter({ ...counter, outlet_cutout_count }));

      dispatch(createRestorePoint(
        {
          undo_action: IHistoryActionType.UPDATE_SHOP_COUNTER,
          redo_action: IHistoryActionType.UPDATE_SHOP_COUNTER,
          desc: "Update Outlet Cutouts",
          undo_data: counter,
          redo_data: { ...counter, outlet_cutout_count },
        }
      ));
      dispatch(markShopQuoteChanged());
      close();
    }
  }

  const close = () => {
    setOpen(false);
    setOutletCutoutCount(0);
  }

  return (
    <UpdateOutletsDrawer
      open={open}
      outlet_cutout_count={outlet_cutout_count}
      setOutletCutoutCount={setOutletCutoutCount}
      close={close}
      save={save} />
  );
}
