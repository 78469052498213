/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ROUTER ///
import { useNavigate } from "react-router-dom";
/// ACTIONS ///
import { filterQuotes } from "api/actions/quotes/quote";
import { setBreadcrumbs } from "api/actions/ui/set";
/// TYPES ///
import { QuoteFilter } from "api/types/quote";
/// MUI ///
import Box from "@mui/material/Box";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Grid from "@mui/material/Grid";
import { TextField } from "@mui/material";
/// PAGE COMPONENTS ///
import BaseButton from "components/universal/button/base";
import PageTitle from "components/universal/page/title";
/// FM ///
import { motion } from "framer-motion";
/// DATETIME ///
import { DateTime } from "luxon";
/// ICONS ///
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { faEraser } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// URLS ///
import { quote_url } from "components/navigation/endpoints";
import { store_url } from "components/navigation/endpoints";
/// BREADCRUMBS ///
import { quote_list } from "values/breadcrumbs/quote";
import { quote_filter } from "values/breadcrumbs/quote";

export default function FilterStoreQuotes() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [filter, setFilter] = useState<QuoteFilter>({});

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { ...quote_list, url: quote_list.url.replace("base_url", store_url) },
      { ...quote_filter, url: quote_list.url.replace("base_url", store_url) }
    ]));
  }, [dispatch]);

  useEffect(() => {

  }, []);

  const applyFilter = () => {
    dispatch(filterQuotes(filter));
    navigate(`/${store_url}/${quote_url}`);
  }

  const keyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      applyFilter();
    }
  }

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style} onKeyDown={keyDown}>
      <Grid container>
        <PageTitle title="Filter Quotes" endProps={
          <Box>
            <BaseButton clickEvent={() => navigate(`/${store_url}/${quote_url}`)} text="Cancel" icon={faTimes} />
            <BaseButton clickEvent={() => setFilter({})} text="Clear" icon={faEraser} />
            <BaseButton clickEvent={() => applyFilter()} text="Apply" icon={faFilter} />
          </Box>
        } />
        <Grid item xs={12}>
          <Box style={header_style}></Box>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: "20px" }}>
          <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ gap: "10px" }}>
            <TextField
              onChange={e => setFilter({ ...filter, name: e.target.value })}
              value={filter?.name ?? ""}
              label="Quote Name"
              size="small" />
            <TextField
              onChange={e => setFilter({ ...filter, customer_name: e.target.value })}
              value={filter?.customer_name ?? ""}
              label="Account Last Name"
              size="small" />
            <DatePicker
              slotProps={{ textField: { size: 'small', InputLabelProps: { shrink: true }, fullWidth: true } }}
              label="Start Date"
              value={filter?.start_date ? DateTime.fromISO(filter.start_date) : null}
              onChange={e => setFilter({ ...filter, start_date: e.toUTC().toISO() })} />
            <DatePicker
              slotProps={{ textField: { size: 'small', InputLabelProps: { shrink: true }, fullWidth: true } }}
              label="End Date"
              value={filter?.start_date ? DateTime.fromISO(filter.end_date) : null}
              onChange={e => setFilter({ ...filter, end_date: e.toUTC().toISO() })} />
          </Box>
        </Grid>
      </Grid>
    </motion.div>
  )
}
