/// TYPES ///
import { ICustomer, ICustomerErrors } from "api/types/customer";
/// MUI COMPONENTS ///
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import AddressAutocomplete from "components/universal/autocomplete/geo";
import ProvinceSelector from "components/universal/select_field/province";
import StyledRadio from "components/universal/styled_radio";

interface Props {
  customer: ICustomer,
  setCustomer: (customer: ICustomer) => void,
  errors: ICustomerErrors,
  setErrors: (errors: ICustomerErrors) => void
}

export default function NewCustomer({ customer, setCustomer, errors, setErrors }: Props) {
  const updateGeo = (label: string, lat: number, lng: number, address: string, city: string, province: string) => {
    setCustomer({
      ...customer,
      geo_address: label,
      lat: lat,
      lng: lng,
      address: customer.address && customer.address !== "" ? customer.address : address,
      city: customer.city && customer.city !== "" ? customer.city : city,
      province: !customer?.province || customer.province === "" ? province : customer.province
    });
    setErrors({ ...errors, geo_error: "", geo_has_error: false });
  }

  return (
    <Grid item xs={12} sx={{ paddingTop: "20px" }}>
      <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ gap: "10px" }}>
        <Stack direction="row" alignItems="center">
          <Typography variant="body2" sx={{ marginRight: "10px" }}>
            Account Type
          </Typography>
          <RadioGroup row value={customer?.is_business} sx={{ paddingLeft: "5px" }} onChange={e => setCustomer({ ...customer, is_business: e.target.value === "true" ? true : false })}>
            <FormControlLabel
              value={false}
              control={<StyledRadio />}
              label={
                <Typography variant="body2">
                  Retail
                </Typography>
              } />
            <FormControlLabel
              value={true}
              control={<StyledRadio />}
              label={
                <Typography variant="body2">
                  Business
                </Typography>
              } />
          </RadioGroup>
        </Stack>
        {
          customer?.is_business ?
            <TextField
              value={customer?.business_name ?? ""}
              onChange={e => setCustomer({ ...customer, business_name: e.target.value })}
              error={errors?.business_name_has_error}
              helperText={errors?.business_name_error ?? ""}
              label="Business Name"
              size="small" /> :
            null
        }
        <Stack direction="row" spacing={1}>
          <TextField
            value={customer?.first_name ?? ""}
            onChange={e => setCustomer({ ...customer, first_name: e.target.value })}
            error={errors?.first_name_has_error}
            helperText={errors?.first_name_error ?? ""}
            label="First Name"
            size="small" />
          <TextField
            value={customer?.last_name ?? ""}
            onChange={e => setCustomer({ ...customer, last_name: e.target.value })}
            error={errors?.last_name_has_error}
            helperText={errors?.last_name_error ?? ""}
            label="Last Name"
            size="small" />
        </Stack>
        <TextField
          value={customer?.email ?? ""}
          onChange={e => setCustomer({ ...customer, email: e.target.value })}
          error={errors?.email_has_error}
          helperText={errors?.email_error ?? ""}
          label="Email"
          size="small" />
        <TextField
          onChange={e => setCustomer({ ...customer, phone_number: e.target.value })}
          value={customer?.phone_number ?? ""}
          label="Phone Number"
          size="small" />
        <AddressAutocomplete
          input_label="Service Address"
          label={customer.geo_address}
          error={errors?.geo_error ?? ""}
          has_error={errors?.geo_has_error}
          geoError={() => setErrors({ ...errors, geo_has_error: true, geo_error: "Unable to retrieve location of this address. Please choose another." })}
          updateGeo={updateGeo} />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ gap: "10px", marginTop: "30px" }}>
        <TextField
          value={customer.address}
          onChange={e => setCustomer({ ...customer, address: e.target.value })}
          label="Billing Address"
          size="small" />
        <TextField
          value={customer?.city ?? ""}
          onChange={e => setCustomer({ ...customer, city: e.target.value })}
          label="Billing City"
          size="small" />
        <ProvinceSelector
          province={customer?.province ?? ""}
          setProvince={province => setCustomer({ ...customer, province: province })} />
        <TextField
          value={customer?.postal_code ?? ""}
          onChange={e => setCustomer({ ...customer, postal_code: e.target.value })}
          label="Billing Postal Code"
          size="small" />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ gap: "10px", marginTop: "30px" }}>
        <TextField
          sx={{ width: "540px" }}
          multiline={process.env.NODE_ENV === "development" ? false : true}
          minRows={3}
          maxRows={3}
          onChange={e => setCustomer({ ...customer, entry_notes: e.target.value })}
          label="Notes"
          size="small" />
      </Box>
    </Grid >

  )
}
