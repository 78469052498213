import { useEffect } from "react";
import { Vector2 } from "api/types/sketch";
import { OrderSlab } from "api/types/order/order";
import { SelfOrderSlab } from "api/types/order/self_order";
import { getOptimalSlabZoom } from "functions/sketch";
import { drawPOCutShapes } from "functions/sketch";
import { getCanvas } from "functions/sketch/context";
import { initializeSlabCanvas } from "functions/sketch/context";
import { getSlabStartPoint } from "functions/sketch";
import { getContext } from "functions/sketch/context";
import { cutShapeCanvas } from "functions/sketch/context";
import { SLAB_ZOOM } from "values/values";

const base_offset: Vector2 = { X: 50, Y: 50 };

function useDrawOrderSlab(
  index: number,
  slab: OrderSlab | SelfOrderSlab,
  load_drawing: boolean
) {
  useEffect(() => {
    const canvas: HTMLCanvasElement | null = getCanvas(document.getElementById(`slab_canvas_${slab.id}`));
    const context: CanvasRenderingContext2D | null = getContext(canvas);
    if (canvas && context && load_drawing) {
      const zoom: number = getOptimalSlabZoom(
        slab.width * 4 + base_offset.X + 50,
        slab.length * 4 + base_offset.Y + 50,
        { X: canvas.width, Y: canvas.height }
      );

      const slab_start: Vector2 = getSlabStartPoint(slab.length, index);
      slab_start.X = Math.round(slab_start.X / SLAB_ZOOM);
      slab_start.Y = Math.round(slab_start.Y / SLAB_ZOOM);

      const path: Path2D = drawPOCutShapes(slab.cut_shapes, zoom, base_offset, slab_start);

      const slab_path = new Path2D();
      slab_path.moveTo(base_offset.X, base_offset.Y);
      slab_path.lineTo(base_offset.X + slab.width * 4 * zoom, base_offset.Y);
      slab_path.lineTo(base_offset.X + slab.width * 4 * zoom, base_offset.Y + slab.length * 4 * zoom);
      slab_path.lineTo(base_offset.X, base_offset.Y + slab.length * 4 * zoom);
      slab_path.lineTo(base_offset.X, base_offset.Y);

      initializeSlabCanvas(canvas, context);
      context.fill(slab_path);
      context.stroke(slab_path);

      cutShapeCanvas(context);
      context.fill(path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load_drawing]);
};
export default useDrawOrderSlab;
