/// TYPES ///
import { IOrderEvent } from "api/types/event";
import { ISelfOrderEvent } from "api/types/event";
import { ICustomEvent } from "api/types/event";
import { IEventFilter } from "api/types/event";
/// LUXON ///
import { DateTime } from "luxon";
/// VALUES ///
import { TEMPLATE } from "values/event";
import { ORDER_MATERIAL } from "values/event";
import { FABRICATION } from "values/event";
import { INSTALL } from "values/event";
import { SERVICE_CALL } from "values/event";

export const filterEventType = (event: IOrderEvent | ISelfOrderEvent | ICustomEvent, filter: IEventFilter): boolean => {
  if (event?.event_type === TEMPLATE && filter.template)
    return false;
  else if (event?.event_type === ORDER_MATERIAL && filter.order_material)
    return false;
  else if (event?.event_type === FABRICATION && filter.fabricate_order)
    return false;
  else if (event?.event_type === INSTALL && filter.install)
    return false;
  else if (event.event_type === SERVICE_CALL && filter.service_call) {
    return false;
  }
  return true;
}

export const filterMorning = (event: IOrderEvent | ISelfOrderEvent | ICustomEvent): boolean => {
  const date = DateTime.fromISO(event.time).toLocal();

  if (date.hour >= 0 && date.hour < 12)
    return true;
  return false;
}

export const filterAfternoon = (event: IOrderEvent | ISelfOrderEvent | ICustomEvent): boolean => {
  const date = DateTime.fromISO(event.time).toLocal();

  if (date.hour >= 12 && date.hour < 17)
    return true;
  return false;
}

export const filterEvening = (event: IOrderEvent | ISelfOrderEvent | ICustomEvent): boolean => {
  const date = DateTime.fromISO(event.time).toLocal();

  if (date.hour >= 17 && date.hour < 25)
    return true;
  return false;
}
