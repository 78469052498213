/// REDUX ///
import { Dispatch } from "redux";
/// ACTION ///
import { Action } from "api/action_types/shop_quote";
/// ACTION TYPE ///
import { ActionType } from "api/action_types/shop_quote";
/// TYPES ///
import { IShopArea } from "api/types/shop_quote/area";

export const updateLocalShopArea = (area: IShopArea) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_LOCAL_SHOP_AREA,
      payload: area
    });
  }
}
