/// MUI ///
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Fab from "@mui/material/Fab";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// ICONS ///
import { faMoneyCheckDollar } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { actionBarStyle } from "styles/sketch";

interface Props {
  show_prices: boolean,
  setShowPrices: (show_prices: boolean) => void
}

export default function DetailActions({ show_prices, setShowPrices }: Props) {
  return (
    <Box sx={actionBarStyle}>
      <Tooltip title="Show Prices">
        <Fab
          size="small"
          color={show_prices ? "secondary" : "primary"}
          aria-label="show-prices"
          onClick={() => setShowPrices(!show_prices)}>
          <FontAwesomeIcon icon={faMoneyCheckDollar} />
        </Fab>
      </Tooltip>
    </Box>
  )
}
