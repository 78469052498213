/// TYPES ///
import { Counter } from "api/types/sketch";
import { Corner } from "api/types/sketch";
import { CutShape } from "api/types/sketch";
import { Point } from "api/types/sketch";
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopCorner } from "api/types/shop_quote/corner";
import { IShopCutShape } from "api/types/shop_quote/cut_shape";
import { IShopPoint } from "api/types/shop_quote/point";
import { Vector2 } from "api/types/sketch";
/// ACTIONS ///
import { updateLocalShopCounter } from "api/actions/shop_quote";
import { updateLocalShopCutShapes } from "api/actions/shop_quote";
import { createShopCounter } from "api/actions/shop_quote";
import { markShopQuoteChanged } from "api/actions/shop_quote";
import { createRestorePoint } from "api/actions/history";
import { updateLocalCounter } from "api/actions/sketch/counter";
import { updateLocalCutShapes } from "api/actions/sketch/cut_shape";
import { createCounter } from "api/actions/sketch/counter";
import { markQuoteChanged } from "api/actions/quotes/quote";
/// FUNCTIONS ///
import { getDistance } from "functions/sketch/math";
import { getDistantPoint } from "functions/sketch/math";
import { createShopCorners } from "functions/sketch/create/shop_corner";
import { createCorners } from "functions/sketch";
import { createShopCutShapes } from "functions/sketch/create/cut_shape";
import { createCutShapes } from "functions/sketch";
import { IHistoryActionType } from "api/types/history";

export function handleCreateCounter(
  dispatch: (({ ...any }) => void),
  quote: number,
  counter: Counter,
  area_uuid: string,
  slab_uuid: string
) {
  if (counter.points.length === 2) {
    const first_point: Point = { ...counter.points[0] };
    const second_point: Point = { ...counter.points[1] };
    const distance: number = getDistance(first_point.location, second_point.location);
    if (distance < 4) {
      const new_location: Vector2 = getDistantPoint(first_point.location, first_point.angle, 4);
      const points: Point[] = [first_point, { ...second_point, location: new_location }];
      const corners: Corner[] = createCorners(points, [], counter.uuid);
      counter.points = points;
      counter.corners = corners;
    }
  }

  const new_cut_shapes: CutShape[] = createCutShapes(
    counter,
    area_uuid,
    slab_uuid,
    counter.points,
    counter.corners,
    []
  );
  dispatch(updateLocalCounter(counter));
  dispatch(updateLocalCutShapes(new_cut_shapes, counter.uuid));
  dispatch(createCounter(
    {
      ...counter,
      area_uuid,
      quote
    },
    new_cut_shapes
  ));

  dispatch(createRestorePoint(
    {
      undo_action: IHistoryActionType.DELETE_COUNTER,
      redo_action: IHistoryActionType.CREATE_COUNTER,
      desc: "Create Counter",
      undo_data: { uuid: counter.uuid },
      redo_data: counter,
      redo_data2: new_cut_shapes
    }
  ));
  dispatch(markQuoteChanged());
}

export function handleCreateShopCounter(
  dispatch: (({ ...any }) => void),
  quote: number,
  counter: IShopCounter,
  area_uuid: string,
  slab_uuid: string
) {
  if (counter.points.length === 2) {
    const first_point: IShopPoint = { ...counter.points[0] };
    const second_point: IShopPoint = { ...counter.points[1] };
    const distance: number = getDistance(first_point.location, second_point.location);
    if (distance < 4) {
      const new_location: Vector2 = getDistantPoint(first_point.location, first_point.angle, 4);
      const points: IShopPoint[] = [first_point, { ...second_point, location: new_location }];
      const corners: IShopCorner[] = createShopCorners(points, [], counter.uuid);
      counter.points = points;
      counter.corners = corners;
    }
  }

  const new_cut_shapes: IShopCutShape[] = createShopCutShapes(
    counter,
    area_uuid,
    slab_uuid,
    counter.points,
    counter.corners,
    []
  );
  dispatch(updateLocalShopCounter(counter));
  dispatch(updateLocalShopCutShapes(new_cut_shapes, counter.uuid));
  dispatch(createShopCounter(
    {
      ...counter,
      area_uuid,
      quote
    },
    new_cut_shapes
  ));

  dispatch(createRestorePoint(
    {
      undo_action: IHistoryActionType.DELETE_SHOP_COUNTER,
      redo_action: IHistoryActionType.CREATE_SHOP_COUNTER,
      desc: "Create Counter",
      undo_data: { uuid: counter.uuid },
      redo_data: counter,
      redo_data2: new_cut_shapes
    },
  ));

  dispatch(markShopQuoteChanged());
}
