/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ACTIONS ///
import { createShopMaterial } from "api/actions/shop_material";
import { resetCreateShopMaterialSuccess } from "api/actions/shop_material";
/// TYPES ///
import { IShopMaterial } from "api/types/shop_material";
import { IShopMaterialError } from "api/types/shop_material";
/// COMPONENTS ///
import ShopMaterialDialog from "./material";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  current_type: string,
  setCurrent: (material: IShopMaterial) => void
}

export default function ShopCreateMaterial({ open, setOpen, current_type, setCurrent }: Props) {
  const dispatch = useAppDispatch();
  const { material: created_material } = useAppSelector(state => state.shop_material);
  const { create_success } = useAppSelector(state => state.shop_material);
  const [errors, setErrors] = useState<IShopMaterialError>({});
  const [material, setMaterial] = useState<IShopMaterial>({ taxable: true });

  useEffect(() => {
    setMaterial({ ...material, material_type: current_type });
  }, [open]);

  useEffect(() => {
    if (create_success) {
      dispatch(resetCreateShopMaterialSuccess());
      setCurrent(created_material);
      setOpen(false);
    }
  }, [dispatch, setCurrent, setOpen, create_success, created_material]);

  const save = () => {
    let error: boolean = false;
    let material_errors: IShopMaterialError = {};

    if (!material?.name || material.name === "") {
      material_errors.name_error = "Enter a Material Name";
      material_errors.name_has_error = true;
      error = true;
    }

    if (!material?.material_type || material.material_type === "") {
      material_errors.material_type_error = "Choose a Material Typ";
      material_errors.material_type_has_error = true;
      error = true;
    }

    if (!material?.thickness || material.thickness === "") {
      material_errors.thickness_error = "Choose a Thickness Error";
      material_errors.thickness_has_error = true;
      error = true;
    }

    setErrors(material_errors);

    if (error) {
      return;
    }

    dispatch(createShopMaterial(material));
  }

  return (
    <ShopMaterialDialog
      open={open}
      setOpen={setOpen}
      title="Create Material"
      button_text="Create"
      material={material}
      setMaterial={setMaterial}
      errors={errors}
      save={save} />
  )
}
