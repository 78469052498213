/// REACT ///
import { Fragment } from "react";
/// TYPES ///
import { Color } from "api/types/material";
import { Material } from "api/types/material";
import { IShopColor } from "api/types/shop_material";
import { IShopMaterial } from "api/types/shop_material";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// MUI ///
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
/// ICONS ///
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
/// VALUES ///
import { getMaterialHeightDisplay } from "values/material";
import { ULTRA_COMPACT } from "values/material";
import { QUARTZ } from "values/material";
import { NATURAL_STONE } from "values/material";

interface Props {
  description: string,
  colors: Color[] | IShopColor[],
  materials: Material[] | IShopMaterial[]
  selected_type: string,
  setSelectedType: (selected_type: string) => void,
  selected_material: number,
  setSelectedMaterial: (selected_material: IShopMaterial) => void,
  selected_color: number,
  setSelectedColor: (selected_color: IShopColor) => void,
  setCreateMaterialOpen: (open: boolean) => void,
  setCreateColorOpen: (open: boolean) => void,
  setEditMaterialOpen: (open: boolean) => void,
  setEditColorOpen: (open: boolean) => void,
  deleteColorOption: () => void,
  hideAdd?: boolean,
  hideDelete?: boolean,
  showNaturalStone?: boolean,
  saveColor: (
    color_id: number,
    material_id: number,
    material_type: string,
    material_name: string,
    color_name: string,
    color_length: number,
    color_width: number
  ) => void,
}

export default function MainColor({
  description,
  colors,
  materials,
  selected_type,
  setSelectedType,
  selected_material,
  setSelectedMaterial,
  selected_color,
  setSelectedColor,
  setCreateMaterialOpen,
  setCreateColorOpen,
  setEditMaterialOpen,
  setEditColorOpen,
  deleteColorOption,
  hideAdd,
  hideDelete,
  showNaturalStone,
  saveColor
}: Props) {

  const setMaterial = (material_id: number) => {
    let material: Material | IShopMaterial | null = null;
    for (let i = 0; i < materials.length; i++) {
      if (materials[i].id === material_id) {
        material = materials[i];
      }
    }

    if (material) {
      setSelectedMaterial(material);
    }
  }

  const setColor = (color_id: number) => {
    let color: Color | IShopColor | null = null;
    for (let i = 0; i < colors.length; i++) {
      if (colors[i].id === color_id) {
        color = colors[i];
      }
    }

    let material: Material | IShopMaterial | null = null;
    for (let i = 0; i < materials.length; i++) {
      if (materials[i].id === selected_material) {
        material = materials[i];
      }
    }

    if (material) {
      setSelectedMaterial(material);
    }

    if (color) {
      setSelectedColor(color);
    }
    saveColor(
      color_id,
      material?.id,
      selected_type,
      material?.name,
      color?.name,
      color?.length,
      color?.width
    )
  }

  return (
    <Fragment>
      <Grid container item xs={6} md={4} sx={{ paddingTop: 2, paddingBottom: 2, alignItems: "center" }}>
        <Grid item xs={11}>
          <Typography variant="body2" sx={{ marginBottom: "10px" }}>
            <b>{description}</b>
          </Typography>
        </Grid>
        <Grid item xs={1}>
          {
            !hideDelete ?
              <IconButton>
                <FontAwesomeIcon icon={faTrash} size="2xs" onClick={() => deleteColorOption()} />
              </IconButton> :
              null
          }
        </Grid>
        <Grid item xs={11}>
          <TextField
            fullWidth
            select
            value={selected_type}
            onChange={e => setSelectedType(e.target.value)}
            label="Material Type">
            <MenuItem value={QUARTZ} key={QUARTZ}>
              Quartz
            </MenuItem>
            <MenuItem value={ULTRA_COMPACT} key={ULTRA_COMPACT}>
              Ultra Compact / Porcelain
            </MenuItem>
            {
              showNaturalStone ?
                <MenuItem value={NATURAL_STONE} key={NATURAL_STONE}>
                  Natural Stone
                </MenuItem> :
                null
            }
          </TextField>
        </Grid>
        <Grid item xs={1}>
        </Grid>
        <Grid item xs={11}>
          <TextField
            sx={{ marginTop: 1 }}
            SelectProps={{
              MenuProps: {
                sx: { height: "400px" },
              },
            }}
            fullWidth
            select
            value={selected_material}
            onChange={e => setMaterial(Number(e.target.value))}
            label="Material">
            <MenuItem value={0} key={0}>
              {"    "}
            </MenuItem>
            {
              materials.map((material: IShopMaterial | Material) => {
                return (
                  <MenuItem value={material.id} key={material.id}>
                    {material.name} {getMaterialHeightDisplay(material.thickness)}
                  </MenuItem>
                )
              })
            }
          </TextField>
        </Grid>
        <Grid item xs={1}>
          {
            !hideAdd ?
              <Stack direction="row" alignItems="center">
                <IconButton onClick={() => setCreateMaterialOpen(true)}>
                  <FontAwesomeIcon icon={faPlus} size="xs" />
                </IconButton>
                {
                  selected_type && selected_material ?
                    <IconButton onClick={() => setEditMaterialOpen(true)}>
                      <FontAwesomeIcon icon={faEdit} size="xs" />
                    </IconButton> :
                    null
                }
              </Stack> :
              null
          }
        </Grid>
        <Grid item xs={11}>
          <TextField
            SelectProps={{
              MenuProps: {
                sx: { height: "400px" },
              },
            }}
            fullWidth
            select
            sx={{ marginTop: 1 }}
            value={selected_color}
            onChange={e => setColor(Number(e.target.value))}
            label="Color">
            <MenuItem value={0} key={0}>
              {"    "}
            </MenuItem>
            {
              colors.map((color: IShopColor | Color) => {
                return (
                  <MenuItem value={color.id} key={color.id}>
                    {color.name}
                  </MenuItem>
                )
              })}
          </TextField>
        </Grid>
        <Grid item xs={1}>
          <Grid item xs={1}>
            {
              !hideAdd && selected_type && selected_material ?
                <Stack direction="row" alignItems="center">
                  <IconButton onClick={() => setCreateColorOpen(true)}>
                    <FontAwesomeIcon icon={faPlus} size="xs" />
                  </IconButton>
                  {
                    selected_color ?
                      <IconButton onClick={() => setEditColorOpen(true)}>
                        <FontAwesomeIcon icon={faEdit} size="xs" />
                      </IconButton> :
                      null
                  }
                </Stack> :
                null
            }
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}
