
/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ACTIONS ///
import { updateLocalShopArea } from "api/actions/shop_quote";
import { createShopColor } from "api/actions/shop_material";
import { resetCreateShopMaterialSuccess } from "api/actions/shop_material";
/// TYPES ///
import { IShopArea } from "api/types/shop_quote/area";
import { IShopColor } from "api/types/shop_material";
import { IShopMaterial } from "api/types/shop_material";
import { IShopColorError } from "api/types/shop_material";
/// COMPONENTS ///
import ShopColorDialog from "./color";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  current_material: IShopMaterial,
  setCurrent: (color: IShopColor) => void,
  area: IShopArea | null
}

export default function ShopCreateColor({ open, setOpen, current_material, setCurrent, area }: Props) {
  const dispatch = useAppDispatch();
  const { color: created_color } = useAppSelector(state => state.shop_material);
  const { create_success } = useAppSelector(state => state.shop_material);
  const [selected_material_error, setSelectMaterialError] = useState<boolean>(false);
  const [errors, setErrors] = useState<IShopColorError>({});
  const [color, setColor] = useState<IShopColor>({ one_time_use: false });

  useEffect(() => {
    if (create_success) {
      dispatch(resetCreateShopMaterialSuccess());
      setCurrent(created_color);
      setOpen(false);
    }

  }, [dispatch, setCurrent, setOpen, create_success, created_color?.id]);

  const save = () => {
    let error: boolean = false;
    let errors: IShopColorError = {};

    if (!current_material?.id) {
      setSelectMaterialError(true);
      error = true;
    }

    if (!color?.name || color.name === "") {
      errors.name_error = "Enter a Color Name";
      errors.name_has_error = true;
      error = true;
    }

    if (!color?.width || color.width <= 0) {
      errors.width_error = "Width must be greater than 0";
      errors.width_has_error = true;
      error = true;
    }

    if (!color?.length || color.length <= 0) {
      errors.length_error = "Length must be greater than 0";
      errors.length_has_error = true;
      error = true;
    }

    setErrors(errors);

    if (error) {
      return;
    }

    dispatch(createShopColor({ ...color, material: current_material.id }));

    if (area) {
      dispatch(updateLocalShopArea({
        ...area,
        material: current_material.id,
        material_name: current_material.name,
        material_type: current_material.material_type,
        material_length: color.length,
        material_width: color.width,
        color_name: color.name,
        material_thickness: current_material.thickness
      }));
    }
    setOpen(false);
  }

  return (
    <ShopColorDialog
      open={open}
      setOpen={setOpen}
      title="Create Material"
      button_text="Create"
      material={current_material}
      color={color}
      setColor={setColor}
      errors={errors}
      selected_material_error={selected_material_error}
      save={save}
    />
  )
}
