/// REACT-ROUTER ///
import { useNavigate } from "react-router-dom";
/// MUI ///
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
/// FM ///
import { motion } from "framer-motion";
/// TYPES ///
import { IFabricationJob } from "api/types/customer";
/// STYLES ///
import { row_text_item_style } from "styles/universal/row";
import { row_btn_style } from "styles/universal/row";
/// MOTION ///
import { row_text_motion } from "styles/universal/row";
/// URLS ///
import { end_customer_url } from "components/navigation/endpoints";
import { job_url } from "components/navigation/endpoints";

interface Props {
  job: IFabricationJob
}

export default function CxJobItem({ job }: Props) {
  const navigate = useNavigate();

  return (
    <motion.button
      initial="rest"
      whileHover="hover"
      animate="rest"
      variants={row_text_motion}
      onClick={() => navigate(`/${end_customer_url}/${job_url}/${job?.id}`)}
      style={row_btn_style}>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="body2" align="left" noWrap sx={row_text_item_style}>
            {job?.email && job.email !== "" ? job.email : job?.phone_number ?? ""}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" align="right" noWrap sx={row_text_item_style}>
            {job?.geo_address ?? ""}
          </Typography>
        </Grid>
      </Grid>
    </motion.button>
  )
}

