/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState } from "react";
/// ACTIONS ///
import { getFabricationJob } from "api/actions/end_customer";
import { updateFabricationJob } from "api/actions/end_customer";
import { setBreadcrumbs } from "api/actions/ui/set";
/// TYPES ///
import { IFabricationJob } from "api/types/customer";
import { IFabricationJobError } from "api/types/customer";
/// COMPONENTS ///
import UpdateJob from "components/quote/update_job";
/// BREADCRUMBS ///
import { cx_list } from "values/breadcrumbs/customer";
import { cx } from "values/breadcrumbs/customer";
/// VALIDATE ///
import { validateEmail } from "functions/value";

export default function UpdateJobPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { end_customer: customer } = useAppSelector(state => state.end_customer);
  const { fabrication_job: fab_job } = useAppSelector(state => state.end_customer);
  const [job, setJob] = useState<IFabricationJob>({});
  const [errors, setErrors] = useState<IFabricationJobError>({});

  useEffect(() => {
    dispatch(getFabricationJob(id));
  }, [id, dispatch]);

  useEffect(() => {
    setJob(fab_job);
  }, [fab_job?.id]);

  useEffect(() => {
    if (customer?.id) {
      dispatch(setBreadcrumbs([cx_list, { ...cx, label: `${customer?.first_name ?? ""} ${customer?.last_name ?? "Account"}`, url: cx.url.replace("id", id) }]));
    }
  }, [dispatch, customer?.id, customer?.first_name, customer?.last_name, id]);

  const update = () => {
    const errors: IFabricationJobError = {};

    if (!job?.lat || job?.lat === 0 || !job?.lng || job?.lng === 0) {
      errors.geo_error = "Could not find coordinates for this address. Please retype the address and select it from the dropdown options.";
      errors.geo_has_error = true;
    }

    if (!validateEmail(job?.email)) {
      errors.email_error = "Must enter a valid email";
      errors.email_has_error = true;
    }

    setErrors(errors);
    if (errors?.email_has_error || errors?.geo_has_error) {
      return;
    }

    dispatch(updateFabricationJob(job, job?.id));
    navigate(-1);
  }

  return (
    <UpdateJob
      title="Update Job"
      email={job?.email ?? ""}
      setEmail={email => { setJob({ ...job, email }); setErrors({ ...errors, email_error: "", email_has_error: false }) }}
      entry_notes={job?.entry_notes ?? ""}
      setEntryNotes={entry_notes => setJob({ ...job, entry_notes })}
      geo_address={job?.geo_address ?? ""}
      setGeoAddress={(geo_address, lat, lng) => setJob({ ...job, geo_address, lat, lng })}
      phone_number={job?.phone_number ?? ""}
      setPhoneNumber={phone_number => setJob({ ...job, phone_number })}
      errors={errors}
      setErrors={setErrors}
      update={update}
    />
  )
}
