/// REDUX ///
import { Dispatch } from "redux";
/// ACTIONS ///
import { Action } from "api/action_types/shop_quote";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/shop_quote";
/// TYPES ///
import { IShopColorOption } from "api/types/shop_quote/color_option";

export const updateLocalShopColorOption = (color_option: IShopColorOption) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_LOCAL_SHOP_COLOR_OPTION,
      payload: color_option
    });
  }
}
