/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState } from "react";
/// ACTIONS ///
import { createFabricationJob } from "api/actions/end_customer";
import { setBreadcrumbs } from "api/actions/ui/set";
import { updateLocalShopQuote } from "api/actions/shop_quote";
/// TYPES ///
import { IFabricationJob } from "api/types/customer";
import { IFabricationJobError } from "api/types/customer";
/// COMPONENTS ///
import UpdateJob from "components/quote/update_job";
/// VALIDATE ///
import { validateEmail } from "functions/value";

export default function CreateShopQuoteJob() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id, customer_id } = useParams();
  const [job, setJob] = useState<IFabricationJob>({});
  const [errors, setErrors] = useState<IFabricationJobError>({});

  useEffect(() => {
    dispatch(setBreadcrumbs([]));
  }, [dispatch, id]);

  const create = () => {
    const errors: IFabricationJobError = {};

    if (!job?.lat || job?.lat === 0 || !job?.lng || job?.lng === 0) {
      errors.geo_error = "Could not find coordinates for this address. Please retype the address and select it from the dropdown options.";
      errors.geo_has_error = true;
    }

    if (!validateEmail(job?.email)) {
      errors.email_error = "Must enter a valid email";
      errors.email_has_error = true;
    }

    setErrors(errors);
    if (errors?.email_has_error || errors?.geo_has_error) {
      return;
    }

    dispatch(createFabricationJob({ ...job, end_customer: Number(customer_id) }, undefined, id));

    dispatch(updateLocalShopQuote({ job_data: job }));
    navigate(-1);
  }

  return (
    <UpdateJob
      title="Create Job"
      email={job?.email ?? ""}
      setEmail={email => { setJob({ ...job, email }); setErrors({ ...errors, email_error: "", email_has_error: false }) }}
      entry_notes={job?.entry_notes ?? ""}
      setEntryNotes={entry_notes => setJob({ ...job, entry_notes })}
      geo_address={job?.geo_address ?? ""}
      setGeoAddress={(geo_address, lat, lng) => setJob({ ...job, geo_address, lat, lng })}
      phone_number={job?.phone_number ?? ""}
      setPhoneNumber={phone_number => setJob({ ...job, phone_number })}
      errors={errors}
      setErrors={setErrors}
      update={create}
    />
  )
}
