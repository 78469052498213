/// TYPES ///
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopCutShape } from "api/types/shop_quote/cut_shape";
/// ACTIONS ///
import { updateLocalShopCutShapes } from "api/actions/shop_quote";
import { updateShopCounter } from "api/actions/shop_quote";
import { createRestorePoint } from "api/actions/history";
import { createBulkShopCutShapes } from "api/actions/shop_quote";
import { markShopQuoteChanged } from "api/actions/shop_quote";
import { createCutShapes } from "functions/sketch";
/// ACTION TYPES ///
import { IHistoryActionType } from "api/types/history";

export function handleUpdateShopCounter(
  dispatch: (({ ...any }) => void),
  original_counter: IShopCounter,
  counter: IShopCounter,
  original_cut_shapes: IShopCutShape[],
  slab_uuid: string,
  desc: string
) {
  dispatch(updateShopCounter(counter));
  const new_cut_shapes: IShopCutShape[] = createCutShapes(
    counter,
    counter.area_uuid,
    slab_uuid,
    counter.points,
    counter.corners,
    []
  );
  dispatch(updateLocalShopCutShapes(new_cut_shapes, counter.uuid));
  dispatch(createBulkShopCutShapes(new_cut_shapes));

  dispatch(createRestorePoint(
    {
      undo_action: IHistoryActionType.UPDATE_SHOP_COUNTER,
      redo_action: IHistoryActionType.UPDATE_SHOP_COUNTER,
      desc,
      undo_data: original_counter,
      undo_data2: original_cut_shapes,
      redo_data: counter,
      redo_data2: new_cut_shapes
    }
  ));
  dispatch(markShopQuoteChanged());
}
