import { IShopBump } from "api/types/shop_quote/bump";

export enum ActionType {
  CREATE_LOCAL_SHOP_BUMP = "CREATE_LOCAL_SHOP_BUMP",
  UPDATE_LOCAL_SHOP_BUMP = "UPDATE_LOCAL_SHOP_BUMP",
  DELETE_LOCAL_SHOP_BUMP = "DELETE_LOCAL_SHOP_BUMP"
}

interface ICreateLocalShopBump {
  type: ActionType.CREATE_LOCAL_SHOP_BUMP,
  payload: IShopBump
}

interface IUpdateLocalShopBump {
  type: ActionType.UPDATE_LOCAL_SHOP_BUMP,
  payload: IShopBump,
  corner_uuid: string,
  counter_uuid: string
}

interface IDeleteLocalShopBump {
  type: ActionType.DELETE_LOCAL_SHOP_BUMP,
  payload: IShopBump
}

export type Action =
  ICreateLocalShopBump |
  IUpdateLocalShopBump |
  IDeleteLocalShopBump;
