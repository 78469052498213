/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useGetDate } from "hooks/date/get";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ACTIONS ///
import { getEvents } from "api/actions/event";
import { getOrderEvents } from "api/actions/event";
import { getSelfOrderEvents } from "api/actions/event";
/// TYPES ///
import { IGetEventArgs } from "api/types/event";
import { ICustomEvent } from "api/types/event";
import { IOrderEvent } from "api/types/event";
import { ISelfOrderEvent } from "api/types/event";
/// MUI ///
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import DayCustomEvent from "./row/custom";
import DayOrderEvent from "./row/order";
import DaySelfOrderEvent from "./row/self_order";
/// COLORS ///
import { light_grey_color } from "styles/style";
/// FUNCTIONS ///
import { filterEventType } from "components/events/functions/event";
import { filterMorning } from "components/events/functions/event";
import { filterAfternoon } from "components/events/functions/event";
import { filterEvening } from "components/events/functions/event";

const day_grid_style = {
  paddingLeft: "10px",
  paddingRight: "10px",
  paddingTop: "15px",
  paddingBottom: "15px",
  borderBottom: `1px solid ${light_grey_color}`,
  borderLeft: `1px solid ${light_grey_color}`
}

export default function CalendarDay() {
  const dispatch = useAppDispatch();
  const {
    custom_events,
    filter,
    nav_date,
    order_events,
    self_order_events
  } = useAppSelector((state) => state.event);
  const [morning_custom_events, setMorningCustomEvents] = useState<ICustomEvent[]>([]);
  const [morning_order_events, setMorningOrderEvents] = useState<IOrderEvent[]>([]);
  const [morning_self_order_events, setMorningSelfOrderEvents] = useState<ISelfOrderEvent[]>([]);
  const [afternoon_custom_events, setAfternoonCustomEvents] = useState<ICustomEvent[]>([]);
  const [afternoon_order_events, setAfternoonOrderEvents] = useState<IOrderEvent[]>([]);
  const [afternoon_self_order_events, setAfternoonSelfOrderEvents] = useState<ISelfOrderEvent[]>([]);
  const [evening_custom_events, setEveningCustomEvents] = useState<ICustomEvent[]>([]);
  const [evening_order_events, setEveningOrderEvents] = useState<IOrderEvent[]>([]);
  const [evening_self_order_events, setEveningSelfOrderEvents] = useState<ISelfOrderEvent[]>([]);
  const loaded_date = useGetDate(nav_date);

  useEffect(() => {
    const events: IOrderEvent[] = order_events.filter(e => filterEventType(e, filter));

    setMorningOrderEvents(events.filter(e => filterMorning(e)));
    setAfternoonOrderEvents(events.filter(e => filterAfternoon(e)));
    setEveningOrderEvents(events.filter(e => filterEvening(e)));
  }, [order_events, loaded_date, filter]);

  useEffect(() => {
    const events: ICustomEvent[] = custom_events.filter(e => filterEventType(e, filter));


    setMorningCustomEvents(events.filter(e => filterMorning(e)));
    setAfternoonCustomEvents(events.filter(e => filterAfternoon(e)));
    setEveningCustomEvents(events.filter(e => filterEvening(e)));
  }, [custom_events, loaded_date, filter]);

  useEffect(() => {
    const events: ISelfOrderEvent[] = self_order_events.filter(e => filterEventType(e, filter));

    setMorningSelfOrderEvents(events.filter(e => filterMorning(e)));
    setAfternoonSelfOrderEvents(events.filter(e => filterAfternoon(e)));
    setEveningSelfOrderEvents(events.filter(e => filterEvening(e)));
  }, [self_order_events, loaded_date, filter]);

  useEffect(() => {
    const args: IGetEventArgs = {
      start_time: loaded_date.startOf("day").toUTC().toISO(),
      end_time: loaded_date.endOf("day").toUTC().toISO(),
    };
    dispatch(getEvents(args));
    dispatch(getOrderEvents(args));
    dispatch(getSelfOrderEvents(args));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded_date, dispatch, filter]);

  return (
    <Grid container sx={{ paddingTop: "15px", height: "80vh" }}>
      <Grid item xs={2} sx={[day_grid_style, { borderTop: `1px solid ${light_grey_color}` }]}>
        <Typography variant="body2">
          Morning <br />
          8AM - 12PM
        </Typography>
      </Grid>
      <Grid item xs={10} sx={[day_grid_style, { borderTop: `1px solid ${light_grey_color}`, borderRight: `1px solid ${light_grey_color}` }]}>
        <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          {
            morning_order_events.map(event => <DayOrderEvent item={event} key={event.id} />)
          }
          {
            morning_self_order_events.map(event => <DaySelfOrderEvent item={event} key={event.id} />)
          }
          {
            morning_custom_events.map(event => <DayCustomEvent item={event} key={event.id} />)
          }
        </div>
      </Grid>
      <Grid item xs={2} sx={day_grid_style}>
        <Typography variant="body2">
          Afternoon <br />
          12PM - 5PM
        </Typography>
      </Grid>
      <Grid item xs={10} sx={[day_grid_style, { borderRight: `1px solid ${light_grey_color}` }]}>
        <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          {
            afternoon_order_events.map(event => <DayOrderEvent item={event} key={event.id} />)
          }
          {
            afternoon_self_order_events.map(event => <DaySelfOrderEvent item={event} key={event.id} />)
          }
          {
            afternoon_custom_events.map(event => <DayCustomEvent item={event} key={event.id} />)
          }
        </div>
      </Grid>
      <Grid item xs={2} sx={day_grid_style}>
        <Typography variant="body2">
          Evening <br />
          5PM - 8PM
        </Typography>
      </Grid>
      <Grid item xs={10} sx={[day_grid_style, { borderRight: `1px solid ${light_grey_color}` }]}>
        <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          {
            evening_order_events.map(event => <DayOrderEvent item={event} key={event.id} />)
          }
          {
            evening_self_order_events.map(event => <DaySelfOrderEvent item={event} key={event.id} />)
          }
          {
            evening_custom_events.map(event => <DayCustomEvent item={event} key={event.id} />)
          }
        </div>
      </Grid>
    </Grid>
  )
}
