/// TYPES ///
import { Counter } from "api/types/sketch";
import { CutShape } from "api/types/sketch";
import { IHistoryActionType } from "api/types/history";
/// ACTIONS ///
import { updateLocalCutShapes } from "api/actions/sketch/cut_shape";
import { updateCounter } from "api/actions/sketch/counter";
import { createBulkCutShapes } from "api/actions/sketch/cut_shape";
import { createRestorePoint } from "api/actions/history";
import { markQuoteChanged } from "api/actions/quotes/quote";
/// FUNCTIONS ///
import { createCutShapes } from "functions/sketch";

export function handleUpdateCounter(
  dispatch: (({ ...any }) => void),
  original_counter: Counter,
  counter: Counter,
  original_cut_shapes: CutShape[],
  slab_uuid: string,
  desc: string
) {
  dispatch(updateCounter(counter));
  const new_cut_shapes: CutShape[] = createCutShapes(
    counter,
    counter.area_uuid,
    slab_uuid,
    counter.points,
    counter.corners,
    []
  );
  dispatch(updateLocalCutShapes(new_cut_shapes, counter.uuid));
  dispatch(createBulkCutShapes(new_cut_shapes));
  dispatch(createRestorePoint(
    {
      undo_action: IHistoryActionType.UPDATE_COUNTER,
      redo_action: IHistoryActionType.UPDATE_COUNTER,
      desc,
      undo_data: { ...original_counter },
      undo_data2: original_cut_shapes,
      redo_data: counter,
      redo_data2: new_cut_shapes
    }
  ));
  dispatch(markQuoteChanged());
}
