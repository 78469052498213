/// REACT ///
import { ReactNode } from "react";
import { useEffect } from "react";
import { useState } from "react";
/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// ACTIONS ///
import { updateShopQuote } from "api/actions/shop_quote";
/// TYPES ///
import { IShopQuote } from "api/types/shop_quote/quote";
/// MUI ///
import Box from "@mui/material/Box";
import { Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
/// COMPONENTS ///
import ShopMeasurements from "../measurements";
import ShopAngles from "../angles";
import ShopEdges from "../edges";
import ShopAppliances from "../appliances";
import SketchActions from "components/quote/buttons/actions";
import DetailActions from "components/quote/buttons/detail_actions";
import HistorySnackbar from "components/universal/history_snackbar";
import ShopSlabs from "../slabs";
import ShopColorOptions from "../color_options";
import ShopQuoteDetails from "../quote_details";
import CreateShopLabel from "../dialog/label/create";
import Loading from "components/universal/loading";
/// FM ///
import { motion } from "framer-motion";
import { useAnimationControls } from "framer-motion";
/// STYLES ///
import { miniDrawerWidth } from "styles/drawer";
import { tab_underline_style } from "styles/sketch";
import { sketch_tab_btn } from "styles/sketch";
import { sketch_btn_anim } from "styles/sketch";
/// MOTION ///
import { measurement_motion } from "motion/sketch";
import { angles_motion } from "motion/sketch";
import { edges_motion } from "motion/sketch";
import { appliances_motion } from "motion/sketch";
import { material_motion } from "motion/sketch";
import { slabs_motion } from "motion/sketch";
import { quote_motion } from "motion/sketch";

interface TabPanelProps {
  children?: ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

interface Props {
  quote: IShopQuote,
  redo: () => void,
  undo: () => void,
  current_area: number,
  setCurrentArea: (area: number) => void
}

export default function ShopQuoteTabs({ quote, redo, undo, current_area, setCurrentArea }: Props) {
  const controls = useAnimationControls();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [index, setIndex] = useState(0);
  const [zoom, setZoom] = useState<number>(quote.zoom ? quote.zoom / 10 : 0.6);
  const [show_prices, setShowPrices] = useState<boolean>(false);
  const [label_open, setLabelOpen] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [copying, setCopying] = useState<boolean>(false);
  const { alerts } = useAppSelector(state => state.history);
  const { areas_loaded } = useAppSelector(state => state.shop_quote);
  const { quote_loaded } = useAppSelector(state => state.shop_quote);
  const { updating: sketch_updating } = useAppSelector(state => state.shop_quote);
  const { updating: quote_updating } = useAppSelector(state => state.shop_quote);
  const { loading: material_loading } = useAppSelector((state) => state.shop_material);

  useEffect(() => {
    setCopying(false);
  }, [index]);

  useEffect(() => {
    const html = document.querySelector("html");
    if (html && index < 4) {
      html.style.overflow = "hidden";
    }
    return () => {
      const html = document.querySelector("html");
      if (html) {
        html.style.overflow = "visible";
      }
    }
  }, [index]);

  const zoomIn = () => {
    setZoom(prev => prev < 1.6 ? Math.round((prev + 0.1) * 10) / 10 : prev);
    dispatch(updateShopQuote({ zoom: zoom < 1.6 ? Math.round((zoom + 0.1) * 10) : zoom * 10 }, quote.id, false, true));
  }

  const zoomOut = () => {
    setZoom(prev => prev > 0.1 ? Math.round((prev - 0.1) * 10) / 10 : prev);
    dispatch(updateShopQuote({ zoom: zoom > 0.1 ? Math.round((zoom - 0.1) * 10) : zoom * 10 }, quote.id, false, true));
  }

  const changeTab = (index: number) => {
    setIndex(index);
    switch (index) {
      case 0:
        controls.start("measurement");
        break;
      case 1:
        controls.start("angles");
        break;
      case 2:
        controls.start("edges");
        break;
      case 3:
        controls.start("appliances");
        break;
      case 4:
        controls.start("material");
        break;
      case 5:
        controls.start("slabs");
        break;
      case 6:
        controls.start("quote");
        break;
    }
  }

  return (
    <Box sx={{
      position: "absolute",
      top: 50,
      left: `${miniDrawerWidth}px`,
      right: 0,
      bottom: 0,
      bgcolor: "background.paper",
    }}>
      <Stack direction="row" justifyContent="space-between">
        <motion.button style={sketch_tab_btn} whileHover={sketch_btn_anim} onClick={() => changeTab(0)}>
          Measurements
          <motion.div initial="measurement" variants={measurement_motion} animate={controls} style={tab_underline_style}></motion.div>
        </motion.button>
        <motion.button style={sketch_tab_btn} whileHover={sketch_btn_anim} onClick={() => changeTab(1)}>
          Angles
          <motion.div initial="measurement" variants={angles_motion} animate={controls} style={tab_underline_style}></motion.div>
        </motion.button>
        <motion.button style={sketch_tab_btn} whileHover={sketch_btn_anim} onClick={() => changeTab(2)}>
          Edges
          <motion.div initial="measurement" variants={edges_motion} animate={controls} style={tab_underline_style}></motion.div>
        </motion.button>
        <motion.button style={sketch_tab_btn} whileHover={sketch_btn_anim} onClick={() => changeTab(3)}>
          Appliances
          <motion.div initial="measurement" variants={appliances_motion} animate={controls} style={tab_underline_style}></motion.div>
        </motion.button>
        <motion.button style={sketch_tab_btn} whileHover={sketch_btn_anim} onClick={() => changeTab(4)}>
          Material
          <motion.div initial="measurement" variants={material_motion} animate={controls} style={tab_underline_style}></motion.div>
        </motion.button>
        <motion.button style={sketch_tab_btn} whileHover={sketch_btn_anim} onClick={() => changeTab(5)}>
          Slabs
          <motion.div initial="measurement" variants={slabs_motion} animate={controls} style={tab_underline_style}></motion.div>
        </motion.button>
        <motion.button style={sketch_tab_btn} whileHover={sketch_btn_anim} onClick={() => changeTab(6)}>
          Quote
          <motion.div initial="measurement" variants={quote_motion} animate={controls} style={tab_underline_style}></motion.div>
        </motion.button>
      </Stack>
      <TabPanel value={index} index={0} dir={theme.direction}>
        <ShopMeasurements index={index} zoom={zoom} setDisabled={setDisabled} current_area={current_area} setCurrentArea={setCurrentArea} copying={copying} setCopying={setCopying} />
      </TabPanel>
      <TabPanel value={index} index={1} dir={theme.direction}>
        <ShopAngles index={index} zoom={zoom} current_area={current_area} setCurrentArea={setCurrentArea} copying={copying} setCopying={setCopying} />
      </TabPanel>
      <TabPanel value={index} index={2} dir={theme.direction}>
        <ShopEdges index={index} zoom={zoom} current_area={current_area} setCurrentArea={setCurrentArea} copying={copying} setCopying={setCopying} />
      </TabPanel>
      <TabPanel value={index} index={3} dir={theme.direction}>
        <ShopAppliances index={index} zoom={zoom} current_area={current_area} setCurrentArea={setCurrentArea} copying={copying} setCopying={setCopying} />
      </TabPanel>
      <TabPanel value={index} index={4} dir={theme.direction}>
        <ShopColorOptions current_area={current_area} setCurrentArea={setCurrentArea} />
      </TabPanel>
      <TabPanel value={index} index={5} dir={theme.direction}>
        <ShopSlabs index={index} current_area={current_area} setCurrentArea={setCurrentArea} />
      </TabPanel>
      <TabPanel value={index} index={6} dir={theme.direction}>
        <ShopQuoteDetails index={index} quote={quote} show_prices={show_prices} />
      </TabPanel>
      {
        index < 4 ?
          <SketchActions
            disabled={disabled}
            loaded={quote_loaded && areas_loaded}
            zoomIn={zoomIn}
            zoomOut={zoomOut}
            openLabel={() => setLabelOpen(true)}
            redo={redo}
            undo={undo}
            copying={copying}
            toggleCopy={() => setCopying(prev => !prev)} /> :
          null
      }
      {
        index === 6 ?
          <DetailActions
            show_prices={show_prices}
            setShowPrices={setShowPrices} /> :
          null
      }
      {
        alerts.map((alert, index) => <HistorySnackbar index={index} active={alert.active} message={alert.desc} />)
      }
      <Loading loading={sketch_updating || quote_updating || material_loading} />
      <CreateShopLabel open={label_open} setOpen={setLabelOpen} current_area={current_area} />
    </Box >
  );
}
