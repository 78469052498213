/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
/// REACT ///
import { Fragment } from "react";
import { useState } from "react";
/// ACTIONS ///
import { deleteShopAddon } from "api/actions/shop_quote";
/// TYPES ///
import { IShopArea } from "api/types/shop_quote/area";
import { IShopAddon } from "api/types/shop_quote/addon";
import { IShopQuote } from "api/types/shop_quote/quote";
import { IShopQuoteLineItem } from "api/types/shop_quote/quote_line_item";
/// COMPONENTS ///
import UpdateShopQuoteLineItem from "components/store_quote/quote/quote_details/dialog/edit_line";
import QuoteSummary from "components/quote/tabs/quote_details/summary";

interface Props {
  quote: IShopQuote,
  areas: IShopArea[],
  is_fitted: boolean,
  show_prices: boolean
  generateQuote: () => void,
}

export default function ShopQuoteSummary({ quote, areas, is_fitted, show_prices, generateQuote }: Props) {
  const dispatch = useAppDispatch();
  const [open_save, setOpenSave] = useState<boolean>(false);
  const [open_delete, setOpenDelete] = useState<boolean>(false);
  const [open_edit, setOpenEdit] = useState<boolean>(false);
  const [line_item, setLineItem] = useState<IShopQuoteLineItem>({});
  const [addon, setAddon] = useState<IShopAddon>({});

  const deleteAddon = () => {
    dispatch(deleteShopAddon(addon));
    setAddon({});
    setOpenDelete(false);
  }

  const addAddon = () => {
    setAddon({});
    setOpenSave(true);
  }

  const editAddon = (addon: IShopAddon) => {
    setAddon(addon);
    setOpenSave(true);
  }

  const openDeleteAddon = (addon: IShopAddon) => {
    setAddon(addon);
    setOpenDelete(true);
  }

  const editLineItem = (item: IShopQuoteLineItem) => {
    setLineItem(item);
    setOpenEdit(true);
  }

  return (
    <Fragment>
      <QuoteSummary
        quote={quote}
        areas={areas}
        is_fitted={is_fitted}
        addon={addon}
        setAddon={setAddon}
        open_save={open_save}
        setOpenSave={setOpenSave}
        open_delete={open_delete}
        setOpenDelete={setOpenDelete}
        addAddon={addAddon}
        editAddon={editAddon}
        openDeleteAddon={openDeleteAddon}
        deleteAddon={deleteAddon}
        generateQuote={generateQuote}
        show_prices={show_prices}
        openEdit={item => editLineItem(item)}
      />
      <UpdateShopQuoteLineItem
        open={open_edit}
        setOpen={setOpenEdit}
        item={line_item}
        setItem={setLineItem} />
    </Fragment>
  )
}
