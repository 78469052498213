/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// ACTIONS ///
import { deleteShopArea } from "api/actions/shop_quote";
import { updateLocalShopCutShapesByArea } from "api/actions/shop_quote";
/// TYPES ///
import { IShopArea } from "api/types/shop_quote/area";
/// COMPONENTS ///
import DeleteArea from "components/quote/dialog/area/delete";

interface Props {
  area: IShopArea,
  open: boolean,
  setOpen: (open: boolean) => void,
  current_area: number,
  setCurrentArea: (area: number) => void
}

export default function DeleteShopArea({ area, open, setOpen, current_area, setCurrentArea }: Props) {
  const dispatch = useAppDispatch();
  const { areas } = useAppSelector((state) => state.shop_quote);

  const remove = () => {
    setOpen(false);
    if (current_area === (areas?.length - 1) ?? 0) {
      setCurrentArea(0);
    }

    dispatch(updateLocalShopCutShapesByArea([], area?.uuid));
    dispatch(deleteShopArea(area));
  }

  return (
    <DeleteArea
      name={area?.name ?? ""}
      open={open}
      setOpen={setOpen}
      remove={remove} />
  )
}
