/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ROUTER ///
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
/// ACTIONS ///
import { getQuote } from "api/actions/quotes/quote";
import { updateQuote } from "api/actions/quotes/quote";
import { setBreadcrumbs } from "api/actions/ui/set";
/// TYPES ///
import { Quote } from "api/types/quote";
import { QuoteErrors } from "api/types/quote";
/// COMPONENTS ///
import UpdateQuote from "components/quote/quote_preview/update";
/// URLS ///
import { detail_url } from "components/navigation/endpoints";
import { preview_url } from "components/navigation/endpoints";
import { quote_url } from "components/navigation/endpoints";
/// BREADCRUMBS ///
import { quote_list } from "values/breadcrumbs/quote";
import { quote_preview } from "values/breadcrumbs/quote";
import { quote_update } from "values/breadcrumbs/quote";

export default function StoreUpdateQuote() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { quote: original_quote } = useAppSelector(state => state.quote);
  const { id } = useParams();
  const [errors, setErrors] = useState<QuoteErrors>({});
  const [quote, setQuote] = useState<Quote>({});


  useEffect(() => {
    if (original_quote) {
      setQuote(original_quote);
    }
  }, [original_quote?.id]);

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { ...quote_list, url: quote_list.url.replace("base_url", "store") },
      { ...quote_preview, url: quote_preview.url.replace("base_url", "store").replace("id", id) },
      { ...quote_update, url: quote_update.url.replace("base_url", "store").replace("id", id) }
    ]));
  }, [dispatch, id]);

  useEffect(() => {
    if (!quote?.id || quote.id.toString() !== id) {
      dispatch(getQuote(id));
    }
  }, [id, dispatch, quote]);

  const save = () => {
    if (quote?.name === "") {
      setErrors({ name_error: "name must not be blank", name_has_error: true });
      return;
    }

    dispatch(updateQuote({ name: quote.name, estimate_number: quote.estimate_number }, id, false));
    navigate(`/store/${quote_url}/${detail_url}/${id}/${preview_url}`);
  }

  return (
    <UpdateQuote
      base_url="store"
      name={quote?.name ?? ""}
      setName={name => setQuote({ ...quote, name })}
      estimate_number={quote?.estimate_number ?? ""}
      setEstimateNumber={estimate_number => setQuote({ ...quote, estimate_number })}
      errors={errors}
      save={save} />
  )
}
