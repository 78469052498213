/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ROUTER ///
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
/// ACTIONS ///
import { getSelfOrderEvent } from "api/actions/event";
import { updateSelfOrderEvent } from "api/actions/event";
import { resetActionSuccess } from "api/actions/event/local/reset_action_success";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import Loading from "components/universal/loading";
import PageTitle from "components/universal/page/title";
/// TYPES ///
import { ISelfOrderEvent } from "api/types/event";
/// FM ///
import { motion } from "framer-motion";
/// LUXON ///
import { DateTime } from "luxon";
/// MUI ///
import Box from "@mui/material/Box";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
/// ICONS ///
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-solid-svg-icons";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// STYLES ///
import { calendar_content_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";

export default function EditSelfOrderEvent() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [event, setEvent] = useState<ISelfOrderEvent>({});
  const { self_order_event } = useAppSelector((state) => state.event);
  const { updating } = useAppSelector((state) => state.event);

  useEffect(() => {
    dispatch(getSelfOrderEvent(id));
  }, [dispatch, id]);

  useEffect(() => {
    setEvent(self_order_event);
  }, [self_order_event]);

  const updateEvent = () => {
    dispatch(updateSelfOrderEvent(event, id));
    navigate(-1);
  }

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={calendar_content_style}>
      <Grid container sx={{ paddingTop: "25px", paddingRight: "75px" }}>
        <PageTitle title="Update Calendar Event" endProps={
          <Box>
            <BaseButton clickEvent={() => navigate(-1)} text="Cancel" icon={faTimes} />
            <BaseButton clickEvent={updateEvent} text="Update" icon={faSave} />
          </Box>
        } />
        <Grid item xs={12}>
          <Box style={header_style}></Box>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: "20px" }}>
          <TextField
            value={event?.title ?? ""}
            sx={{ width: "400px" }}
            onChange={e => setEvent({ ...event, title: e.target.value })}
            label="Name"
            size="small" />
        </Grid>
        <Grid item xs={12}>
          <DateTimePicker
            sx={{ width: "400px" }}
            value={DateTime.fromISO(event?.time ?? DateTime.now().toISO())}
            slotProps={{ textField: { size: "small", InputLabelProps: { shrink: true }, fullWidth: true } }}
            label="Date"
            onChange={e => setEvent({ ...event, time: e.toISO() })} />
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: "20px" }}>
          <TextField
            value={event?.notify_email ?? ""}
            sx={{ width: "400px" }}
            onChange={e => setEvent({ ...event, notify_email: e.target.value })}
            label="Assign To (Email)"
            size="small" />
        </Grid>
        <Grid item xs={12}>
          <TextField
            style={{ width: "400px", marginTop: "10px" }}
            value={event?.description ?? ""}
            multiline
            minRows={3}
            label="Description"
            onChange={(e) => setEvent({ ...event, description: e.target.value })} />
        </Grid>
      </Grid>
      <Loading loading={updating} />
    </motion.div>
  )
}
