export enum IHistoryActionType {
  CREATE_COUNTER = "CREATE_COUNTER",
  UPDATE_COUNTER = "UPDATE_COUNTER",
  DELETE_COUNTER = "DELETE_COUNTER",
  REDRAW_COUNTER = "REDRAW_COUNTER",
  MOVE_COUNTER = "MOVE_COUNTER",
  CREATE_SINK = "CREATE_SINK",
  UPDATE_SINK = "UPDATE_SINK",
  DELETE_SINK = "DELETE_SINK",
  CREATE_COOKTOP = "CREATE_COOKTOP",
  UPDATE_COOKTOP = "UPDATE_COOKTOP",
  DELETE_COOKTOP = "DELETE_COOKTOP",
  UPDATE_CORNER = "UPDATE_CORNER",
  UPDATE_EDGE = "UPDATE_EDGE",
  UPDATE_BUMPS = "UPDATE_BUMPS",
  CREATE_SHOP_COUNTER = "CREATE_SHOP_COUNTER",
  UPDATE_SHOP_COUNTER = "UPDATE_SHOP_COUNTER",
  DELETE_SHOP_COUNTER = "DELETE_SHOP_COUNTER",
  MOVE_SHOP_COUNTER = "MOVE_SHOP_COUNTER",
  CREATE_SHOP_SINK = "CREATE_SHOP_SINK",
  UPDATE_SHOP_SINK = "UPDATE_SHOP_SINK",
  DELETE_SHOP_SINK = "DELETE_SHOP_SINK",
  CREATE_SHOP_COOKTOP = "CREATE_SHOP_COOKTOP",
  UPDATE_SHOP_COOKTOP = "UPDATE_SHOP_COOKTOP",
  DELETE_SHOP_COOKTOP = "DELETE_SHOP_COOKTOP",
}

export interface IHistory {
  undo_action?: IHistoryActionType,
  redo_action?: IHistoryActionType,
  desc?: string,
  undo_data?: any,
  undo_data2?: any,
  redo_data?: any,
  redo_data2?: any
}

export interface IHistoryWarning {
  desc: string,
  active: boolean
}


/// undo list and redo list
// action -- put on undo list, wipe redo list
// undo - move to current redo list, move last undo to current
// current -- in neither list
// redo-- move to current to undo list, move last redo to current
//

