/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
/// REACT ///
import { useState } from "react";
/// UUID ///
import { v4 } from "uuid";
/// ACTIONS ///
import { createShopCooktop } from "api/actions/shop_quote";
import { markShopQuoteChanged } from "api/actions/shop_quote";
import { createRestorePoint } from "api/actions/history";
/// TYPES ///
import { IHistoryActionType } from "api/types/history";
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopCooktop } from "api/types/shop_quote/cooktop";
import { CutoutError } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
/// COMPONENTS ///
import SaveCooktop from "components/quote/drawer/cooktop";
/// EMPTY ///
import { empty_cooktop } from "values/empty/quote/cooktop";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  selected_counter: IShopCounter,
  mouse: Vector2
}

export default function CreateShopCooktop({ open, setOpen, selected_counter, mouse }: Props) {
  const dispatch = useAppDispatch();
  const [cooktop, setCooktop] = useState<IShopCooktop>({ ...empty_cooktop });

  const [error, setError] = useState<CutoutError>({});

  const close = () => {
    setOpen(false);
    setCooktop({ ...empty_cooktop });
    setError({});
  }

  const save = () => {
    let error: CutoutError = {};
    if (!cooktop?.length) {
      error.length_error = "Length must be greater than 0";
      error.length_has_error = true;
    }

    if (!cooktop?.width) {
      error.width_error = "Width must be greater than 0";
      error.width_has_error = true;
    }

    if (error.width_error || error.length_error) {
      setError(error);
      return;
    }

    if (selected_counter) {
      const new_cooktop: IShopCooktop = {
        ...cooktop,
        uuid: v4(),
        angle: -90,
        counter_uuid: selected_counter?.uuid,
        location: {
          X: Math.round(mouse.X),
          Y: Math.round(mouse.Y)
        },
        x: Math.round(mouse.X),
        y: Math.round(mouse.Y)
      }
      dispatch(createShopCooktop(new_cooktop));
      dispatch(markShopQuoteChanged());

      dispatch(createRestorePoint(
        {
          undo_action: IHistoryActionType.DELETE_SHOP_COOKTOP,
          redo_action: IHistoryActionType.CREATE_SHOP_COOKTOP,
          desc: "Create Cooktop",
          undo_data: { uuid: new_cooktop.uuid, counter_uuid: new_cooktop.counter_uuid },
          redo_data: new_cooktop
        }
      ));
      close();
    }
  }


  return (
    <SaveCooktop
      open={open}
      setOpen={setOpen}
      num_burners={cooktop?.num_burners ?? 0}
      setNumBurners={(num_burners => setCooktop({ ...cooktop, num_burners }))}
      length={cooktop?.length ?? 30}
      setLength={length => setCooktop({ ...cooktop, length })}
      length_error={error?.length_error ?? ""}
      length_has_error={error?.length_has_error ?? false}
      width={cooktop?.width ?? 26}
      setWidth={width => setCooktop({ ...cooktop, width })}
      width_error={error?.width_error ?? ""}
      width_has_error={error?.width_has_error ?? false}
      save={save}
      cancel={close}
      title="Create Cooktop Cutout" />
  );
}

