
/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ACTIONS ///
import { updateLocalShopArea } from "api/actions/shop_quote";
import { updateShopColor } from "api/actions/shop_material";
/// TYPES ///
import { IShopArea } from "api/types/shop_quote/area";
import { IShopColor } from "api/types/shop_material";
import { IShopMaterial } from "api/types/shop_material";
import { IShopColorError } from "api/types/shop_material";
/// COMPONENTS ///
import ShopColorDialog from "./color";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  current_material: IShopMaterial,
  current_color: IShopColor,
  area: IShopArea | null
}

export default function ShopUpdateColor({ open, setOpen, current_material, current_color, area }: Props) {
  const dispatch = useAppDispatch();
  const [selected_material_error, setSelectMaterialError] = useState<boolean>(false);
  const [errors, setErrors] = useState<IShopColorError>({});
  const [material, setMaterial] = useState<IShopMaterial>();
  const [color, setColor] = useState<IShopColor>();

  useEffect(() => {
    setMaterial(current_material);
    setColor(current_color);
  }, [open]);

  const save = () => {
    let error: boolean = false;
    let errors: IShopColorError = {};

    if (!material?.id) {
      setSelectMaterialError(true);
      error = true;
    }

    if (!color?.name || color.name === "") {
      errors.name_error = "Enter a Color Name";
      errors.name_has_error = true;
      error = true;
    }

    if (!color?.width || color.width <= 0) {
      errors.width_error = "Width must be greater than 0";
      errors.width_has_error = true;
      error = true;
    }

    if (!color?.length || color.length <= 0) {
      errors.length_error = "Length must be greater than 0";
      errors.length_has_error = true;
      error = true;
    }

    setErrors(errors);

    if (error) {
      return;
    }

    dispatch(updateShopColor({ ...color }, color.id));

    if (area) {
      dispatch(updateLocalShopArea({
        ...area,
        material: current_material.id,
        material_name: current_material.name,
        material_type: current_material.material_type,
        material_length: color.length,
        material_width: color.width,
        color_name: color.name,
        material_thickness: current_material.thickness
      }));
    }
    setOpen(false);
  }

  return (
    <ShopColorDialog
      open={open}
      setOpen={setOpen}
      title="Update Color"
      button_text="Update"
      material={material}
      color={color}
      setColor={setColor}
      errors={errors}
      selected_material_error={selected_material_error}
      save={save}
    />
  )
}
