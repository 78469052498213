/// MUI COMPONENTS ///
import { MenuItem } from "@mui/material";
import { TextField } from "@mui/material";
/// VALUES ///
import { PROVINCES } from "values/geography";

interface Props {
  province: string,
  setProvince: (province: string) => void,
  input_label?: string
}

export default function ProvinceSelector({ province, setProvince, input_label = "Billing Province" }: Props) {
  return (
    <TextField
      sx={{ width: "265px" }}
      SelectProps={{
        MenuProps: {
          sx: { height: "400px" },
        },
      }}
      value={province ?? ""}
      onChange={e => setProvince(e.target.value)}
      label={input_label}
      select>
      <MenuItem key="" value="">
      </MenuItem>
      {
        PROVINCES.map(province => {
          return (
            <MenuItem key={province} value={province}>
              {province}
            </MenuItem>
          )
        })
      }
    </TextField>

  )
}
