/// MUI  ///
import { styled } from "@mui/material/styles";
/// COMPONENTS ///
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import { RadioProps } from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
/// VALUES ///
import { NONE } from "./values";
import { EXISTING } from "./values";
import { NEW } from "./values";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark" ? "rgba(57,75,89,.5)" : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

function BpRadio(props: RadioProps) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

interface Props {
  show: (showing: string) => void
}

export default function AddCustomerRadios({ show }: Props) {
  return (
    <FormControl sx={{ paddingLeft: "5px", paddingTop: "20px" }}>
      <FormLabel id="add-customer-radios">
        <Typography variant="body2">
          Add Account
        </Typography>
      </FormLabel>
      <RadioGroup row defaultValue="none" aria-labelledby="customer-radios" name="customer-radios" onChange={e => show(e.target.value)}>
        <FormControlLabel
          value={NONE}
          control={<BpRadio />}
          label={
            <Typography variant="body2">
              None
            </Typography>
          } />
        <FormControlLabel
          value={EXISTING}
          control={<BpRadio />}
          label={
            <Typography variant="body2">
              Existing Account
            </Typography>
          } />
        <FormControlLabel
          value={NEW}
          control={<BpRadio />}
          label={
            <Typography variant="body2">
              New
            </Typography>
          } />
      </RadioGroup>
    </FormControl>
  );
}
