/// REDUX ///
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
/// ACTION ///
import { Action } from "api/action_types/history";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/history";
/// TYPES ///
import { IHistory } from "api/types/history";
/// ACTIONS ///
import { restoreAction } from "../api/history_action";

export const restore = () => {
  return (dispatch: Dispatch<Action>, getState: () => RootState) => {
    const restore: IHistory = getState().history.restore;

    restoreAction(
      restore.undo_action,
      restore.undo_data,
      restore.undo_data2,
      dispatch
    );

    dispatch({
      type: ActionType.RESTORE
    });
  }
}
