/// TYPES ///
import { IHistory } from "api/types/history";
import { IHistoryWarning } from "api/types/history";
/// ACTIONS ///
import { Action } from "api/action_types/history";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/history";

interface State {
  undo_history: IHistory[],
  redo_history: IHistory[],
  restore: IHistory,
  alerts: IHistoryWarning[],
  error: string
}

const initialState = {
  undo_history: [],
  redo_history: [],
  restore: {},
  alerts: [],
  error: ""
}

export const historyReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.CREATE_HISTORY_POINT:
      return {
        ...state,
        undo_history: [...state.undo_history, action.history],
        redo_history: [],

      }
    case ActionType.UNDO:
      const undo_last: IHistory = state.undo_history[state.undo_history.length - 1];

      return {
        ...state,
        undo_history: [...state.undo_history.slice(0, -1)],
        redo_history: [...state.redo_history, undo_last],
        alerts: [...state.alerts, {
          desc: `Undo ${undo_last.desc}`,
          active: true
        }]
      }
    case ActionType.REDO:
      const redo_last: IHistory = state.redo_history[state.redo_history.length - 1];

      return {
        ...state,
        redo_history: [...state.redo_history.slice(0, -1)],
        undo_history: [...state.undo_history, redo_last],
        alerts: [...state.alerts, {
          desc: `Redo ${redo_last.desc}`,
          active: true
        }]
      }
    case ActionType.RESTORE: {
      return {
        ...state,
        restore: {}
      }
    }
    case ActionType.REPLACE_RESTORE_POINT:
      return {
        ...state,
        restore: action.history
      }
    case ActionType.CLOSE_ALERT:
      return {
        ...state,
        alerts: [...state.alerts.slice(1)]
      }
    case ActionType.CLEAR_HISTORY:
      return {
        ...state,
        undo_history: [],
        redo_history: [],
        error: ""
      }
    default:
      return state;
  }
}
