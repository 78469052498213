/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ACTIONS ///
import { updateLocalShopCounter } from "api/actions/shop_quote";
import { replaceRestorePoint } from "api/actions/history";
import { restore } from "api/actions/history";
/// TYPES ///
import { IHistoryActionType } from "api/types/history";
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopCorner } from "api/types/shop_quote/corner";
import { IShopSlab } from "api/types/shop_quote/slab";
import { CornerError } from "api/types/quote/error";
/// COMPONENTS ///
import UpdateCorner from "components/quote/drawer/update_corner";
/// FUNCTIONS ///
import { getFirstShopSlabInAreas } from "functions/sketch/get/shop_slab";
import { updateShopCorner } from "functions/sketch/update/shop_corner";
import { handleUpdateShopCounter } from "handler/shop_counter/update";
/// VALUES ///
import { STANDARD } from "values/quote";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  original_corner: IShopCorner | null,
  selected_counter: IShopCounter | null
}

export default function UpdateShopCorner({ open, setOpen, original_corner, selected_counter }: Props) {
  const dispatch = useAppDispatch();
  const { areas } = useAppSelector(state => state.shop_quote);
  const { cut_shapes } = useAppSelector(state => state.shop_quote);
  const [error, setError] = useState<CornerError>({});
  const [corner, setCorner] = useState<IShopCorner>({});

  useEffect(() => {
    if (open && original_corner) {
      setCorner(original_corner);
      dispatch(replaceRestorePoint(
        {
          undo_action: IHistoryActionType.UPDATE_SHOP_COUNTER,
          desc: "Cancel Update Corner",
          undo_data: { ...selected_counter }
        }
      ));
    }
  }, [open, dispatch]);

  const save = () => {
    if (selected_counter) {
      const counter: IShopCounter = updateShopCorner(selected_counter, corner);
      const slab: IShopSlab | null = getFirstShopSlabInAreas(areas, counter.area_uuid);

      handleUpdateShopCounter(
        dispatch,
        { ...selected_counter },
        counter,
        cut_shapes.filter(shape => shape.counter_uuid === counter.uuid),
        slab.uuid,
        `Change ${corner.corner_type.toUpperCase()} Corner`
      );
      setError({});
      setOpen(false);
    }
  }

  const update = (corner: IShopCorner) => {
    const counter: IShopCounter = updateShopCorner(selected_counter, corner);
    dispatch(updateLocalShopCounter(counter));
    setCorner(corner);
  }

  const cancel = () => {
    dispatch(restore());
    setError({});
    setOpen(false);
  }

  return (
    <UpdateCorner
      open={open}
      setOpen={setOpen}
      cancel={cancel}
      save={save}
      corner_type={corner?.corner_type ?? STANDARD}
      setCornerType={corner_type => update({ ...corner, corner_type })}
      corner_radius={corner?.corner_radius ?? 0}
      setCornerRadius={corner_radius => update({ ...corner, corner_radius })}
      radius_error={error?.radius_error}
      radius_has_error={error?.radius_has_error}
      corner_length={corner?.corner_length ?? 0}
      setCornerLength={corner_length => update({ ...corner, corner_length })}
      length_error={error?.length_error}
      length_has_error={error?.length_has_error}
      corner_depth={corner?.corner_depth ?? 0}
      setCornerDepth={corner_depth => update({ ...corner, corner_depth })}
      depth_error={error?.depth_error}
      depth_has_error={error?.depth_has_error}
    />
  );
}
