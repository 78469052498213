/// TYPES ///
import { User } from "api/types/authentication";
import { IFabricationJob } from "api/types/customer";
/// COMPONENTS ///
import { Fragment } from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
/// FUNCTIONS ///
import { getDate } from "functions/date";
import { getExpiryDate } from "functions/date";
/// STYLES ///
import { wo_title_container_style } from "styles/market";
import { wo_title_style } from "styles/market";
import { wo_txt_style } from "styles/market";
/// COLORS ///
import { light_grey_color } from "styles/style";

interface Props {
  invoice_number: string,
  dealer: User,
  fabricator: User,
  job: IFabricationJob,
  created: string
}

export default function WOHeader({ invoice_number, dealer, fabricator, job, created }: Props) {
  return (
    <Fragment>
      <Grid item xs={6} sx={{ height: "80px" }}>
        <img src={typeof (dealer.logo) === "string" ? dealer.logo : ""} width={130} height={65}
          style={{ objectFit: "contain", objectPosition: "left" }} alt="Business Logo" />
      </Grid>
      <Grid item xs={6} sx={wo_title_container_style}>
        <Stack display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h2" sx={wo_title_style}>
            WORK ORDER
          </Typography>
          <Typography variant="body2" style={wo_title_style}>
            Invoice #: {invoice_number}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={6} sx={{ marginTop: "15px", height: "155px", paddingTop: "5px", paddingLeft: "5px" }}>
        <Stack>
          <Typography variant="h6" sx={wo_txt_style}>
            Issued From
          </Typography>
          <Typography variant="body2" sx={wo_txt_style}>
            {dealer?.business_name ?? ""}
          </Typography>
          <Typography variant="body2" sx={wo_txt_style}>
            {dealer?.address ?? ""}
          </Typography>
          <Typography variant="body2" sx={wo_txt_style}>
            {dealer?.city ?? ""} {dealer?.city !== "" ? ", " : ""}{dealer?.province ?? ""}
          </Typography>
          <Typography variant="body2" sx={wo_txt_style}>
            {dealer?.postal_code ?? ""}
          </Typography>
          <Typography variant="body2" sx={wo_txt_style}>
            {dealer?.email ?? ""}
          </Typography>
          <Typography variant="body2" sx={wo_txt_style}>
            {dealer?.phone_number ?? ""}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={6} sx={{ marginTop: "15px", height: "155px", padding: "5px", paddingRight: "5px", backgroundColor: light_grey_color }}>
        <Stack alignItems="end" sx={{ paddingRight: 0.5 }}>
          <Typography variant="h6" sx={wo_txt_style}>
            Issued To
          </Typography>
          <Typography variant="body2" textAlign="right" sx={wo_txt_style}>
            {fabricator?.first_name ?? ""} {fabricator?.last_name ?? ""}
          </Typography>
          <Typography variant="body2" textAlign="right" sx={wo_txt_style}>
            {fabricator?.address ?? ""}
          </Typography>
          <Typography variant="body2" textAlign="right" sx={wo_txt_style}>
            {fabricator?.city ?? ""}, {fabricator?.city !== "" ? " " : ""}{fabricator?.province ?? ""}
          </Typography>
          <Typography variant="body2" textAlign="right" sx={wo_txt_style}>
            {fabricator?.postal_code ?? ""}
          </Typography>
          <Typography variant="body2" sx={wo_txt_style}>
            {fabricator?.email ?? ""}
          </Typography>
          <Typography variant="body2" sx={wo_txt_style}>
            {fabricator?.phone_number ?? ""}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={6} sx={{ marginTop: "20px", height: "130px", paddingLeft: "5px" }}>
        <Stack alignItems="start">
          <Typography variant="body2" textAlign="right" sx={wo_txt_style}>
            {job?.geo_address ?? ""}
          </Typography>
          <Typography variant="body2" textAlign="right" sx={wo_txt_style}>
            {job?.email ?? ""}
          </Typography>
          <Typography variant="body2" textAlign="right" sx={wo_txt_style}>
            {job?.phone_number ?? ""}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={6} sx={{ marginTop: "20px", height: "130px", paddingRight: "10px" }}>
        <Stack>
          <Typography variant="body2" align="right" sx={wo_txt_style}>
            Created on: {getDate(created)}
          </Typography>
          <Typography variant="body2" align="right" sx={wo_txt_style}>
            Expires on: {getExpiryDate(created)}
          </Typography>
        </Stack>
      </Grid>
    </Fragment>
  )
}
