/// TYPES ///
import { Counter } from "api/types/sketch";
import { CutShape } from "api/types/sketch";
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopCutShape } from "api/types/shop_quote/cut_shape";
import { IHistoryActionType } from "api/types/history";
/// ACTIONS ///
import { deleteLocalShopCounter } from "api/actions/shop_quote";
import { deleteLocalShopCutShapes } from "api/actions/shop_quote";
import { deleteShopCounter } from "api/actions/shop_quote";
import { createRestorePoint } from "api/actions/history";
import { markShopQuoteChanged } from "api/actions/shop_quote";
import { deleteLocalCounter } from "api/actions/sketch/counter";
import { deleteLocalCutShapes } from "api/actions/sketch/cut_shape";
import { deleteCounter } from "api/actions/sketch/counter";
import { markQuoteChanged } from "api/actions/quotes/quote";

export function handleDeleteCounter(
  dispatch: (({ ...any }) => void),
  setSelectedCounter: (counter: Counter | null) => void,
  counter_uuid: string,
  counter_id: number,
  counter: Counter,
  cut_shapes: CutShape[]
) {
  dispatch(deleteLocalCounter(counter_uuid));
  dispatch(deleteLocalCutShapes(counter_uuid));
  dispatch(deleteCounter(counter_uuid, counter_id));
  dispatch(createRestorePoint(
    {
      undo_action: IHistoryActionType.CREATE_COUNTER,
      redo_action: IHistoryActionType.DELETE_COUNTER,
      desc: "Remove Counter",
      undo_data: counter,
      undo_data2: cut_shapes,
      redo_data: { uuid: counter.uuid }
    },
  ));
  setSelectedCounter(null);
  dispatch(markQuoteChanged());
}

export function handleDeleteShopCounter(
  dispatch: (({ ...any }) => void),
  setSelectedCounter: (counter: IShopCounter | null) => void,
  counter_uuid: string,
  counter_id: number,
  counter: IShopCounter,
  cut_shapes: IShopCutShape[]
) {
  dispatch(deleteLocalShopCounter(counter_uuid));
  dispatch(deleteLocalShopCutShapes(counter_uuid));
  dispatch(deleteShopCounter(counter_uuid, counter_id));

  dispatch(createRestorePoint(
    {
      undo_action: IHistoryActionType.CREATE_SHOP_COUNTER,
      redo_action: IHistoryActionType.DELETE_SHOP_COUNTER,
      desc: "Remove Counter",
      undo_data: counter,
      undo_data2: cut_shapes,
      redo_data: { uuid: counter.uuid }
    },
  ));

  setSelectedCounter(null);
  dispatch(markShopQuoteChanged());
}
