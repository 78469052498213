/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
/// UUID ///
import { v4 } from "uuid";
/// ACTIONS ///
import { createShopColorOption } from "api/actions/shop_quote";
import { updateShopArea } from "api/actions/shop_quote";
/// TYPES ///
import { IShopArea } from "api/types/shop_quote/area";
import { IShopColorOption } from "api/types/shop_quote/color_option";
/// MUI ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import ShopColorItem from "./row/color";
import ShopMainColorItem from "./row/main_color";
import TooltipButton from "components/universal/button/tooltip";
/// ICONS ///
import { faPlus } from "@fortawesome/free-solid-svg-icons";
/// LODASH ///
import { debounce } from "lodash";
/// VALUES ///
import { header_style } from "styles/universal/section_header";

interface Props {
  area: IShopArea,
  updateLoading: (is_loading: boolean, id: number | string) => void,
}

export default function ShopColorOptionArea({ area, updateLoading }: Props) {
  const dispatch = useAppDispatch();
  const [local_area, setLocalArea] = useState<IShopArea>({});

  useEffect(() => {
    setLocalArea(area);
  }, [area]);

  const update = useCallback(debounce(function (name: string, uuid: string, id: string | number) {
    dispatch(updateShopArea({ uuid, name }, id, true));
    updateLoading(false, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, 1000), []);

  const updateName = (name: string, uuid: string, id: string | number) => {
    setLocalArea({ ...local_area, name });
    update(name, uuid, id);
    updateLoading(true, id);
  }

  const addColorOption = () => {
    const color_option: IShopColorOption = {
      number: local_area?.color_options?.length ?? 0 + 1,
      uuid: v4(),
      area_uuid: local_area?.uuid,
      material: local_area?.material,
      color: local_area?.color,
      material_name: local_area?.material_name ?? "",
      material_type: local_area?.material_type ?? "",
      material_length: local_area?.material_length ?? 0,
      material_width: local_area?.material_length ?? 0,
      subtotal: 0,
    }
    dispatch(createShopColorOption(color_option));
  }

  return (
    <Grid container spacing={4} alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h6">
          Materials
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={header_style}></Box>
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Area Name"
          value={local_area?.name ?? ""}
          onChange={(e) => updateName(e.target.value, local_area?.uuid, local_area?.id)} />
      </Grid>
      <Grid item xs={6}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
          <TooltipButton text="Color Option" icon={faPlus} clickEvent={addColorOption} tooltip="Add Alternate Price to Estimate" />
        </div>
      </Grid>
      <ShopMainColorItem
        key="MAIN"
        area={local_area}
        description="Main Color" />
      {
        local_area?.color_options ?
          local_area?.color_options?.map((color_option, index) => {
            return (
              <ShopColorItem
                key={color_option?.uuid}
                color_option={color_option}
                description={"Color Option #" + (index + 1)} />
            )
          }) :
          null
      }
    </Grid>
  )
}
