/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
/// ACTIONS ///
import { getAreas } from "api/actions/quotes/area";
import { getCounters } from "api/actions/sketch/counter";
import { getCutShapes } from "api/actions/sketch/cut_shape";
import { getFabricatorQuote } from "api/actions/market";
/// MUI COMPONENTS ///
import Box from "@mui/material/Box";
/// COMPONENTS ///
import LoadingPage from "components/universal/loading_page";
import PrintLineItems from "components/universal/print/line_items";
import WOPrintHeader from "components/universal/print/header/wo";
import PrintSummaryHeaders from "components/universal/print/summary_headers";
import PrintSummary from "components/universal/print/summary";
import PrintTerms from "components/universal/print/terms";
import PrintSignature from "components/universal/print/signature";
import QuotePrintAreas from "components/universal/diagrams/print_counter/areas";
import QuotePrintShapeAreas from "components/universal/diagrams/print_cut_shapes/areas";
/// PDF ///
import { PDFViewer } from "@react-pdf/renderer";
import { Page } from "@react-pdf/renderer";
import { Document } from "@react-pdf/renderer";
import { StyleSheet } from "@react-pdf/renderer";
import { Font } from "@react-pdf/renderer";
import { Image } from "@react-pdf/renderer";
/// IMAGE ///
import Logo from "static/logo_icon.png";
/// FM ///
import { motion } from "framer-motion";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// STYLES ///
import { content_container_style } from "styles/universal/page";

export default function MarketPrintPreview() {
  const dispatch = useAppDispatch();
  const [logo, setLogo] = useState<string | null>(null);
  const [logo_read, setLogoRead] = useState<boolean>(false);
  const { market_id } = useParams();
  const { quote } = useAppSelector((state) => state.market);
  const {
    counters,
    counters_loaded,
    cut_shapes
  } = useAppSelector((state) => state.sketch);
  const { areas, areas_loaded } = useAppSelector((state) => state.quote);
  const { user } = useAppSelector((state) => state.authentication);

  Font.register({
    family: "Montserrat",
    fonts: [
      {
        src: "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-Y3tcoqK5.ttf",
      },
      {
        src: "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-Y3tcoqK5.ttf",
        fontWeight: "bold"
      }
    ]
  });

  useEffect(() => {
    if (market_id) {
      dispatch(getFabricatorQuote(market_id));
      dispatch(getCounters(null, market_id));
      dispatch(getAreas(null, market_id));
      dispatch(getCutShapes(null, market_id));
    }
  }, [dispatch, market_id]);

  useEffect(() => {
    if (user?.logo && typeof (user?.logo) === "string") {
      fetch(user?.logo).then(response => {
        return response.blob();
      }).then(blob => {
        const blob_url: string = URL.createObjectURL(blob);
        setLogo(blob_url);
        setLogoRead(true);
      }).catch(() => {
        setLogoRead(true);
      })
    }
    else {
      setLogoRead(true);
    }
  }, [user?.logo]);

  const styles = StyleSheet.create({
    page: {
      paddingLeft: "40px",
      paddingRight: "40px",
      paddingTop: "40px",
      paddingBottom: "40px"
    },
    title: {
      fontWeight: "bold",
      fontSize: 14
    },
    body2: {
      fontSize: 14
    },
    logo: {
      height: "40px",
      width: "80px",
      objectFit: "contain",
      objectPosition: "left"
    },
    row: {
      marginTop: "25px",
      display: "flex",
      flexDirection: "row"
    },
    font: {
      fontFamily: "Montserrat"
    },
    footer_image: {
      position: "absolute",
      right: 10,
      bottom: 5,
      height: "20px",
      width: "20px"
    }
  });

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <LoadingPage loading={!quote?.id || !user?.id || !logo_read || !counters_loaded || !areas_loaded}>
        <Box display="flex" justifyContent="center">
          <PDFViewer width="795px" height="1123px">
            <Document>
              <Page size="A4" style={styles.page}>
                <WOPrintHeader
                  title="WORK ORDER"
                  number={quote?.estimate_number ?? ""}
                  logo_url={logo ? logo : ""}
                  buyer_name={user?.business_name ?? ""}
                  buyer_address={user?.address ?? ""}
                  buyer_city={user?.city ?? ""}
                  buyer_province={user?.province ?? ""}
                  buyer_postal_code={user?.postal_code ?? ""}
                  buyer_phone_number={user?.phone_number ?? ""}
                  buyer_email={user?.email ?? ""}
                  seller_name={quote?.user?.business_name ?? ""}
                  seller_address={quote?.user?.address ?? ""}
                  seller_city={quote?.user?.city ?? ""}
                  seller_province={quote?.user?.province ?? ""}
                  seller_postal_code={quote?.user?.postal_code ?? ""}
                  seller_phone_number={quote?.user?.phone_number ?? ""}
                  seller_email={quote?.user?.email ?? ""}
                  project_address={quote?.fabrication_job_data?.geo_address ?? ""}
                  project_city={""}
                  project_province={""}
                  project_postal_code={""}
                  project_phone_number={quote?.fabrication_job_data?.phone_number ?? ""}
                  project_email={quote?.fabrication_job_data?.email ?? ""}
                  date={quote?.date_created ?? ""} />
                <PrintSummaryHeaders />
                <PrintLineItems areas={quote?.prices ?? []} />
                <PrintSummary
                  total={quote?.total ?? 0}
                  tax_name={quote?.quote_data?.tax_name ?? "Tax"}
                  tax={quote?.tax ?? 0}
                  second_tax_name={quote?.quote_data?.second_tax_name ?? "Tax2"}
                  second_tax={quote?.second_tax ?? 0}
                  discount={0} />
                <QuotePrintAreas counters={counters} areas={areas} labels={[]} />
                <QuotePrintShapeAreas areas={areas} cut_shapes={cut_shapes} />
                <PrintTerms payment_terms={user?.payment_terms ?? ""} title="Fabricator Terms & Conditions" />
                <PrintSignature signature={null} name={null} date={null} />
                <Image src={Logo} style={styles.footer_image} fixed />
              </Page>
            </Document>
          </PDFViewer>
        </Box>
      </LoadingPage>
    </motion.div>
  );
}
