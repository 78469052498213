/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ROUTER ///
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
/// ACTIONS ///
import { getShopQuote } from "api/actions/shop_quote";
import { updateShopQuote } from "api/actions/shop_quote";
import { setBreadcrumbs } from "api/actions/ui/set";
/// TYPES ///
import { IShopQuote } from "api/types/shop_quote/quote";
import { QuoteErrors } from "api/types/quote";
/// COMPONENTS ///
import UpdateQuote from "components/quote/quote_preview/update";
/// BREADCRUMBS ///
import { quote_list } from "values/breadcrumbs/quote";
import { quote_preview } from "values/breadcrumbs/quote";
import { quote_update } from "values/breadcrumbs/quote";

export default function ShopUpdateQuote() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { quote: original_quote } = useAppSelector(state => state.shop_quote);
  const { id } = useParams();
  const [errors, setErrors] = useState<QuoteErrors>({});
  const [quote, setQuote] = useState<IShopQuote>({});

  useEffect(() => {
    if (original_quote) {
      setQuote(original_quote);
    }
  }, [original_quote?.id]);

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { ...quote_list, url: quote_list.url.replace("base_url", "shop") },
      { ...quote_preview, url: quote_preview.url.replace("base_url", "shop").replace("id", id) },
      { ...quote_update, url: quote_update.url.replace("base_url", "shop").replace("id", id) }
    ]));
  }, [dispatch, id]);

  useEffect(() => {
    if (!quote?.id || quote.id.toString() !== id) {
      dispatch(getShopQuote(id));
    }
  }, [id, dispatch, quote]);

  const save = () => {
    if (quote?.name === "") {
      setErrors({ name_error: "name must not be blank", name_has_error: true });
      return;
    }

    dispatch(updateShopQuote({ name: quote.name, estimate_number: quote.estimate_number }, id, false));
    navigate(-1);
  }

  return (
    <UpdateQuote
      base_url="shop"
      name={quote?.name ?? ""}
      setName={name => setQuote({ ...quote, name })}
      estimate_number={quote?.estimate_number ?? ""}
      setEstimateNumber={estimate_number => setQuote({ ...quote, estimate_number })}
      errors={errors}
      save={save} />
  )
}
