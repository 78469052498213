export const shop_addon_url: string = "shop_addon";
export const shop_area_url: string = "shop_area";
export const shop_bump_url: string = "shop_bump";
export const shop_color_option_url: string = "shop_color_option";
export const shop_cooktop_url: string = "shop_cooktop";
export const shop_corner_url: string = "shop_corner";
export const shop_counter_url: string = "shop_counter";
export const shop_cut_bump_url: string = "shop_cut_bump";
export const shop_cut_shape_url: string = "shop_cut_shape";
export const shop_bulk_cut_shape_url: string = "shop_bulk_cut_shape";
export const email_shop_quote_url: string = "email_shop_quote";
export const shop_label_url: string = "shop_label";
export const shop_quote_url: string = "shop_quote";
export const shop_quote_total_url: string = "update_shop_quote_total";
export const shop_quote_line_item_url: string = "shop_quote_line_item";
export const shop_sink_url: string = "shop_sink";
export const shop_slab_url: string = "shop_slab";
export const shop_cut_shape_fitted_url: string = "shop_cut_shape_fitted";
