/// REDUX ///
import { Dispatch } from "redux";
/// ACTION ///
import { Action } from "api/action_types/history";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/history";
/// TYPES ///
import { IHistory } from "api/types/history";

export const createRestorePoint = (history: IHistory) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.CREATE_HISTORY_POINT,
      history
    });
  }
}
