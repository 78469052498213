import { IHistory } from "api/types/history";

export enum ActionType {
  UNDO = "UNDO",
  REDO = "REDO",
  RESTORE = "RESTORE",
  CREATE_HISTORY_POINT = "CREATE_HISTORY_POINT",
  REPLACE_RESTORE_POINT = "REPLACE_RESTORE_POINT",
  CLOSE_ALERT = "CLOSE_ALERT",
  CLEAR_HISTORY = "CLEAR_HISTORY"
}

interface IUndo {
  type: ActionType.UNDO
}

interface IRedo {
  type: ActionType.REDO
}

interface IRestore {
  type: ActionType.RESTORE
}

interface ICreateRestorePoint {
  type: ActionType.CREATE_HISTORY_POINT,
  history: IHistory
}

interface IReplaceRestorePoint {
  type: ActionType.REPLACE_RESTORE_POINT,
  history: IHistory
}

interface ICloseAlert {
  type: ActionType.CLOSE_ALERT
}

interface IClearHistory {
  type: ActionType.CLEAR_HISTORY
}

export type Action =
  IUndo |
  IRedo |
  IRestore |
  ICreateRestorePoint |
  IReplaceRestorePoint |
  ICloseAlert |
  IClearHistory;
