/// HOOKS ///
import { useAppSelector } from "api/hooks/apiHook";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState } from "react";
/// TYPES ///
import { FabricatorQuote } from "api/types/market";
/// MUI COMPONENTS ///
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UserDetails from "./user_details";
// FM ///
import { motion } from "framer-motion";
/// STYLES ///
import { accordionIconStyle } from "styles/style";
import { item_style } from "styles/quote_preview/section";
/// URLS ///
import { detail_url } from "components/navigation/endpoints";
import { market_url } from "components/navigation/endpoints";

interface Props {
  index: number,
  item: FabricatorQuote
}

export default function MarketQuoteItem({ index, item }: Props) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { quote } = useAppSelector((state) => state.quote);
  const [expanded, setExpanded] = useState<boolean>(false);

  const toggleAccordion = () => {
    setExpanded((prev) => !prev);
  }

  return (
    <Grid item xs={12} sx={item_style(true, "auto")}>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.1 * index / 5 } }} style={{ width: "100%" }}>
        <Accordion sx={{ width: "100%" }} elevation={0} expanded={expanded}>
          <AccordionSummary sx={accordionIconStyle} expandIcon={<ExpandMoreIcon onClick={toggleAccordion} />}>
            <Grid container alignItems="center">
              <Grid item xs={3}>
                <img src={typeof (item?.user?.logo) === "string" ? item?.user?.logo : ""} width={100} height={50} style={{ objectFit: "contain" }} alt="Business Logo" />
              </Grid>
              <Grid item xs={3}>
                <Stack>
                  <Typography variant="body2">
                    {item.user?.business_name ?? ""}
                  </Typography>
                  <Typography variant="body2">
                    {item.user?.address ?? ""}
                  </Typography>
                  <Typography variant="body2">
                    {`${item?.user?.city ?? ""} ${item?.user?.province ?? ""} ${item.user?.postal_code ?? ""}`}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2" color={item.all_prices_found ? "default" : "error"} align="right">
                  {
                    item.all_prices_found ?
                      `${(item.total + item.tax).toFixed(2)}` :
                      "Items not in stock"
                  }

                </Typography>
              </Grid>
              <Grid item xs={3} textAlign="right">
                <Button
                  size="small"
                  disabled={quote && (quote.changed || !item.all_prices_found)}
                  onClick={() => navigate(`/${market_url}/${id}/${detail_url}/${item.id}`)}>
                  Check Quote
                </Button>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <UserDetails
              user={item?.user ?? {}} />
          </AccordionDetails>
        </Accordion>
      </motion.div>
    </Grid>
  )
}
