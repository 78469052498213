/// REDUX ///
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
/// ACTION ///
import { Action } from "api/action_types/history";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/history";
/// TYPES ///
import { IHistory } from "api/types/history";
/// ACTIONS ///
import { historyAction } from "../api/history_action";

export const undo = () => {
  return (dispatch: Dispatch<Action>, getState: () => RootState) => {

    const undos: IHistory[] = getState().history.undo_history;
    const last: IHistory = undos[undos.length - 1];

    historyAction(
      last.undo_action,
      last.undo_data,
      last.undo_data2,
      dispatch
    );

    dispatch({
      type: ActionType.UNDO
    });
  }
}
