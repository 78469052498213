/// REACT ///
import { useState } from "react";
/// ROUTER ///
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
/// TYPES ///
import { ICustomer } from "api/types/customer";
/// MUI ///
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import CustomerAutocomplete from "components/universal/autocomplete/customer";
import BaseButton from "components/universal/button/base";
import PageTitle from "components/universal/page/title";
/// FM ///
import { motion } from "framer-motion";
/// ICONS ///
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { item_style } from "styles/quote_preview/section";
import { header_style } from "styles/universal/section_header";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// URLS ///
import { detail_url } from "components/navigation/endpoints";
import { preview_url } from "components/navigation/endpoints";
import { quote_url } from "components/navigation/endpoints";

interface Props {
  base_url: string,
  update: (customer_id: number) => void
}

export default function AssignCustomer({ base_url, update }: Props) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [customer, setCustomer] = useState<ICustomer | null>(null);

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container>
        <PageTitle title="Assign Account" size_lg={6} endProps={
          <Box>
            <BaseButton clickEvent={() => navigate(`/${base_url}/${quote_url}/${detail_url}/${id}/${preview_url}`)} text="Cancel" icon={faTimes} />
            <BaseButton clickEvent={() => update(customer?.id ?? 0)} disabled={!customer} text="Add to Quote" icon={faSave} />
          </Box>
        } />
        <Grid item xs={0} lg={6}></Grid>
        <Grid item xs={12} lg={6}>
          <Box style={header_style}></Box>
        </Grid>
        <Grid item xs={0} lg={6}></Grid>
        <Grid item xs={12} sx={{ paddingTop: "20px" }}>
          <CustomerAutocomplete chooseCustomer={cx => setCustomer(cx)} input_label="Choose Customer" />
        </Grid>
        {
          customer ?
            <Grid container item xs={12} lg={6} sx={{ marginTop: "25px" }}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Selected Account
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box sx={header_style}></Box>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" sx={item_style()}>
                  <b>Name</b>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" align="right" sx={item_style()}>
                  {`${customer?.first_name ?? ""} ${customer?.last_name ?? ""}`}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" sx={item_style()}>
                  <b>Address</b>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" align="right" sx={item_style()}>
                  {customer?.address ?? ""}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" sx={item_style()}>
                  <b>City</b>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" align="right" sx={item_style()}>
                  {customer?.city ?? ""}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" sx={item_style()}>
                  <b>Province</b>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" align="right" sx={item_style()}>
                  {customer?.province ?? ""}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" sx={item_style()}>
                  <b>Postal Code</b>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" align="right" sx={item_style()}>
                  {customer?.postal_code ?? ""}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" sx={item_style()}>
                  <b>Email</b>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" align="right" sx={item_style()}>
                  {customer?.email ?? ""}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" sx={item_style()}>
                </Typography>
                <b>Phone Number</b>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" align="right" sx={item_style()}>
                  {customer?.phone_number ?? ""}
                </Typography>
              </Grid>
            </Grid> :
            null
        }
      </Grid>
    </motion.div>
  )
}
