/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ROUTER ///
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
/// ACTIONS ///
import { getEndCustomer } from "api/actions/end_customer";
import { updateEndCustomer } from "api/actions/end_customer";
import { setBreadcrumbs } from "api/actions/ui/set";
/// TYPES ///
import { ICustomerErrors } from "api/types/customer";
import { ICustomer } from "api/types/customer";
/// REACT  ///
import { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
/// MUI ///
import { Box } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import PageTitle from "components/universal/page/title";
import AddressAutocomplete from "components/universal/autocomplete/geo";
import ProvinceSelector from "components/universal/select_field/province";
import StyledRadio from "components/universal/styled_radio";
/// FM ///
import { motion } from "framer-motion";
/// ICONS ///
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-solid-svg-icons";
/// VALIDATE ///
import { checkSaveCustomer } from "values/check/customer";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// BREADCRUMBS ///
import { cx_list } from "values/breadcrumbs/customer";
import { cx } from "values/breadcrumbs/customer";
import { cx_update } from "values/breadcrumbs/customer";

export default function UpdateCustomer() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { end_customer } = useAppSelector(state => state.end_customer);
  const [errors, setErrors] = useState<ICustomerErrors>({});
  const [customer, setCustomer] = useState<ICustomer>({});

  useEffect(() => {
    setCustomer(end_customer);
  }, [end_customer?.id]);

  useEffect(() => {
    if (!end_customer?.id || end_customer?.id.toString() !== id) {
      dispatch(getEndCustomer(id));
    }
  }, [dispatch, end_customer?.id, id]);

  useEffect(() => {
    if (end_customer?.id) {
      dispatch(setBreadcrumbs([
        cx_list,
        { ...cx, label: `${end_customer?.first_name ?? ""} ${end_customer?.last_name ?? "Account"}`, url: cx.url.replace("id", id) },
        { ...cx_update, url: cx.url.replace("id", id) }
      ]));
    }
  }, [dispatch, end_customer.id, id, end_customer?.first_name, end_customer?.last_name]);

  const saveString = (key: string, value: string) => {
    setCustomer({ ...customer, [key]: value });
    setErrors({ ...errors, [`${key}_error`]: "", [`${key}_has_error`]: false });
  }

  const updateGeo = (label: string, lat: number, lng: number, address: string, city: string, province: string) => {
    setCustomer({
      ...customer,
      geo_address: label,
      lat: lat,
      lng: lng,
      address: customer.address && customer.address !== "" ? customer.address : address,
      city: customer.city && customer.city !== "" ? customer.city : city,
      province: customer.province && customer.province !== "" ? customer.province : province
    });
  }

  const geoError = () => {
    setErrors({ ...errors, geo_error: "Unable to retrieve coordinates of this address.", geo_has_error: true });
  }

  const update = () => {
    const errors: ICustomerErrors = checkSaveCustomer(customer);

    if (errors.email_has_error || errors.first_name_has_error || errors.last_name_has_error || errors.geo_has_error) {
      setErrors(errors);
      return;
    }

    dispatch(updateEndCustomer(customer, id));
    navigate(-1);
  }

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container>
        <PageTitle title="Update Account" size_lg={6} endProps={
          <Box>
            <BaseButton clickEvent={() => navigate(-1)} text="Cancel" icon={faTimes} />
            <BaseButton clickEvent={update} text="Update" icon={faSave} />
          </Box>
        } />
        <Grid item xs={0} lg={6}></Grid>
        <Grid item xs={12}>
          <RadioGroup row value={customer?.is_business} onChange={e => setCustomer({ ...customer, is_business: e.target.value === "true" ? true : false })}>
            <FormControlLabel
              value={"false"}
              control={<StyledRadio />}
              label={
                <Typography variant="body2">
                  Retail
                </Typography>
              } />
            <FormControlLabel
              value={"true"}
              control={<StyledRadio />}
              label={
                <Typography variant="body2">
                  Business
                </Typography>
              } />
          </RadioGroup>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box style={header_style}></Box>
        </Grid>
        <Grid item xs={0} lg={6}></Grid>
        {
          customer ?
            <Fragment>
              <Grid item xs={12} sx={{ paddingTop: "20px" }}>
                <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ gap: "10px" }}>
                  {
                    customer?.is_business ?
                      <TextField
                        value={customer.business_name}
                        onChange={e => saveString("business_name", e.target.value)}
                        error={errors?.business_name_has_error}
                        helperText={errors?.business_name_error ?? ""}
                        label="Business Name" /> :
                      null
                  }
                  <Stack direction="row" spacing={1}>
                    <TextField
                      value={customer?.first_name}
                      onChange={e => saveString("first_name", e.target.value)}
                      error={errors?.first_name_has_error}
                      helperText={errors?.first_name_error ?? ""}
                      label="First Name" />
                    <TextField
                      value={customer?.last_name}
                      onChange={e => saveString("last_name", e.target.value)}
                      error={errors?.last_name_has_error}
                      helperText={errors?.last_name_error ?? ""}
                      label="Last Name" />
                  </Stack>
                  <TextField
                    value={customer?.email}
                    onChange={e => saveString("email", e.target.value)}
                    error={errors?.email_has_error}
                    helperText={errors?.email_error ?? ""}
                    label="Email" />
                  <TextField
                    value={customer?.phone_number}
                    onChange={e => saveString("phone_number", e.target.value)}
                    label="Phone Number" />
                  <AddressAutocomplete
                    input_label="Service Address"
                    label={customer?.geo_address}
                    error={errors?.geo_error ?? ""}
                    has_error={errors?.geo_has_error}
                    geoError={geoError}
                    updateGeo={updateGeo} />
                </Box>
                <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ gap: "10px", marginTop: "30px" }}>
                  <TextField
                    value={customer?.address}
                    onChange={e => saveString("address", e.target.value)}
                    label="Billing Address" />
                  <TextField
                    value={customer?.city}
                    onChange={e => saveString("city", e.target.value)}
                    label="Billing City" />
                  <ProvinceSelector
                    province={customer?.province}
                    setProvince={province => saveString("province", province)} />
                  <TextField
                    value={customer?.postal_code}
                    onChange={e => saveString("postal_code", e.target.value)}
                    label="Billing Postal Code" />
                </Box>
                <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ gap: "10px", marginTop: "30px" }}>
                  <TextField
                    sx={{ width: "540px" }}
                    multiline={process.env.NODE_ENV === "development" ? false : true}
                    minRows={3}
                    maxRows={3}
                    onChange={e => saveString("entry_notes", e.target.value)}
                    value={customer?.entry_notes}
                    label="Notes" />
                </Box>
              </Grid>
            </Fragment> :
            null
        }
      </Grid>
    </motion.div>
  )
}
