/// REACT ///
import { Fragment } from "react";
/// TYPES ///
import { QuoteLineItem } from "api/types/quote";
import { OrderLineItem } from "api/types/order";
import { SelfOrderLineItem } from "api/types/order";
import { IShopQuoteLineItem } from "api/types/shop_quote/quote_line_item";
/// MUI ///
import { Grid } from "@mui/material";
import { IconButton } from "@mui/material";
import { Typography } from "@mui/material";
/// FM ///
import { motion } from "framer-motion";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// ICONS ///
import { faEdit } from "@fortawesome/free-solid-svg-icons";
/// MOTION ///
import { line_item_motion } from "motion/quote";
/// STYLES ///
import { edit_icn_style } from "styles/sketch";
import { edit_icn_btn_style } from "styles/sketch";
import { item_style } from "styles/quote_preview/section";

interface Props {
  item: QuoteLineItem | OrderLineItem | SelfOrderLineItem | IShopQuoteLineItem,
  openEdit: (item: QuoteLineItem | OrderLineItem | SelfOrderLineItem | IShopQuoteLineItem) => void,
  show_prices?: boolean
}

export default function QuoteSummaryRow({ item, openEdit, show_prices = false }: Props) {
  return (
    <Fragment>
      <Grid item xs={6}>
        <div style={item_style()}>
          <motion.div whileHover="show" initial="no_show" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Typography variant="body2">
              {item?.name ?? ""}
            </Typography>
            <motion.div variants={line_item_motion} style={{ display: "flex", alignItems: "center" }}>
              <IconButton onClick={() => openEdit(item)} sx={edit_icn_btn_style}>
                <FontAwesomeIcon icon={faEdit} style={edit_icn_style} />
              </IconButton>
            </motion.div>
          </motion.div>
        </div>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body2" align="right" sx={item_style()}>
          {show_prices ? item?.quantity?.toFixed(2) ?? "" : ""}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2" align="right" sx={item_style()}>
          {show_prices ? `${item?.price?.toFixed(2)}` ?? "" : ""}
        </Typography>
      </Grid>
    </Fragment>
  )
}
