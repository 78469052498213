/// REACT ///
import { SyntheticEvent } from "react";
import { MouseEvent as ReactMouseEvent } from "react";
import { useState } from "react";
import { useEffect } from "react";
/// UUID ///
import { v4 } from "uuid";
/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import useMousePosition from "hooks/mouse_position";
import useDrawShop from "hooks/draw_shop";
/// HANDLERS ///
import { handleCreateShopCounter } from "handler/counter/create";
/// ACTIONS ///
import { createShopLabel } from "api/actions/shop_quote";
import { createShopCooktop } from "api/actions/shop_quote";
import { updateShopCooktop } from "api/actions/shop_quote";
import { deleteShopCooktop } from "api/actions/shop_quote";
import { updateLocalShopCooktop } from "api/actions/shop_quote";
import { createShopSink } from "api/actions/shop_quote";
import { updateShopSink } from "api/actions/shop_quote";
import { deleteShopSink } from "api/actions/shop_quote";
import { createLocalShopSink } from "api/actions/shop_quote";
import { updateLocalShopSink } from "api/actions/shop_quote";
import { deleteLocalShopSink } from "api/actions/shop_quote";
import { deleteShopCounter } from "api/actions/shop_quote";
import { deleteLocalShopCounter } from "api/actions/shop_quote";
import { markShopQuoteChanged } from "api/actions/shop_quote";
import { deleteShopLabel } from "api/actions/shop_quote";
import { updateShopLabel } from "api/actions/shop_quote";
import { updateLocalShopLabel } from "api/actions/shop_quote";
import { createRestorePoint } from "api/actions/history";
/// TYPES ///
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopCooktop } from "api/types/shop_quote/cooktop";
import { IHistoryActionType } from "api/types/history";
import { IShopLabel } from "api/types/shop_quote/label";
import { IShopSlab } from "api/types/shop_quote/slab";
import { IShopSink } from "api/types/shop_quote/sink";
import { HighlightData } from "types";
import { MouseData } from "types/mouse_position";
import { Vector2 } from "api/types/sketch";
/// MUI ///
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
/// COMPONENTS ///
import AppliancesMenu from "components/quote/menu/appliances";
import CooktopCutoutMenu from "components/quote/menu/cooktop_cutout";
import LabelMenu from "components/quote/menu/label";
import SinkCutoutMenu from "components/quote/menu/sink_cutout";
import ShopAreaBar from "../bar/area";
import UpdateShopLabel from "../dialog/label/update";
import CreateShopCooktop from "../drawer/create_cooktop";
import UpdateShopCooktop from "../drawer/update_cooktop";
import CreateShopSink from "../drawer/create_sink";
import UpdateShopSink from "../drawer/update_sink";
import UpdateShopOutlets from "../drawer/update_outlet";
/// STYLES ///
import { sketch_style } from "styles/sketch";
import { canvasStyle } from "styles/sketch";
/// FUNCTIONS ///
import { getMouseAtZoom } from "functions/sketch";
import { duplicateShopCounter } from "functions/sketch/duplicate/counter";
import { getFirstShopSlabInAreas } from "functions/sketch/get/shop_slab";
import { moveShopSink } from "functions/sketch/move/sink";
import { moveShopCooktop } from "functions/sketch/move/cooktop";
import { checkShopCounterClicked } from "functions/sketch/check/counter";
import { checkShopCooktopClicked } from "functions/sketch/check/cooktop";
import { checkIndicatorSelected } from "functions/sketch/check/indicator";
import { checkShopSinkClicked } from "functions/sketch/check/sink";
import { moveVector } from "functions/sketch/move/vector";
/// EMPTY VALUES ///
import { empty_sink } from "values/empty/quote/sink";
import { empty_cooktop } from "values/empty/quote/cooktop";
/// VALUES ///
import { getCopyMessage } from "values/text";

interface Props {
  index: number,
  zoom: number,
  current_area: number,
  setCurrentArea: (area: number) => void,
  copying: boolean,
  setCopying: (copying: boolean) => void
}

export default function ShopAppliances({ index, zoom, current_area, setCurrentArea, copying, setCopying }: Props) {
  const dispatch = useAppDispatch();
  const [selected_counter, setSelectedCounter] = useState<IShopCounter | null>(null);
  const mouse_data: MouseData = useMousePosition();
  const ref = mouse_data.ref;
  const [selected_cooktop, setSelectedCooktop] = useState<IShopCooktop | null>(null);
  const [selected_sink, setSelectedSink] = useState<IShopSink | null>(null);
  const { quote } = useAppSelector((state) => state.shop_quote);
  const { areas } = useAppSelector((state) => state.shop_quote);
  const { counters } = useAppSelector((state) => state.shop_quote);
  const { labels } = useAppSelector((state) => state.shop_quote);
  const [selected_label, setSelectedLabel] = useState<IShopLabel | null>(null);
  const [lifting_label, setLiftingLabel] = useState<boolean>(false);
  const [lifting, setLifting] = useState<boolean>(false);
  const [lift_point, setLiftPoint] = useState<Vector2>({ X: 0, Y: 0 });
  const [in_operation, setInOperation] = useState<boolean>(false);
  const [appliance_menu_open, setApplianceMenuOpen] = useState<boolean>(false);
  const [sink_menu_open, setSinkMenuOpen] = useState<boolean>(false);
  const [cooktop_menu_open, setCooktopMenuOpen] = useState<boolean>(false);
  const [label_menu_open, setLabelMenuOpen] = useState<boolean>(false);
  const [edit_label_open, setEditLabelOpen] = useState<boolean>(false);
  const [outlet_cutout_open, setOutletCutoutOpen] = useState<boolean>(false);
  const [add_sink_open, setAddSinkOpen] = useState<boolean>(false);
  const [change_sink_open, setChangeSinkOpen] = useState<boolean>(false);
  const [add_cooktop_open, setAddCooktopOpen] = useState<boolean>(false);
  const [change_cooktop_open, setChangeCooktopOpen] = useState<boolean>(false);
  const [menu_location, setMenuLocation] = useState<Vector2>({ X: 0, Y: 0 });
  const [copy, setCopy] = useState<HighlightData | null>(null);

  const highlight_data: HighlightData = useDrawShop(
    index,
    selected_counter,
    areas[current_area]?.uuid,
    mouse_data,
    add_sink_open || change_sink_open || add_cooktop_open || change_cooktop_open || appliance_menu_open || sink_menu_open || cooktop_menu_open,
    false,
    lifting,
    in_operation,
    zoom,
    {
      show_measurements: true,
      show_appliances: true,
      appliances_selectable: !add_sink_open && !change_sink_open && !add_cooktop_open && !change_cooktop_open && !appliance_menu_open && !sink_menu_open && !cooktop_menu_open,
    }
  );

  const handleEvent = (event: SyntheticEvent) => {
    const mouse_event: MouseEvent | null = event.nativeEvent instanceof MouseEvent ? event.nativeEvent as MouseEvent : null;
    const mouse: Vector2 = getMouseAtZoom(mouse_data.position, zoom);
    if ((mouse_event && mouse_event.type === "mousedown" && mouse_event.button === 0)) {
      if (copying && !copy && highlight_data) {
        setCopy(highlight_data);
        setInOperation(false);
        return;
      }
      else if (copying && copy) {
        if (highlight_data?.cooktop && copy?.cooktop) {
          dispatch(updateShopCooktop({
            ...highlight_data.cooktop,
            num_burners: copy.cooktop.num_burners,
            length: copy.cooktop.length,
            width: copy.cooktop.width,
            angle: copy.cooktop.angle
          }));
        }
        else if (highlight_data?.sink && copy?.sink) {
          dispatch(updateShopSink({
            ...highlight_data.sink,
            sink_type: copy.sink.sink_type,
            shape: copy.sink.shape,
            faucet_hole_count: copy.sink.faucet_hole_count,
            length: copy.sink.length,
            width: copy.sink.width,
            angle: copy.sink.angle
          }));
        }
        setInOperation(false);
        return;
      }

      const no_menus_open: boolean = !add_sink_open && !change_cooktop_open && !add_cooktop_open && !change_sink_open && !appliance_menu_open &&
        !cooktop_menu_open && !sink_menu_open;
      if (no_menus_open) {
        for (let i = 0; i < labels.length; i++) {
          if (checkIndicatorSelected(labels[i].location, mouse, true, 55)) {
            setSelectedLabel(labels[i]);
            setLiftingLabel(true);
            return;
          }
        }
      }
      const target: HTMLDivElement = event.target as HTMLDivElement;
      if (appliance_menu_open) {
        if (!target.id.startsWith("menu")) {
          setApplianceMenuOpen(false);
        }
      }
      else if (cooktop_menu_open) {
        if (!target.id.startsWith("menu")) {
          setCooktopMenuOpen(false);
        }
      }
      else if (sink_menu_open) {
        if (!target.id.startsWith("menu")) {
          setSinkMenuOpen(false);
        }
      }
      else if (highlight_data && highlight_data.cooktop) {
        setLifting(true);
        setLiftPoint(mouse);
        setSelectedCooktop(highlight_data.cooktop);
        return;
      }
      else if (highlight_data && highlight_data.sink) {
        setLifting(true);
        setLiftPoint(mouse);
        setSelectedSink(highlight_data.sink);
        return;
      }
    }
    else if ((mouse_event && mouse_event.type === "mouseup" && mouse_event.button === 0)) {
      if (lifting) {
        setLifting(false);
        if (selected_cooktop) {
          const updated_cooktop: IShopCooktop = { ...selected_cooktop };
          const cooktop: IShopCooktop = moveShopCooktop(updated_cooktop, getMouseAtZoom(lift_point, zoom), mouse);
          dispatch(updateShopCooktop(cooktop, updated_cooktop.id));

          dispatch(createRestorePoint(
            {
              undo_action: IHistoryActionType.UPDATE_SHOP_COOKTOP,
              redo_action: IHistoryActionType.UPDATE_SHOP_COOKTOP,
              desc: "Move Cooktop",
              undo_data: updated_cooktop,
              redo_data: cooktop
            }
          ));
        }
        else if (selected_sink) {
          const updated_sink: IShopSink = { ...selected_sink };
          const sink: IShopSink = moveShopSink(updated_sink, getMouseAtZoom(lift_point, zoom), mouse);
          dispatch(updateShopSink(sink, updated_sink.id));

          dispatch(createRestorePoint(
            {
              undo_action: IHistoryActionType.UPDATE_SHOP_SINK,
              redo_action: IHistoryActionType.UPDATE_SHOP_SINK,
              desc: "Move Sink",
              undo_data: updated_sink,
              redo_data: sink
            }
          ));
        }
        setSelectedCooktop(null);
        setSelectedSink(null);
      }
      else if (lifting_label && selected_label) {
        const label: IShopLabel = { ...selected_label };
        const vector: Vector2 = moveVector(selected_label.location, mouse);
        dispatch(updateShopLabel({ ...label, location: vector }, label.id));
        dispatch(updateLocalShopLabel({ ...label, location: vector }));
        setSelectedLabel(null);
        setLiftingLabel(false);
      }
    }
    else if ((mouse_event && mouse_event.type === "mousedown" && mouse_event.button === 2)) {
      if (copying) {
        setCopying(false);
        setCopy(null);
        setInOperation(false);
        return;
      }
      for (let i = 0; i < labels.length; i++) {
        if (checkIndicatorSelected(labels[i].location, getMouseAtZoom(mouse, zoom), true)) {
          setSelectedLabel(labels[i]);
          setMenuLocation({
            X: mouse_event.pageX,
            Y: mouse_event.pageY
          });
          setLabelMenuOpen(true);
          return;
        }
      }

      const cooktop_clicked: IShopCooktop | null = checkShopCooktopClicked(counters.filter(c => c.area_uuid === areas[current_area].uuid), mouse_data.position, zoom)
      if (cooktop_clicked) {
        setSelectedCooktop(cooktop_clicked);
        setCooktopMenuOpen(true);
        setSinkMenuOpen(false);
        setApplianceMenuOpen(false);
        setMenuLocation({
          X: mouse_event.pageX,
          Y: mouse_event.pageY
        });
        return;
      }
      const sink_clicked: IShopSink | null = checkShopSinkClicked(counters.filter(c => c.area_uuid === areas[current_area].uuid), mouse_data.position, zoom);
      if (sink_clicked) {
        setSelectedSink(sink_clicked);
        setSinkMenuOpen(true);
        setCooktopMenuOpen(false);
        setApplianceMenuOpen(false);
        setMenuLocation({
          X: mouse_event.pageX,
          Y: mouse_event.pageY
        });
        return;
      }

      const counter: IShopCounter | null = checkShopCounterClicked(counters.filter(c => c.area_uuid === areas[current_area].uuid), mouse);
      if (counter) {
        setSelectedCounter(counter);
        setMenuLocation({
          X: mouse_event.pageX,
          Y: mouse_event.pageY
        });
        setApplianceMenuOpen(true);
        setSinkMenuOpen(false);
        setCooktopMenuOpen(false);
        return;
      }
    }
  }

  useEffect(() => {
    const mouse: Vector2 = mouse_data.position
    if (lifting) {
      if (selected_cooktop) {
        const cooktop: IShopCooktop = moveShopCooktop(selected_cooktop, getMouseAtZoom(lift_point, zoom), getMouseAtZoom(mouse, zoom));
        if (cooktop) {
          dispatch(updateLocalShopCooktop(cooktop));
        }
      }
      else if (selected_sink) {
        const sink: IShopSink = moveShopSink(selected_sink, getMouseAtZoom(lift_point, zoom), getMouseAtZoom(mouse, zoom));
        if (sink) {
          dispatch(updateLocalShopSink(sink));
        }
      }
    }
    else if (selected_label && lifting_label) {
      const vector: Vector2 = moveVector(selected_label.location, getMouseAtZoom(mouse_data.position, zoom));
      dispatch(updateLocalShopLabel({ ...selected_label, location: vector }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mouse_data.position]);

  const deleteSelectedCounter = () => {
    if (selected_counter) {
      dispatch(deleteLocalShopCounter(selected_counter.uuid));
      dispatch(deleteShopCounter(selected_counter.uuid, selected_counter.id));
      dispatch(markShopQuoteChanged());
    }
    setApplianceMenuOpen(false);
  }

  const handleDuplicateCounter = () => {
    setInOperation(true);
    if (selected_counter) {
      const counter: IShopCounter = duplicateShopCounter(selected_counter);
      const slab: IShopSlab | null = getFirstShopSlabInAreas(areas, counter.area_uuid);
      handleCreateShopCounter(
        dispatch,
        quote.id,
        counter,
        counter.area_uuid,
        slab.uuid
      );
    }
    setInOperation(false);
    setApplianceMenuOpen(false);
  }

  const handleSinkCutout = () => {
    setMenuLocation(mouse_data.position);
    setSelectedSink({ ...empty_sink, location: menu_location });
    setAddSinkOpen(true);
    setApplianceMenuOpen(false);
  }

  const handleAddCooktopCutout = () => {
    setMenuLocation(mouse_data.position);
    setSelectedCooktop({ ...empty_cooktop, location: menu_location });
    setAddCooktopOpen(true);
    setApplianceMenuOpen(false);
  }

  const handleAddOutletCutout = () => {
    setOutletCutoutOpen(true);
    setApplianceMenuOpen(false);
  }

  const handleOpenSinkProperties = () => {
    setChangeSinkOpen(true);
    setSinkMenuOpen(false);
  }

  const handleRotateSink = (rotation: number) => {
    if (selected_sink) {
      const sink: IShopSink = { ...selected_sink, angle: (selected_sink.angle + rotation) % 360 };
      setSelectedSink(sink);
      dispatch(updateLocalShopSink(sink));
      dispatch(updateShopSink(sink, selected_sink.id, true));

      dispatch(createRestorePoint(
        {
          undo_action: IHistoryActionType.UPDATE_SHOP_SINK,
          redo_action: IHistoryActionType.UPDATE_SHOP_SINK,
          desc: "Rotate Sink",
          undo_data: { ...selected_sink },
          redo_data: sink
        }
      ));
    }
  }

  const handleDuplicateSink = () => {
    if (selected_sink) {
      const new_sink: IShopSink = {
        ...selected_sink,
        uuid: v4(),
        location: {
          X: selected_sink.location.X - 100 > 100 ? selected_sink.location.X - 100 : selected_sink.location.X + 100,
          Y: selected_sink.location.Y - 100 > 100 ? selected_sink.location.Y - 100 : selected_sink.location.Y + 100
        }
      };
      delete new_sink["id"];
      dispatch(createLocalShopSink(new_sink));
      dispatch(createShopSink(new_sink));
      dispatch(markShopQuoteChanged());

      dispatch(createRestorePoint(
        {
          undo_action: IHistoryActionType.DELETE_SHOP_SINK,
          redo_action: IHistoryActionType.CREATE_SHOP_SINK,
          desc: "Duplicate Sink",
          undo_data: { uuid: new_sink.uuid, counter_uuid: new_sink.counter_uuid },
          redo_data: new_sink,
        }
      ));
    }
    setSinkMenuOpen(false);
  }

  const handleDeleteSink = () => {
    if (selected_sink) {
      const sink: IShopSink = { ...selected_sink };
      dispatch(deleteLocalShopSink(sink));
      dispatch(deleteShopSink(sink, sink.id));
      dispatch(markShopQuoteChanged());

      dispatch(createRestorePoint(
        {
          undo_action: IHistoryActionType.CREATE_SHOP_SINK,
          redo_action: IHistoryActionType.DELETE_SHOP_SINK,
          desc: "Delete Sink",
          undo_data: sink,
          redo_data: { uuid: sink.uuid, counter_uuid: sink.counter_uuid }
        }
      ));
    }
    setSinkMenuOpen(false);
  }

  const handleOpenCooktopProperties = () => {
    setChangeCooktopOpen(true);
    setCooktopMenuOpen(false);
  }

  const handleRotateCooktop = (rotation: number) => {
    if (selected_cooktop) {
      const cooktop: IShopCooktop = { ...selected_cooktop, angle: (selected_cooktop.angle + rotation) % 360 };
      setSelectedCooktop(cooktop);
      dispatch(updateLocalShopCooktop(cooktop));
      dispatch(updateShopCooktop(cooktop, selected_cooktop.id, true));

      dispatch(createRestorePoint(
        {
          undo_action: IHistoryActionType.UPDATE_SHOP_COOKTOP,
          redo_action: IHistoryActionType.UPDATE_SHOP_COOKTOP,
          desc: "Rotate Cooktop",
          undo_data: { ...selected_cooktop },
          redo_data: cooktop
        }
      ));
    }
  }

  const handleDuplicateCooktop = () => {
    if (selected_cooktop) {
      const new_cooktop: IShopCooktop = {
        ...selected_cooktop,
        uuid: v4(),
        location: {
          X: selected_cooktop.location.X - 100 > 100 ? selected_cooktop.location.X - 100 : selected_cooktop.location.X + 100,
          Y: selected_cooktop.location.Y - 100 > 100 ? selected_cooktop.location.Y - 100 : selected_cooktop.location.Y + 100
        }
      };
      delete new_cooktop["id"];

      dispatch(createShopCooktop(new_cooktop));
      dispatch(markShopQuoteChanged());

      dispatch(createRestorePoint(
        {
          undo_action: IHistoryActionType.DELETE_SHOP_COOKTOP,
          redo_action: IHistoryActionType.CREATE_SHOP_COOKTOP,
          desc: "Duplicate Cooktop",
          undo_data: { uuid: new_cooktop.uuid, counter_uuid: new_cooktop.counter_uuid },
          redo_data: new_cooktop
        }
      ));
    }
    setCooktopMenuOpen(false);
  }

  const handleDeleteCooktop = () => {
    if (selected_cooktop) {
      const cooktop: IShopCooktop = { ...selected_cooktop };

      dispatch(deleteShopCooktop(cooktop, cooktop.id));
      dispatch(markShopQuoteChanged());
      dispatch(createRestorePoint(
        {
          undo_action: IHistoryActionType.CREATE_SHOP_COOKTOP,
          redo_action: IHistoryActionType.DELETE_SHOP_COOKTOP,
          desc: "Delete Cooktop",
          undo_data: cooktop,
          redo_data: { uuid: cooktop.uuid, counter_uuid: cooktop.counter_uuid }
        }
      ));
    }
    setCooktopMenuOpen(false);
  }

  const handleOpenEditLabel = () => {
    setEditLabelOpen(true);
    setLabelMenuOpen(false);
  }

  const duplicateLabel = () => {
    if (selected_label) {
      dispatch(createShopLabel({ text: selected_label.text, area: selected_label.area, size: selected_label.size, location: { X: 450, Y: 450 }, quote: quote.id }));
    }
    setLabelMenuOpen(false);
  }

  const handleDeleteLabel = () => {
    const id: number = selected_label.id;
    dispatch(deleteShopLabel(id));
    setLabelMenuOpen(false);
  }

  return (
    <div>
      <ShopAreaBar current_area={current_area} setArea={setCurrentArea} />
      <Box onContextMenu={(e) => { e.preventDefault(); }}
        sx={sketch_style(copy ? "crosshair" : copying ? "grab" : "normal")}
        onMouseDown={(event: ReactMouseEvent) => handleEvent(event)}
        onMouseUp={(event: ReactMouseEvent) => handleEvent(event)}
        ref={ref}>
        <AppliancesMenu
          open={appliance_menu_open}
          menu_location={menu_location}
          deleteCounter={deleteSelectedCounter}
          duplicateCounter={handleDuplicateCounter}
          addSinkCutout={handleSinkCutout}
          addCooktopCutout={handleAddCooktopCutout}
          addOutletCutout={handleAddOutletCutout} />
        <SinkCutoutMenu
          open={sink_menu_open}
          menu_location={menu_location}
          openProperties={handleOpenSinkProperties}
          handleRotateSink={handleRotateSink}
          duplicateSink={handleDuplicateSink}
          deleteSink={handleDeleteSink} />
        <CooktopCutoutMenu
          open={cooktop_menu_open}
          menu_location={menu_location}
          openProperties={handleOpenCooktopProperties}
          rotateCooktop={handleRotateCooktop}
          duplicateCooktop={handleDuplicateCooktop}
          deleteCooktop={handleDeleteCooktop} />
        <LabelMenu
          open={label_menu_open}
          menu_location={menu_location}
          openEditLabel={handleOpenEditLabel}
          duplicateLabel={duplicateLabel}
          deleteLabel={handleDeleteLabel} />
        <canvas id="canvas" className={JSON.stringify(canvasStyle)} />
        <CreateShopSink
          open={add_sink_open}
          setOpen={setAddSinkOpen}
          selected_counter={selected_counter}
          mouse={getMouseAtZoom({ X: menu_location.X - mouse_data.offset.X, Y: menu_location.Y - mouse_data.offset.Y }, zoom)} />
        <UpdateShopSink
          setSelectedCounter={setSelectedCounter}
          selected_sink={selected_sink}
          setSelectedSink={setSelectedSink}
          open={change_sink_open}
          setOpen={setChangeSinkOpen}
          current_sink={empty_sink} />
        <CreateShopCooktop
          open={add_cooktop_open}
          setOpen={setAddCooktopOpen}
          selected_counter={selected_counter}
          mouse={getMouseAtZoom({ X: menu_location.X - mouse_data.offset.X, Y: menu_location.Y - mouse_data.offset.Y }, zoom)} />
        <UpdateShopCooktop
          selected_cooktop={selected_cooktop}
          setSelectedCooktop={setSelectedCooktop}
          open={change_cooktop_open}
          setOpen={setChangeCooktopOpen} />
        <UpdateShopOutlets
          open={outlet_cutout_open}
          setOpen={setOutletCutoutOpen}
          counter={selected_counter} />
        <UpdateShopLabel
          open={edit_label_open}
          setOpen={setEditLabelOpen}
          selected_label={selected_label} />
      </Box>
      {
        copying && copy ?
          <Stack sx={{ position: "absolute", top: mouse_data.position.Y + 30, left: mouse_data.position.X + 20, zIndex: 2000, backgroundColor: "#fff", border: "1px solid #666666", paddingLeft: "10px", paddingRight: "10px", paddingTop: "3px", paddingBottom: "3px" }}>
            <Typography variant="body2">
              {getCopyMessage(copy)}
            </Typography>
            <Typography variant="body2" sx={{ fontSize: 10 }} color="error">
              Right click to cancel
            </Typography>
          </Stack> :
          null
      }
    </div>
  )
}
