/// TYPES ///
import { ICustomer } from "api/types/customer";
import { ICustomerErrors } from "api/types/customer";
/// MUI ///
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel } from "@mui/material";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
/// PAGE COMPONENTS ///
import AddCustomerRadios from "./radio";
import ExistingCustomer from "./forms/existing";
import NewCustomer from "./forms/new";
/// VALUES ///
import { NEW } from "./radio/values";
import { EXISTING } from "./radio/values";
import { NONE } from "./radio/values";

interface Props {
  showing: string,
  setShowing: (showing: string) => void,
  customer: ICustomer,
  setCustomer: (cx: ICustomer) => void,
  cx_errors: ICustomerErrors,
  setCxErrors: (error: ICustomerErrors) => void,
  no_cx: boolean,
  setJobUseCx: (use_cx: boolean) => void
}

export default function CreateQuoteCustomer({
  showing,
  setShowing,
  customer,
  setCustomer,
  cx_errors,
  setCxErrors,
  no_cx,
  setJobUseCx
}: Props) {

  return (
    <Stack>
      <AddCustomerRadios show={setShowing} />
      {
        showing === EXISTING ?
          <ExistingCustomer error={no_cx} setCustomer={setCustomer} /> :
          null
      }
      {
        showing === NEW ?
          <NewCustomer
            customer={customer}
            setCustomer={setCustomer}
            errors={cx_errors}
            setErrors={setCxErrors} /> :
          null
      }
      {
        showing === EXISTING || showing === NEW ?
          <FormControlLabel
            sx={{ paddingLeft: "5px" }}
            value={NONE}
            control={<Checkbox defaultChecked onChange={e => setJobUseCx(e.target.checked)} />}
            label={
              <Typography variant="body2">
                Job Site same as Account
              </Typography>
            } /> :
          null
      }
    </Stack>
  )
}
