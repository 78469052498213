/// TYPES ///
import { IShopCounter } from "api/types/shop_quote/counter";
import { Counter } from "api/types/sketch";
import { IHistoryActionType } from "api/types/history";
import { Vector2 } from "api/types/sketch";
/// ACTIONS ///
import { updateCounter } from "api/actions/sketch/counter";
import { updateLocalCounter } from "api/actions/sketch/counter";
import { createRestorePoint } from "api/actions/history";
import { updateShopCounter } from "api/actions/shop_quote";
import { updateLocalShopCounter } from "api/actions/shop_quote";
/// FUNCTIONS ///
import { getCenterOfCounter } from "functions/sketch";
import { rotateCounter } from "functions/sketch";
import { rotateShopCounter } from "functions/sketch/rotate/counter";

export function handleRotateCounter(
  dispatch: (({ ...any }) => void),
  setSelectedCounter: (counter: Counter | null) => void,
  counter: Counter,
  rotation: number
) {
  const axis: Vector2 = getCenterOfCounter(counter);
  const rotated_counter: Counter = rotateCounter(counter, rotation, axis);
  dispatch(updateLocalCounter(rotated_counter));
  dispatch(updateCounter(rotated_counter, true));
  dispatch(createRestorePoint(
    {
      undo_action: IHistoryActionType.MOVE_COUNTER,
      redo_action: IHistoryActionType.MOVE_COUNTER,
      desc: "Rotate Counter",
      undo_data: counter,
      redo_data: rotated_counter,
    }
  ));
  setSelectedCounter(rotated_counter);
}

export function handleRotateShopCounter(
  dispatch: (({ ...any }) => void),
  setSelectedCounter: (counter: IShopCounter | null) => void,
  counter: IShopCounter,
  rotation: number
) {
  const axis: Vector2 = getCenterOfCounter(counter);
  const rotated_counter: IShopCounter = rotateShopCounter(counter, rotation, axis);
  dispatch(updateLocalShopCounter(rotated_counter));
  dispatch(updateShopCounter(rotated_counter, true));
  dispatch(createRestorePoint(
    {
      undo_action: IHistoryActionType.MOVE_SHOP_COUNTER,
      redo_action: IHistoryActionType.MOVE_SHOP_COUNTER,
      desc: "Rotate Counter",
      undo_data: counter,
      redo_data: rotated_counter
    }
  ));
  setSelectedCounter(rotated_counter);
}
