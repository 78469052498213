/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
/// ACTION ///
import { getFabricationJob } from "api/actions/end_customer";
import { setBreadcrumbs } from "api/actions/ui/set";
/// MUI ///
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import PageTitle from "components/universal/page/title";
/// FM ///
import { motion } from "framer-motion";
/// ICONS ///
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
import { list_container_style } from "styles/universal/page";
import { item_style } from "styles/quote_preview/section";
import { title_style } from "styles/quote_preview/section";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// URLS ///
import { end_customer_url } from "components/navigation/endpoints";
import { job_url } from "components/navigation/endpoints";
import { update_url } from "components/navigation/endpoints";
/// BREADCRUMBS ///
import { cx_list } from "values/breadcrumbs/customer";
import { cx } from "values/breadcrumbs/customer";

export default function Job() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { end_customer: customer } = useAppSelector(state => state.end_customer);
  const { fabrication_job: job } = useAppSelector(state => state.end_customer);

  useEffect(() => {
    dispatch(getFabricationJob(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (customer?.id) {
      dispatch(setBreadcrumbs([cx_list, { ...cx, label: `${customer?.first_name ?? ""} ${customer?.last_name ?? "Account"}`, url: cx.url.replace("id", id) }]));
    }
  }, [dispatch, customer?.id, customer?.first_name, customer?.last_name, id]);

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container alignItems="flex-start" sx={list_container_style}>
        <PageTitle title="Job" size_lg={6} endProps={
          <Box>
            <BaseButton text="Back" icon={faRightToBracket} clickEvent={() => navigate(-1)} />
            <BaseButton text="Edit" icon={faEdit} clickEvent={() => navigate(`/${end_customer_url}/${job_url}/${job?.id}/${update_url}`)} />
          </Box>
        } />
        <Grid item xs={0} lg={6}></Grid>
        <Grid container item xs={12} lg={6}>
          <Grid item xs={12}>
            <Typography variant="h6" sx={title_style}>
              Contact
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box style={header_style}></Box>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={item_style()}>
              <b>Address</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" align="right" sx={item_style()}>
              {job?.geo_address ?? ""}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={item_style()}>
              <b>Email</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" align="right" sx={item_style()}>
              {job?.email ?? ""}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={item_style()}>
              <b>Phone Number</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" align="right" sx={item_style()}>
              {job?.phone_number ?? ""}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={item_style()}>
              <b>Entry Notes</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" align="right" sx={item_style()}>
              {job?.entry_notes ?? ""}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={0} lg={6}></Grid>
      </Grid>
    </motion.div>
  )
}
