/// TYPES ///
import { ICustomer } from "api/types/customer";
import { FilterEndCustomer } from "api/types/customer";
import { IFabricationJob } from "api/types/customer";
/// ACTIONS ///
import { Action } from "api/action_types/end_customer";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/end_customer";

interface State {
  end_customers: ICustomer[],
  end_customer: ICustomer,
  filter: FilterEndCustomer,
  fabrication_jobs: IFabricationJob[],
  fabrication_job: IFabricationJob,
  loading: boolean,
  updating: boolean,
  error: string
}

const initialState = {
  end_customers: [],
  end_customer: {},
  filter: {},
  fabrication_jobs: [],
  fabrication_job: {},
  loading: false,
  updating: false,
  error: ""
}

export const endCustomerReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.GET_FABRICATION_JOBS_SUCCESS:
      return {
        ...state,
        fabrication_jobs: action.fabrication_jobs,
        updating: false,
        error: ""
      }
    case ActionType.GET_FABRICATION_JOB:
      return {
        ...state,
        fabrication_job: action.fabrication_job,
        updating: false,
        error: ""
      }
    case ActionType.GET_END_CUSTOMERS_SUCCESS:
      return {
        ...state,
        end_customers: action.payload,
        loading: false,
        error: ""
      }
    case ActionType.GET_END_CUSTOMER_SUCCESS:
      return {
        ...state,
        end_customer: action.payload,
        loading: false,
        error: ""
      }
    case ActionType.CREATE_END_CUSTOMER_SUCCESS:
      return {
        ...state,
        end_customers: [...state.end_customers, action.payload],
        end_customer: action.payload,
        loading: false,
        error: ""
      }
    case ActionType.UPDATE_END_CUSTOMER_SUCCESS:
      return {
        ...state,
        end_customers: [...state.end_customers.filter(item => item.id !== action.payload.id), action.payload],
        loading: false,
        error: ""
      }
    case ActionType.DELETE_END_CUSTOMER_SUCCESS:
      return {
        ...state,
        end_customers: [...state.end_customers.filter(item => item.id !== action.payload.id)],
        loading: false,
        error: ""
      }
    case ActionType.CREATE_FABRICATION_JOB_SUCCESS:
      return {
        ...state,
        fabrication_job: action.fabrication_job
      }
    case ActionType.SET_END_CUSTOMER:
      return {
        ...state,
        end_customer: action.payload
      }
    case ActionType.SORT_CUSTOMERS:
      return {
        ...state,
        end_customers: action.customers
      }
    case ActionType.SET_CUSTOMER_FILTER:
      return {
        ...state,
        filter: action.filter
      }
    case ActionType.GET_END_CUSTOMERS_PENDING:
    case ActionType.GET_END_CUSTOMER_PENDING:
    case ActionType.CREATE_END_CUSTOMER_PENDING:
    case ActionType.UPDATE_END_CUSTOMER_PENDING:
    case ActionType.DELETE_END_CUSTOMER_PENDING:
      return {
        ...state,
        loading: true
      }
    case ActionType.GET_END_CUSTOMERS_FAIL:
    case ActionType.GET_END_CUSTOMER_FAIL:
    case ActionType.CREATE_END_CUSTOMER_FAIL:
    case ActionType.UPDATE_END_CUSTOMER_FAIL:
    case ActionType.DELETE_END_CUSTOMER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    default:
      return state;
  }
}
