/// ROUTER ///
import { useNavigate } from "react-router-dom";
/// TIME ///
import { DateTime } from "luxon";
/// FM ///
import { motion } from "framer-motion";
/// MUI ///
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
/// TYPES ///
import { Quote } from "api/types/quote";
/// URLS ///
import { detail_url } from "components/navigation/endpoints";
import { preview_url } from "components/navigation/endpoints";
import { quote_url } from "components/navigation/endpoints";
import { store_url } from "components/navigation/endpoints";
/// STYLES ///
import { row_btn_style } from "styles/universal/row";
import { row_text_item_style } from "styles/universal/row";
/// MOTION ///
import { row_text_motion } from "styles/universal/row";

interface Props {
  item: Quote
}

export default function StoreQuotesItem({ item }: Props) {
  const navigate = useNavigate();

  return (
    <Grid item xs={12}>
      <motion.button
        initial="rest"
        whileHover="hover"
        animate="rest"
        variants={row_text_motion}
        onClick={() => navigate(`/${store_url}/${quote_url}/${detail_url}/${item.id}/${preview_url}`)}
        style={row_btn_style}>
        <Grid container>
          <Grid item xs={2.5}>
            <Typography variant="body2" align="left" sx={row_text_item_style}>
              {DateTime.fromISO(item.date_created).toLocal().toFormat("LLL dd h:mm a")}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" align="left" noWrap sx={row_text_item_style}>
              {item.name}
            </Typography>
          </Grid>
          <Grid item xs={3.5}>
            <Typography variant="body2" align="left" noWrap sx={row_text_item_style}>
              {item?.customer_data?.is_business ? item?.customer_data?.business_name : `${item?.customer_data?.first_name ?? ""} ${item?.customer_data?.last_name ?? ""}`}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" align="left" noWrap sx={row_text_item_style}>
              {item.address}
            </Typography>
          </Grid>
        </Grid>
      </motion.button>
    </Grid>
  )
}
