import { primary_color } from "styles/style";
import { secondary_color } from "styles/style";
import { error_color } from "styles/style";
import { grey_color } from "styles/style";
import { light_grey_color } from "styles/style";

export function getCanvas(input: HTMLElement | null): HTMLCanvasElement | null {
  if (input) {
    return input as HTMLCanvasElement;
  }
  return null;
}

export function getContext(canvas: HTMLCanvasElement | null): CanvasRenderingContext2D | null {
  if (canvas) {
    return canvas.getContext("2d");
  }
  return null;
}

export function initializeCanvas(canvas: HTMLCanvasElement, context: CanvasRenderingContext2D, zoom?: number) {
  context.font = "13px Arial";
  context.fillStyle = primary_color;
  context.strokeStyle = primary_color;
  context.textAlign = "center";
  context.lineWidth = 2;
  context.clearRect(0, 0, canvas.width, canvas.height);
}

export function detailCanvas(context: CanvasRenderingContext2D) {
  context.strokeStyle = light_grey_color;
  context.fillStyle = grey_color;
  context.lineWidth = 1;
}

export function standardCounterCanvas(context: CanvasRenderingContext2D, color: string) {
  context.strokeStyle = color;
  context.fillStyle = color;
  context.lineWidth = 2;
}

export function errorCanvas(context: CanvasRenderingContext2D) {
  context.strokeStyle = error_color;
  context.fillStyle = error_color;
}

export function initializeSlabCanvas(canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) {
  context.font = "13px Arial";
  context.strokeStyle = grey_color;
  context.fillStyle = light_grey_color;
  context.textAlign = "center";
  context.lineWidth = 1;
  context.clearRect(0, 0, canvas.width, canvas.height);
}

export function slabCanvas(context: CanvasRenderingContext2D) {
  context.strokeStyle = grey_color;
  context.fillStyle = light_grey_color;
}

export function lineHighlightCanvas(context: CanvasRenderingContext2D) {
  context.strokeStyle = secondary_color;
  context.lineWidth = 8;
  context.fillStyle = secondary_color;
}

export function highlightCanvas(context: CanvasRenderingContext2D) {
  context.strokeStyle = secondary_color;
  context.fillStyle = secondary_color;
  context.lineWidth = 1;
}

export function defaultCanvas(context: CanvasRenderingContext2D) {
  context.strokeStyle = primary_color;
  context.fillStyle = primary_color;
  context.lineWidth = 1;
}

export function cutShapeCanvas(context: CanvasRenderingContext2D) {
  context.strokeStyle = primary_color;
  context.fillStyle = primary_color;
  context.lineWidth = 2;
}
