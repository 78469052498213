/// REACT COMPONENTS ///
import { Fragment } from "react";
/// PDF /// 
import { Image } from "@react-pdf/renderer";
import { StyleSheet } from "@react-pdf/renderer";
import { Text } from "@react-pdf/renderer";
import { View } from "@react-pdf/renderer";
/// FUNCTIONS ///
import { getDate } from "functions/date";
import { getExpiryDate } from "functions/date";
/// COLORS ///
import { primary_color } from "styles/style";
import { light_grey_color } from "styles/style";
import { white_color } from "styles/style";

interface Props {
  title: string,
  number: string,
  logo_url: string,
  vendor_name: string,
  vendor_address: string,
  vendor_city: string,
  vendor_province: string,
  vendor_postal_code: string,
  vendor_phone_number: string,
  vendor_email: string,
  bill_to_name: string,
  bill_to_address: string,
  bill_to_city: string,
  bill_to_province: string,
  bill_to_postal_code: string,
  bill_to_email: string,
  bill_to_phone_number: string,
  job_site_address: string,
  job_site_city: string,
  job_site_province: string,
  job_site_postal_code: string,
  job_site_phone_number: string,
  job_site_email: string,
  date: string
}

export default function POPrintHeader({
  title,
  number,
  logo_url,
  vendor_name,
  vendor_address,
  vendor_city,
  vendor_province,
  vendor_postal_code,
  vendor_email,
  vendor_phone_number,
  bill_to_name,
  bill_to_address,
  bill_to_city,
  bill_to_province,
  bill_to_postal_code,
  bill_to_email,
  bill_to_phone_number,
  job_site_address,
  job_site_city,
  job_site_province,
  job_site_postal_code,
  job_site_phone_number,
  job_site_email,
  date
}: Props) {
  const styles = StyleSheet.create({
    big_title: {
      fontFamily: "Montserrat",
      color: white_color,
      fontSize: 20
    },
    title: {
      fontWeight: "bold",
      fontSize: 10.8,
      fontFamily: "Montserrat"
    },
    body2: {
      fontFamily: "Montserrat",
      fontSize: 10.5
    },
    body2_white: {
      fontFamily: "Montserrat",
      fontSize: 10.5,
      color: white_color
    },
    logo: {
      height: "60px",
      width: "120px",
      objectFit: "contain",
      objectPosition: "left"
    },
    row: {
      marginTop: "5px",
      display: "flex",
      flexDirection: "row"
    }
  });

  return (
    <Fragment>
      <View style={{ display: "flex", flexDirection: "row", alignItems: "center", paddingLeft: "10px" }}>
        <View style={{ width: "50%", display: "flex", justifyContent: "center", alignItems: "flex-start", height: "70px" }}>
          {
            logo_url !== null && logo_url !== undefined && logo_url !== "" ?
              <Image src={logo_url} style={styles.logo} /> :
              null
          }
        </View>
        <View style={{ display: "flex", width: "50%", height: "70px", justifyContent: "center", alignItems: "center", backgroundColor: primary_color }}>
          <Text style={styles.big_title}>
            {title}
          </Text>
          <Text style={styles.body2_white}>
            Invoice #: {number}
          </Text>
        </View>
      </View>
      <View style={{ width: "100%", height: "10px" }}></View>
      <View style={{ display: "flex", flexDirection: "row", paddingLeft: "10px" }}>
        <View style={{ width: "50%", height: "100px", paddingTop: "5px" }}>
          <Text style={styles.title}>
            Vendor
          </Text>
          <Text style={styles.body2}>
            {vendor_name}
          </Text>
          <Text style={styles.body2}>
            {vendor_address}
          </Text>
          <Text style={styles.body2}>
            {vendor_city}, {vendor_province}
          </Text>
          <Text style={styles.body2}>
            {vendor_postal_code}
          </Text>
          <Text style={styles.body2}>
            {vendor_email}
          </Text>
          <Text style={styles.body2}>
            {vendor_phone_number}
          </Text>
        </View>
        <View style={{ textAlign: "right", width: "50%", height: "100px", backgroundColor: light_grey_color, paddingTop: "5px", paddingRight: "10px" }}>
          <Text style={styles.title}>
            Bill To
          </Text>
          <Text style={styles.body2}>
            {bill_to_name}
          </Text>
          <Text style={styles.body2}>
            {bill_to_address}
          </Text>
          <Text style={styles.body2}>
            {`${bill_to_city}, ${bill_to_province}`}
          </Text>
          <Text style={styles.body2}>
            {bill_to_postal_code}
          </Text>
          <Text style={styles.body2}>
            {bill_to_email}
          </Text>
          <Text style={styles.body2}>
            {bill_to_phone_number}
          </Text>
        </View>
      </View>
      <View style={{ display: "flex", flexDirection: "row", paddingLeft: "10px", paddingTop: "20px" }}>
        <View style={{ width: "50%", height: "90px", paddingTop: "5px" }}>
          <Text style={styles.title}>
            Job Site
          </Text>
          <Text style={styles.body2}>
            {job_site_address}
          </Text>
          <Text style={styles.body2}>
            {job_site_city}, {job_site_province}
          </Text>
          <Text style={styles.body2}>
            {job_site_postal_code}
          </Text>
          <Text style={styles.body2}>
            {job_site_phone_number}
          </Text>
          <Text style={styles.body2}>
            {job_site_email}
          </Text>
        </View>
        <View style={{ width: "50%", paddingRight: "15px", textAlign: "right" }}>
          <Text style={styles.body2}>
            Created on: {getDate(date)}
          </Text>
          <Text style={styles.body2}>
            Expires on: {getExpiryDate(date)}
          </Text>
        </View>
      </View>
      <View style={styles.row}>
      </View>
    </Fragment>
  );
}
