
import type { Action as BumpAction } from "./bump";
import type { Action as ClearAction } from "./clear";
import type { Action as CooktopAction } from "./cooktop";
import type { Action as CornerAction } from "./corner";
import type { Action as CounterAction } from "./counter";
import type { Action as CutBumpAction } from "./cut_bump";
import type { Action as CutShapeAction } from "./cut_shape";
import type { Action as ErrorAction } from "./error";
import type { Action as LabelAction } from "./label";
import type { Action as LoadingAction } from "./loading";
import type { Action as SinkAction } from "./sink";

import { ActionType as BumpActionType } from "./bump";
import { ActionType as ClearActionType } from "./clear";
import { ActionType as CooktopActionType } from "./cooktop";
import { ActionType as CornerActionType } from "./corner";
import { ActionType as CounterActionType } from "./counter";
import { ActionType as CutBumpActionType } from "./cut_bump";
import { ActionType as CutShapeActionType } from "./cut_shape";
import { ActionType as ErrorActionType } from "./error";
import { ActionType as LabelActionType } from "./label";
import { ActionType as LoadingActionType } from "./loading";
import { ActionType as SinkActionType } from "./sink";

export const ActionType = {
  ...BumpActionType,
  ...ClearActionType,
  ...CooktopActionType,
  ...CounterActionType,
  ...CornerActionType,
  ...CounterActionType,
  ...CutBumpActionType,
  ...CutShapeActionType,
  ...ErrorActionType,
  ...LabelActionType,
  ...LoadingActionType,
  ...SinkActionType
}

export type Action =
  BumpAction |
  ClearAction |
  CooktopAction |
  CornerAction |
  CounterAction |
  CutBumpAction |
  CutShapeAction |
  ErrorAction |
  LabelAction |
  LoadingAction |
  SinkAction;

