/// HOOKS ///
import { useState } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { createFeedback } from "api/actions/feedback";
/// TYPES ///
import { Feedback } from "api/types/feedback";
/// REACT COMPONENTS ///
import { Fragment } from "react";
/// MUI COMPONENTS ///
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
/// ICONS ///
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CommentIcon from "@mui/icons-material/Comment";
/// VALUES ///
import { BUG } from "values/feedback";
import { FEATURE } from "values/feedback";
import { OTHER } from "values/feedback";
import { getFeedbackTypeDisplay } from "values/feedback";
/// STYLES ///
import { dialog_body_style } from "styles/dialog";

export default function CreateFeedback() {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [create_feedback, setCreateFeedback] = useState<Feedback>({});
  const [error, setError] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);

  const handleFeatureType = (feedback_type: string) => {
    setCreateFeedback({ ...create_feedback, feedback_type: feedback_type });
  }

  const handleDescription = (description: string) => {
    setCreateFeedback({ ...create_feedback, description: description });
    setError(false);
  }

  const handleCreate = () => {
    if (create_feedback.description === "") {
      setError(true);
      return;
    }

    setCompleted(true);
    dispatch(createFeedback(create_feedback));
  }

  const handleClose = () => {
    setOpen(false);
    setCreateFeedback({});
    setError(false);
  }

  return (
    <Fragment>
      <Button endIcon={<CommentIcon />} onClick={() => setOpen(true)} sx={{ marginRight: 4 }} size="small">
        Feedback
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth={"lg"}>
        <DialogTitle>Create Feedback</DialogTitle>
        <DialogContent sx={dialog_body_style}>
          {
            !completed ?
              <Stack sx={{ gap: "10px", marginTop: "10px" }}>
                <TextField
                  error={error}
                  helperText={error ? "Description must not be blank" : ""}
                  select
                  defaultValue={BUG}
                  label="Type"
                  onChange={(e) => handleFeatureType(e.target.value)}>
                  <MenuItem key={BUG} value={BUG}>
                    {getFeedbackTypeDisplay(BUG)}
                  </MenuItem>
                  <MenuItem key={FEATURE} value={FEATURE}>
                    {getFeedbackTypeDisplay(FEATURE)}
                  </MenuItem>
                  <MenuItem key={OTHER} value={OTHER}>
                    {getFeedbackTypeDisplay(OTHER)}
                  </MenuItem>
                </TextField>
                <TextField
                  label="Description"
                  multiline
                  minRows={3}
                  error={error}
                  onChange={(e) => handleDescription(e.target.value)} />
              </Stack> :
              <Stack direction="row" alignItems="center">
                <IconButton color="primary">
                  <CheckCircleIcon />
                </IconButton>
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                  Thank you for the feedback
                </Typography>
              </Stack>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{completed ? "Close" : "Cancel"}</Button>
          <Button onClick={handleCreate}>Create</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
