/// HOOKS ///
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
/// ROUTER ///
import { Location } from "react-router-dom";
/// MUI COMPONENTS ///
import { Divider } from "@mui/material";
import { List } from "@mui/material";
import { ListItemButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// ICONS ///
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { faGrip } from "@fortawesome/free-solid-svg-icons";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import { faStore } from "@fortawesome/free-solid-svg-icons";
import { faUserGroup } from "@fortawesome/free-solid-svg-icons";
/// URLS ///
import { store_url } from "components/navigation/endpoints";
import { end_customer_url } from "components/navigation/endpoints";
import { event_url } from "components/navigation/endpoints";
import { home_url } from "components/navigation/endpoints";
import { order_url } from "components/navigation/endpoints";
import { org_url } from "components/navigation/endpoints";
import { quote_url } from "components/navigation/endpoints";
import { stores_url } from "components/navigation/endpoints";
import { wo_url } from "components/navigation/endpoints";
import { week_url } from "components/navigation/endpoints";
/// STYLES ///
import { mini_btn_style } from "styles/drawer";
import { mini_ico_style } from "styles/drawer";

export default function MiniDealerDrawer() {
  const navigate = useNavigate();
  const location: Location<any> = useLocation();

  return (
    <List>
      <ListItemButton key="Dashboard" sx={mini_btn_style(location.pathname === home_url)} onClick={() => navigate(home_url)}>
        <Tooltip title="Dashboard" placement="right">
          <FontAwesomeIcon icon={faGrip} style={mini_ico_style(location.pathname === home_url)} />
        </Tooltip>
      </ListItemButton>
      <ListItemButton key="Cx" sx={mini_btn_style(location.pathname.includes(end_customer_url))} onClick={() => navigate(`/${end_customer_url}`)}>
        <Tooltip title="Accounts" placement="right">
          <FontAwesomeIcon icon={faUserGroup} style={mini_ico_style(location.pathname.includes(end_customer_url))} />
        </Tooltip>
      </ListItemButton>
      <ListItemButton key="Quotes" sx={mini_btn_style(location.pathname.includes(quote_url))} onClick={() => navigate(`/${store_url}/${quote_url}`)}>
        <Tooltip title="Quotes" placement="right">
          <FontAwesomeIcon icon={faFileInvoiceDollar} style={mini_ico_style(location.pathname.includes(quote_url))} />
        </Tooltip>
      </ListItemButton>
      <ListItemButton key="Orders" sx={mini_btn_style(location.pathname.includes(order_url))} onClick={() => navigate(`${store_url}/${order_url}`)}>
        <Tooltip title="Orders" placement="right">
          <FontAwesomeIcon icon={faCartShopping} style={mini_ico_style(location.pathname.includes(order_url))} />
        </Tooltip>
      </ListItemButton>
      <ListItemButton key="WOs" sx={mini_btn_style(location.pathname.includes(wo_url))} onClick={() => navigate(`${wo_url}`)}>
        <Tooltip title="Work Orders" placement="right">
          <FontAwesomeIcon icon={faClipboardCheck} style={mini_ico_style(location.pathname.includes(wo_url))} />
        </Tooltip>
      </ListItemButton>
      <ListItemButton key="Calendar" sx={mini_btn_style(location.pathname.includes(event_url))} onClick={() => navigate(`/${event_url}/${week_url}`)}>
        <Tooltip title="Calendar" placement="right">
          <FontAwesomeIcon icon={faCalendar} style={mini_ico_style(location.pathname.includes(event_url))} />
        </Tooltip>
      </ListItemButton>
      <Divider sx={{ marginBottom: "2px", marginTop: "2px" }} />
      <ListItemButton key="Org" sx={mini_btn_style(location.pathname.includes(org_url))} onClick={() => navigate(`/${org_url}`)}>
        <Tooltip title="Organization" placement="right">
          <FontAwesomeIcon icon={faBuilding} style={mini_ico_style(location.pathname.includes(org_url))} />
        </Tooltip>
      </ListItemButton>
      <ListItemButton key="Stores" sx={mini_btn_style(location.pathname.includes(stores_url))} onClick={() => navigate(`/${stores_url}`)}>
        <Tooltip title="Stores" placement="right">
          <FontAwesomeIcon icon={faStore} style={mini_ico_style(location.pathname.includes(stores_url))} />
        </Tooltip>
      </ListItemButton>
    </List>
  );
}
