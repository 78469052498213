import { CornerData } from "api/types/sketch";
import { InwardCornerRadius } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { getAngle } from "functions/sketch/math";
import { constructInwardRadiusCorner } from "../../corner/construct";

export function drawCutInsideRadiusCorner(
  path: Path2D,
  i: number,
  previous_location: Vector2,
  current_location: Vector2,
  next_location: Vector2,
  radius: number,
  zoom: number
): CornerData {
  const angle: number = getAngle(previous_location, current_location) + 90;
  const next_angle: number = getAngle(current_location, next_location) + 90;
  const corner_radius: InwardCornerRadius = constructInwardRadiusCorner(
    angle,
    current_location,
    next_angle,
    radius * zoom
  );
  let first_point: Vector2 | null = null;

  if (i === 0) {
    first_point = corner_radius.start_point;
    path.moveTo(corner_radius.start_point.X, corner_radius.start_point.Y);
  }
  else {
    path.lineTo(corner_radius.start_point.X, corner_radius.start_point.Y);
  }

  path.arc(
    corner_radius.center_point.X,
    corner_radius.center_point.Y,
    corner_radius.radius,
    corner_radius.start_angle,
    corner_radius.end_angle,
    true
  );

  return {
    first_point: first_point,
    last_point: corner_radius.end_point
  }
}
