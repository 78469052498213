/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ACTIONS ///
import { updateShopMaterial } from "api/actions/shop_material";
/// TYPES ///
import { IShopMaterial } from "api/types/shop_material";
import { IShopMaterialError } from "api/types/shop_material";
/// COMPONENTS ///
import ShopMaterialDialog from "./material";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  current_material: IShopMaterial
}

export default function ShopUpdateMaterial({ open, setOpen, current_material }: Props) {
  const dispatch = useAppDispatch();
  const [errors, setErrors] = useState<IShopMaterialError>({});
  const [material, setMaterial] = useState<IShopMaterial>({});

  useEffect(() => {
    if (open) {
      setMaterial(current_material);
    }
  }, [open]);

  const save = () => {
    let error: boolean = false;
    let material_errors: IShopMaterialError = {};

    if (!material?.name || material.name === "") {
      material_errors.name_error = "Enter a Material Name";
      material_errors.name_has_error = true;
      error = true;
    }

    if (!material?.material_type || material.material_type === "") {
      material_errors.material_type_error = "Choose a Material Typ";
      material_errors.material_type_has_error = true;
      error = true;
    }

    if (!material?.thickness || material.thickness === "") {
      material_errors.thickness_error = "Choose a Thickness Error";
      material_errors.thickness_has_error = true;
      error = true;
    }

    setErrors(material_errors);

    if (error) {
      return;
    }

    dispatch(updateShopMaterial(material, material.id));
    setOpen(false);
  }

  return (
    <ShopMaterialDialog
      open={open}
      setOpen={setOpen}
      title="Update Material"
      button_text="Update"
      material={material}
      setMaterial={setMaterial}
      errors={errors}
      save={save} />
  )
}
