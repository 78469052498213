/// AXIOS ///
import axios from "axios";
import { AxiosError } from "axios";
/// REDUX ///
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
/// ACTIONS ///
import { Action } from "api/action_types/shop_quote";
import { Action as AuthAction } from "api/action_types/authentication";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/shop_quote";
import { ActionType as AuthActionType } from "api/action_types/authentication";
/// TYPES ///
import { IShopQuote } from "api/types/shop_quote/quote";
/// URLS ///
import { url_prefix } from "settings";
import { shop_quote_url } from "../../endpoints";

export const updateShopQuote = (
  quote: IShopQuote,
  id: number | string,
  quote_requested?: boolean,
  no_update_local?: boolean
) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
    const { user } = getState().authentication;

    dispatch({
      type: ActionType.SHOP_QUOTE_UPDATING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    let url = `${url_prefix}${shop_quote_url}/${id}/?`;

    if (quote_requested) {
      url += "quote_requested=true&";
    }

    try {
      const { data } = await axios.patch(url, quote, config);

      if (!no_update_local) {
        dispatch({
          type: ActionType.UPDATE_SHOP_QUOTE,
          payload: data.quote
        });

        if (quote_requested) {
          dispatch({
            type: ActionType.GET_SHOP_AREAS,
            payload: data.areas
          });
        }
      }
      else {
        dispatch({
          type: ActionType.SHOP_QUOTE_ACTION_SUCCESS
        });
      }
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.SHOP_QUOTE_ERROR,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}
