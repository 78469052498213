/// HOOKS ///
import { useEffect } from "react";
import { useState } from "react";
/// TYPES ///
import { ICustomer } from "api/types/customer";
import { IFabricationJob } from "api/types/customer";
import { IFabricationJobError } from "api/types/customer";
/// MUI ///
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
/// COMPONENTS ///
import AddressAutocomplete from "components/universal/autocomplete/geo";
import BaseButton from "components/universal/button/base";
/// ICONS ///
import { faUserGroup } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { dialog_body_style } from "styles/dialog";
/// VALIDATE ///
import { validateEmail } from "functions/value";

interface Props {
  quote_job: IFabricationJob | null,
  customer: ICustomer,
  open: boolean,
  setOpen: (open: boolean) => void,
  dispatchSave: (job: IFabricationJob) => void
}

export default function QuoteJob({ quote_job, customer, open, setOpen, dispatchSave }: Props) {
  const [job, setJob] = useState<IFabricationJob>({});
  const [errors, setErrors] = useState<IFabricationJobError>({});

  useEffect(() => {
    if (open && quote_job) {
      setJob(quote_job)
    }
  }, [open]);

  const save = () => {
    const errors: IFabricationJobError = {};

    if (!job?.lat || job?.lat === 0 || !job?.lng || job?.lng === 0) {
      errors.geo_error = "Could not find coordinates for this address. Please retype the address and select it from the dropdown options.";
      errors.geo_has_error = true;
    }

    if (!validateEmail(job?.email)) {
      errors.email_error = "Must enter a valid email";
      errors.email_has_error = true;
    }

    setErrors(errors);
    if (errors?.email_has_error || errors?.geo_has_error) {
      return;
    }

    dispatchSave(job);
    close();
  }

  const close = () => {
    setErrors({});
    setOpen(false);
  }


  const setAsCustomer = () => {
    if (customer) {
      setJob({
        end_customer: customer.id ?? 0,
        email: customer.email ?? "",
        phone_number: customer?.phone_number ?? "",
        entry_notes: customer?.entry_notes ?? "",
        lat: customer?.lat ?? 0,
        lng: customer?.lng ?? 0,
        geo_address: customer?.geo_address ?? ""
      });
    }
  }

  const updateGeo = (label: string, lat: number, lng: number, address: string, city: string, province: string) => {
    setJob({
      ...job,
      geo_address: label,
      lat: lat,
      lng: lng,
    });
    setErrors({ ...errors, geo_error: "", geo_has_error: false });
  }

  return (
    <Dialog fullScreen open={open} onClose={close} disableEnforceFocus>
      <DialogTitle>Add Job Site</DialogTitle>
      <DialogContent sx={dialog_body_style}>
        <Stack justifyContent="flex-end" direction="row" spacing={2} sx={{ paddingTop: "10px" }}>
          <BaseButton text="Use Customer" clickEvent={setAsCustomer} icon={faUserGroup} />
        </Stack>
        <Box display="flex" justifyContent="flex-start" flexDirection="column" sx={{ gap: "10px", paddingTop: "10px" }}>
          <TextField
            error={errors.email_has_error}
            helperText={errors.email_error}
            label="Email"
            value={job?.email ?? ""}
            onChange={e => setJob({ ...job, email: e.target.value })} />
          <TextField
            label="Phone Number"
            value={job?.phone_number ?? ""}
            onChange={e => setJob({ ...job, phone_number: e.target.value })} />
          <TextField
            label="Notes"
            value={job?.entry_notes ?? ""}
            onChange={(e) => setJob({ ...job, entry_notes: e.target.value })} />
          <AddressAutocomplete
            input_label="Site Location"
            label={job?.geo_address ?? ""}
            error={errors.geo_error}
            has_error={errors.geo_has_error}
            geoError={() => setErrors({ ...errors, geo_error: "Unable to find geo location", geo_has_error: true })}
            updateGeo={updateGeo} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button onClick={save}>Save</Button>
      </DialogActions>
    </Dialog>
  )
}

