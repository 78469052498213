/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
/// ACTIONS ///
import { getShopOrders } from "api/actions/order/shop_order";
import { setBreadcrumbs } from "api/actions/ui/set";
import { setSOFilter } from "api/actions/order/shop_order";
/// TYPES ///
import { SelfOrder } from "api/types/order";
/// EVENTS ///
import { ChangeEvent } from "react";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import { InputAdornment } from "@mui/material";
import { IconButton } from "@mui/material";
import { Grid } from "@mui/material";
import { Pagination } from "@mui/material";
import { TextField } from "@mui/material";
import { Typography } from "@mui/material";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import OrderHeader from "./header";
import OrderItem from "./row";
import List from "components/universal/list";
import PaginationSelector from "components/universal/ui/pagination_selector";
import PageTitle from "components/universal/page/title";
// FM //
import { motion } from "framer-motion";
// FA COMPONENTS ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// FA ICONS ///
import { faEraser } from "@fortawesome/free-solid-svg-icons";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
import { list_container_style } from "styles/universal/page";
import { row_header_style } from "styles/universal/row";
import { title_style } from "styles/quote_preview/section";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// VALUES ///
import { default_pagination_length } from "styles/table";
/// LODASH ///
import { filter } from "lodash";
/// URLS ///
import { order_url } from "components/navigation/endpoints";
import { shop_url } from "components/navigation/endpoints";
/// VALIDATE ///
import { JSONIsEmpty } from "functions/value";
/// BREADCRUMBS ///
import { order_list } from "values/breadcrumbs/order/shop";

export default function ShopOrderList() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { filter: so_filter } = useAppSelector((state) => state.self_order);
  const { self_orders } = useAppSelector((state) => state.self_order);
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [num_per_page, setNumPerPage] = useState<number>(default_pagination_length);
  const [filt_orders, setFiltOrders] = useState<SelfOrder[]>([]);

  useEffect(() => {
    setFiltOrders(
      filter(self_orders, function (so: SelfOrder) {
        if (
          so?.name?.toLowerCase().includes(search.toLowerCase()) ||
          so?.end_customer_data?.first_name?.toLowerCase().includes(search.toLowerCase()) ||
          so?.end_customer_data?.last_name?.toLowerCase().includes(search.toLowerCase())
        ) {
          return so;
        }
      }) as SelfOrder[]
    )
  }, [search, self_orders]);

  useEffect(() => {
    dispatch(getShopOrders(so_filter));
  }, [dispatch, so_filter]);

  useEffect(() => {
    dispatch(setBreadcrumbs([order_list]));
  }, [dispatch]);

  const changePage = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const searchKeyDown = (e: any) => {
    if (e.key === "Escape") {
      e.preventDefault();
      setSearch("");
    }
  }

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container sx={list_container_style}>
        <PageTitle title="Orders" endProps={
          <Box display="flex" alignItems="center" flexDirection="row">
            {!JSONIsEmpty(so_filter) ? <BaseButton text="Clear" icon={faEraser} clickEvent={() => dispatch(setSOFilter({}))} /> : null}
            <BaseButton text="Filter" icon={faFilter} clickEvent={() => navigate(`/${shop_url}/${order_url}/filter`)} />
          </Box>
        } />
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Box sx={header_style}></Box>
          </Grid>
          <Grid item xs={6} sx={row_header_style}>
            <Box display="flex" justifyContent="flex-start" alignItems="center" sx={{ paddingTop: "5px", paddingBottom: "5px" }}>
              <Typography variant="h6" sx={{ ...title_style, paddingTop: "3px" }}>
                {filt_orders.length} results
              </Typography>
              {
                self_orders.length === 2000 ?
                  <Typography variant="body2" sx={{ marginLeft: "5px" }} color="error">
                    Limit of 2000 reached. Use filter to narrow down results.
                  </Typography> :
                  null
              }
            </Box>
          </Grid>
          <Grid item xs={6} sx={row_header_style}>
            <Box display="flex" justifyContent="flex-end" alignItems="center" sx={{ paddingTop: "5px", paddingBottom: "5px" }}>
              <TextField
                onKeyDown={searchKeyDown}
                placeholder="Quote name or customer name"
                value={search}
                size="small"
                label="Search Within Results"
                sx={{ height: "30px", marginRight: "20px" }}
                InputProps={{
                  style: { height: "30px", },
                  endAdornment: <InputAdornment position="end">
                    {

                      search.length > 0 ?
                        <IconButton onClick={() => setSearch("")}>
                          <FontAwesomeIcon icon={faXmark} size="xs" />
                        </IconButton> :
                        <FontAwesomeIcon icon={faMagnifyingGlass} size="xs" />
                    }
                  </InputAdornment>
                }}
                onChange={e => setSearch(e.target.value)} />
            </Box>
          </Grid>
          <OrderHeader />
          <List
            Item={OrderItem}
            items={filt_orders.slice((page - 1) * num_per_page, page * num_per_page)
            } />
        </Grid >
        <Grid item xs={1}></Grid>
        <Grid item xs={10} sx={{ marginTop: 3 }}>
          <Box display="flex" justifyContent="center">
            <Pagination
              onChange={changePage}
              count={
                filt_orders.length > num_per_page ?
                  Math.ceil(filt_orders.length / num_per_page) :
                  1
              } shape="rounded" />
          </Box>
        </Grid>
        <Grid item xs={1} sx={{ marginTop: 3 }}>
          <PaginationSelector num_per_page={num_per_page} setNumPerPage={(setNumPerPage)} />
        </Grid>
      </Grid>
    </motion.div>
  )
}

