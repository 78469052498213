/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
/// ACTIONS ///
import { clearSelfOrder } from "api/actions/self_order/self_order/local/clear";
import { getShopOrder } from "api/actions/order/shop_order";
import { getSelfOrderAreas } from "api/actions/self_order/area";
import { getSelfOrderCounters } from "api/actions/self_order/counter";
import { getSelfOrderSlabs } from "api/actions/self_order/slab";
import { openSignatureMessage } from "api/actions/signature";
import { setBreadcrumbs } from "api/actions/ui/set";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import LoadingPage from "components/universal/loading_page";
import EmailSignatureRequest from "components/universal/dialog/signature";
import OrderCountersDiagram from "components/universal/diagrams/order_counter/areas";
import OrderSlabsDiagram from "components/universal/diagrams/order_cut_shape/areas";
import OrderSummary from "components/universal/order/summary";
import POHeader from "components/universal/order/po_header";
import SignatureArea from "components/universal/signature";
import TermsConditions from "components/universal/quote/terms";
// FM //
import { motion } from "framer-motion";
/// ICONS ///
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { faSignature } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { list_container_style } from "styles/universal/page";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// URLS ///
import { print_url } from "components/navigation/endpoints";
import { order_url } from "components/navigation/endpoints";
import { shop_url } from "components/navigation/endpoints";
/// BREADCRUMBS ///
import { order_list } from "values/breadcrumbs/order/shop";
import { order } from "values/breadcrumbs/order/shop";

export default function ShopOrder() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { self_order } = useAppSelector((state) => state.self_order);
  const { counters } = useAppSelector((state) => state.self_order);
  const { areas } = useAppSelector((state) => state.self_order);
  const { slabs } = useAppSelector((state) => state.self_order);
  const { areas_loading, counters_loading, slabs_loading } = useAppSelector((state) => state.self_order);
  const { message_open } = useAppSelector((state) => state.signature);
  const [open_signature, setOpenSignature] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setBreadcrumbs([order_list, { ...order, label: self_order?.name ?? "Order", url: order.url.replace("id", id) }]));
  }, [dispatch, self_order?.id, self_order?.name, id]);

  useEffect(() => {
    if (id) {
      dispatch(getShopOrder(id));
      dispatch(getSelfOrderCounters(id));
      dispatch(getSelfOrderAreas(id));
      dispatch(getSelfOrderSlabs(id));
    }
    // return () => {
    //  dispatch(clearSelfOrder());
    //}
  }, [dispatch, id]);

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <LoadingPage loading={areas_loading || counters_loading || slabs_loading}>
        <Grid container sx={list_container_style}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end" sx={{ paddingBottom: "10px" }}>
              <BaseButton text="Signature" icon={faSignature} clickEvent={() => setOpenSignature(true)} />
              <BaseButton text="Print" icon={faPrint} clickEvent={() => window.open(`/${shop_url}/${order_url}/${id}/${print_url}`, '_blank')} />
            </Box>
          </Grid>
          <POHeader
            invoice_number={self_order?.order_number ?? ""}
            seller={self_order?.fabricator_data ?? {}}
            customer={self_order?.end_customer_data ?? {}}
            job={self_order?.job_data ?? {}}
            created={self_order?.created ?? ""} />
          <OrderSummary order={self_order} areas={areas} />
          <TermsConditions payment_terms={self_order?.fabricator_data?.payment_terms ?? ""} />
          <OrderCountersDiagram areas={areas} counters={counters} counters_loaded={!counters_loading && !areas_loading} />
          <OrderSlabsDiagram slabs={slabs} areas={areas} shapes_loaded={!areas_loading && !slabs_loading} />
          <SignatureArea
            signature={self_order ? self_order.signature_image : null}
            name={self_order && self_order.end_customer_data ? `
                    ${self_order.end_customer_data.first_name} ${self_order.end_customer_data.last_name}` : ""}
            date={self_order ? self_order.signature_date : null} />
          <Grid item xs={12}>
            <EmailSignatureRequest
              open={open_signature}
              setOpen={setOpenSignature}
              default_email={self_order && self_order.end_customer_data && self_order.end_customer_data.email ? self_order.end_customer_data.email : ""}
              name={`${self_order &&
                self_order.end_customer_data ? self_order.end_customer_data.first_name : ""} ${self_order &&
                  self_order.end_customer_data ? self_order.end_customer_data.last_name : ""}`}
              customer={self_order && self_order.end_customer ? self_order.end_customer : null}
              self_order_id={self_order ? self_order.id : 0}
              exists={self_order && self_order.signature_image !== null && self_order.signature_image !== undefined} />
          </Grid>
        </Grid>
        <Snackbar
          open={message_open}
          autoHideDuration={5000}
          onClose={() => dispatch(openSignatureMessage(false))}
          message="Signature sent to customer successfully." />
      </LoadingPage>
    </motion.div>
  )
}
