import axios from "axios";
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
import { Action } from "api/action_types/signature";
import { Action as AuthAction } from "api/action_types/authentication";
import { ActionType } from "api/action_types/signature";
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { url_prefix } from "settings";
import { quote_signature_url } from "../endpoints";

export const createQuoteSignatureRequest = (
  email: string,
  quote_id?: number | string,
  order_id?: number | string,
  self_order_id?: number | string
) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.SIGNATURE_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    let url = `${url_prefix}${quote_signature_url}/?email=${email}&`;

    if (quote_id) {
      url += `quote_id=${quote_id}&`;
    }
    else if (order_id) {
      url += `order_id=${order_id}&`;
    }
    else if (self_order_id) {
      url += `self_order_id=${self_order_id}&`;
    }

    try {
      const { data } = await axios.post(url, {}, config);

      dispatch({
        type: ActionType.CREATE_SIGNATURE_SUCCESS,
        signature: data
      });

      dispatch({
        type: ActionType.OPEN_MESSAGE,
        open: true
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.SIGNATURE_ERROR,
          error: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}
