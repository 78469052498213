/// HOOKS ///
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT COMPONENTS ///
import { Fragment } from "react";
/// MUI COMPONENTS ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// FA ICONS ///
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons";
import { faList } from "@fortawesome/free-solid-svg-icons";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import { faSheetPlastic } from "@fortawesome/free-solid-svg-icons";
import { faShop } from "@fortawesome/free-solid-svg-icons";
import { faUserGroup } from "@fortawesome/free-solid-svg-icons";
/// COMPONENTS ///
import DashboardTile from "components/universal/tiles/dashboard";
/// STYLES ///
import { title_style } from "styles/quote_preview/section";
import { tile_ico_style } from "styles/dashboard";
import { header_style } from "styles/universal/section_header";
/// URLS ///
import { end_customer_url } from "components/navigation/endpoints";
import { event_url } from "components/navigation/endpoints";
import { login_url } from "components/navigation/endpoints";
import { material_url } from "components/navigation/endpoints";
import { order_url } from "components/navigation/endpoints";
import { org_url } from "components/navigation/endpoints";
import { price_list_url } from "components/navigation/endpoints";
import { quote_url } from "components/navigation/endpoints";
import { shop_url } from "components/navigation/endpoints";
import { shops_url } from "components/navigation/endpoints";

export default function ShopDashboard() {
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.authentication);

  if (!user.id) {
    navigate(login_url);
  }

  return (
    <Fragment>
      <Grid container item xs={12} md={6}>
        <Grid item xs={12} sx={{ marginTop: "20px" }}>
          <Typography variant="h6" sx={title_style}>
            Manage Services
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={header_style}></Box>
        </Grid>
        <DashboardTile
          title="Quotes"
          index={0}
          url={`${shop_url}/${quote_url}`}
          icon={<FontAwesomeIcon icon={faFileInvoiceDollar} style={tile_ico_style} />} />
        <DashboardTile
          title="Orders"
          index={1}
          url={`${shop_url}/${order_url}`}
          icon={<FontAwesomeIcon icon={faCartShopping} style={tile_ico_style} />} />
        <DashboardTile
          title="Calendar"
          index={2}
          url={`${event_url}/month`}
          icon={<FontAwesomeIcon icon={faCalendar} style={tile_ico_style} />} />
        <DashboardTile
          title="Accounts"
          index={3}
          url={end_customer_url}
          icon={<FontAwesomeIcon icon={faUserGroup} style={tile_ico_style} />} />
      </Grid>
      <Grid item xs={0} md={6}></Grid>
      <Grid container item xs={12} md={6} sx={{ marginTop: "20px" }}>
        <Grid item xs={12}>
          <Typography variant="h6" sx={title_style}>
            Manage Prices
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={header_style}></Box>
        </Grid>
        <DashboardTile
          title="Prices"
          index={4}
          url={`${shop_url}/${price_list_url}`}
          icon={<FontAwesomeIcon icon={faList} style={tile_ico_style} />} />
        <DashboardTile
          title="Materials"
          index={5}
          url={`${shop_url}/${material_url}`}
          icon={<FontAwesomeIcon icon={faSheetPlastic} style={tile_ico_style} />} />
      </Grid>
      <Grid item xs={0} md={6}></Grid>
      <Grid container item xs={12} md={6} sx={{ marginTop: "20px" }}>
        <Grid item xs={12}>
          <Typography variant="h6" sx={title_style}>
            Manage Organization
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={header_style}></Box>
        </Grid>
        <DashboardTile
          title="Organization"
          index={6}
          url={org_url}
          icon={<FontAwesomeIcon icon={faBuilding} style={tile_ico_style} />} />
        <DashboardTile
          title="Shops"
          index={7}
          url={shops_url}
          icon={<FontAwesomeIcon icon={faShop} style={tile_ico_style} />} />
      </Grid>
      <Grid item xs={0} md={6}></Grid>
      <Grid container item xs={12} md={6} sx={{ marginTop: "20px" }}>
        <Grid item xs={12}>
          <Typography variant="h6" sx={title_style}>
            Learn
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={header_style}></Box>
        </Grid>
        <DashboardTile
          title="Latest Updates"
          external={true}
          index={4}
          url="https://learn.arrisstone.com"
          icon={<FontAwesomeIcon icon={faDatabase} style={tile_ico_style} />} />
        <DashboardTile
          title="Training Videos"
          external={true}
          index={5}
          url="https://learn.arrisstone.com/training"
          icon={<FontAwesomeIcon icon={faGraduationCap} style={tile_ico_style} />} />
      </Grid>
      <Grid item xs={0} md={6}></Grid>
    </Fragment>
  )
}
