/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ROUTER ///
import { useLocation } from "react-router-dom";
/// MUI ///
import Box from "@mui/material/Box";
/// FM ///
import { motion } from "framer-motion";
import { useAnimationControls } from "framer-motion";
import { useMotionValueEvent } from "framer-motion";
import { useScroll } from "framer-motion";
import { useSpring } from "framer-motion";
import { Variants } from "framer-motion";
/// STYLES ///
import { scroll_color } from "styles/scroll";

export const scroll_variants: Variants = {
  show: {
    opacity: 1,
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    }
  },
  hide: {
    opacity: 0,
    transition: {
      duration: 0.6,
      type: "tween",
      ease: "easeOut"
    },
  },
}

export default function ScrollBar() {
  const controls = useAnimationControls();
  const location = useLocation();
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  useMotionValueEvent(scrollYProgress, "change", (latest: number) => {
    if (latest === 0 || latest > 0.97) {
      controls.start("hide");
    }
    else {
      controls.start("show");
    }
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [location]);

  return (
    <motion.div
      initial="hide"
      variants={scroll_variants}
      animate={controls}
      style={{
        position: "fixed",
        height: "8px",
        top: 50,
        left: 50,
        right: 0,
        zIndex: 1000,
        scaleX
      }}>
      <Box sx={scroll_color}></Box>
    </motion.div>
  )
}
