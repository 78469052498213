/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
/// ACTIONS ///
import { createEndCustomer } from "api/actions/end_customer";
import { setBreadcrumbs } from "api/actions/ui/set";
/// TYPES ///
import { ICustomer } from "api/types/customer";
import { ICustomerErrors } from "api/types/customer";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import AddressAutocomplete from "components/universal/autocomplete/geo";
import BaseButton from "components/universal/button/base";
import PageTitle from "components/universal/page/title";
import ProvinceSelector from "components/universal/select_field/province";
import StyledRadio from "components/universal/styled_radio";
/// FM ///
import { motion } from "framer-motion";
/// ICONS ///
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// URLS ///
import { end_customer_url } from "components/navigation/endpoints";
/// VALIDATE ///
import { validateEmail } from "functions/value";
/// BREADCRUMBS ///
import { cx_list } from "values/breadcrumbs/customer";
import { cx_create } from "values/breadcrumbs/customer";

const default_url: string = `/${end_customer_url}`;

interface Props {
  return_url?: string
}

export default function CreateCustomer({ return_url = default_url }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [customer, setCustomer] = useState<ICustomer>({ geo_address: "" });
  const [errors, setErrors] = useState<ICustomerErrors>({});

  const saveString = (key: string, value: string) => {
    setCustomer({ ...customer, [key]: value });
    setErrors({ ...errors, [`${key}_error`]: "", [`${key}_has_error`]: false });
  }

  const updateGeo = (label: string, lat: number, lng: number, address: string, city: string, province: string) => {
    setCustomer({
      ...customer,
      geo_address: label,
      lat: lat,
      lng: lng,
      address: customer.address && customer.address !== "" ? customer.address : address,
      city: customer.city && customer.city !== "" ? customer.city : city,
      province: !customer?.province || customer.province === "" ? province : customer.province
    });
    setErrors({ ...errors, geo_error: "", geo_has_error: false });
  }

  const geoError = () => {
    setErrors({ ...errors, geo_error: "Unable to retrieve coordinates of this address.", geo_has_error: true });
  }

  const create = () => {
    let errors: ICustomerErrors = {};

    if (!customer.first_name || customer.first_name === "") {
      errors.first_name_error = "Enter a first name";
      errors.first_name_has_error = true;
    }

    if (!customer.last_name || customer.last_name === "") {
      errors.last_name_error = "Enter a last name";
      errors.last_name_has_error = true;
    }

    if ((!customer.business_name || customer.business_name === "") && customer?.is_business) {
      errors.business_name_error = "Enter a business name";
      errors.business_name_has_error = true;
    }

    if (!customer.email || !validateEmail(customer.email)) {
      errors.email_has_error = true;
      errors.email_error = "Enter a valid email";
    }

    if (isNaN(customer.lat) || isNaN(customer.lng)) {
      errors.geo_has_error = true;
      errors.geo_error = "Unable to retreive coordinates for this address";
    }

    if (errors.email_has_error || errors.last_name_has_error || errors.first_name_has_error || errors.geo_has_error) {
      setErrors(errors);
      return;
    }

    dispatch(createEndCustomer(customer));
    setCustomer({ geo_address: "" });
    navigate(return_url);
  }

  useEffect(() => {
    dispatch(setBreadcrumbs([cx_list, cx_create]));
  }, [dispatch]);

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container>
        <PageTitle title="Create Account" endProps={
          <Box>
            <BaseButton clickEvent={() => navigate(return_url)} text="Cancel" icon={faTimes} />
            <BaseButton clickEvent={create} text="Create" icon={faSave} />
          </Box>
        } />
        <Grid item xs={12}>
          <Box style={header_style}></Box>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: "20px" }}>
          <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ gap: "10px" }}>
            <RadioGroup row value={customer?.is_business} aria-labelledby="customer-radios" name="customer-radios" onChange={e => setCustomer({ ...customer, is_business: e.target.value === "true" ? true : false })}>
              <FormControlLabel
                value={false}
                control={<StyledRadio />}
                label={
                  <Typography variant="body2">
                    Retail
                  </Typography>
                } />
              <FormControlLabel
                value={true}
                control={<StyledRadio />}
                label={
                  <Typography variant="body2">
                    Business
                  </Typography>
                } />
            </RadioGroup>
            {
              customer?.is_business ?
                <TextField
                  onChange={e => saveString("business_name", e.target.value)}
                  error={errors?.business_name_has_error}
                  helperText={errors?.business_name_error ?? ""}
                  label="Business Name" /> :
                null
            }
            <Stack direction="row" spacing={1}>
              <TextField
                onChange={e => saveString("first_name", e.target.value)}
                error={errors?.first_name_has_error}
                helperText={errors?.first_name_error ?? ""}
                label="First Name" />
              <TextField
                onChange={e => saveString("last_name", e.target.value)}
                error={errors?.last_name_has_error}
                helperText={errors?.last_name_error ?? ""}
                label="Last Name"
                size="small" />
            </Stack>
            <TextField
              onChange={e => saveString("email", e.target.value)}
              error={errors?.email_has_error}
              helperText={errors?.email_error ?? ""}
              label="Email"
              size="small" />
            <TextField
              onChange={e => saveString("phone_number", e.target.value)}
              label="Phone Number"
              size="small" />
            <AddressAutocomplete
              input_label="Service Address"
              label={customer.geo_address}
              error={errors?.geo_error ?? ""}
              has_error={errors?.geo_has_error}
              geoError={geoError}
              updateGeo={updateGeo} />
          </Box>
          <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ gap: "10px", marginTop: "30px" }}>
            <TextField
              value={customer.address}
              onChange={e => saveString("address", e.target.value)}
              label="Billing Address"
              size="small" />
            <TextField
              value={customer.city}
              onChange={e => saveString("city", e.target.value)}
              label="Billing City"
              size="small" />
            <ProvinceSelector
              province={customer?.province ?? ""}
              setProvince={province => saveString("province", province)} />
            <TextField
              value={customer.postal_code}
              onChange={e => saveString("postal_code", e.target.value)}
              label="Billing Postal Code"
              size="small" />
          </Box>
          <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ gap: "10px", marginTop: "30px" }}>
            <TextField
              sx={{ width: "540px" }}
              multiline={process.env.NODE_ENV === "development" ? false : true}
              minRows={3}
              maxRows={3}
              onChange={e => saveString("entry_notes", e.target.value)}
              label="Notes"
              size="small" />
          </Box>
        </Grid>
      </Grid>
    </motion.div>
  )
}
