import { ICustomer } from "api/types/customer";
import { IFabricationJob } from "api/types/customer";

export enum ActionType {
  SET_END_CUSTOMER_ON_SHOP_QUOTE = "SET_END_CUSTOMER_ON_QUOTE",
  SET_FABRICATION_JOB_ON_SHOP_QUOTE = "SET_FABRICATION_JOB_ON_QUOTE",
}

interface ISetEndCustomerOnQuote {
  type: ActionType.SET_END_CUSTOMER_ON_SHOP_QUOTE,
  customer: ICustomer
}

interface ISetFabricationJobOnQuote {
  type: ActionType.SET_FABRICATION_JOB_ON_SHOP_QUOTE,
  job: IFabricationJob
}

export type Action = ISetEndCustomerOnQuote | ISetFabricationJobOnQuote;
