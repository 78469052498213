/// REACT-ROUTER ///
import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
/// AUTH ///
import PrivateRoute from "./private_route";
/// COMPONENTS ///
import Dashboard from "components/dashboard";
import ForgotPassword from "components/authentication/forgot_password";
import Login from "components/authentication/login";
import SubscriptionSetup from "components/payment/setup";
import SubscriptionSetupConfirmation from "components/payment/setup/confirmation";
import SubscriptionSetupCancellation from "components/payment/setup/cancellation";
import MarketPrintPreview from "components/market/print";
/// WO ///
import WOs from "components/wo/wos";
import WOFilter from "components/wo/wos/filter";
import WO from "components/wo/wo";
import WOPrint from "components/wo/wo/print";
/// EVENTS ///
import EventsDayPage from "components/events/day";
import EventsMonthPage from "components/events/month";
import CustomEvent from "components/events/event/custom";
import OrderEvent from "components/events/event/order";
import SelfOrderEvent from "components/events/event/self_order";
import CalendarWeek from "components/events/week";
import CreateEvent from "components/events/create";
import EditCustomEvent from "components/events/edit/custom";
import EditOrderEvent from "components/events/edit/order";
import EditSelfOrderEvent from "components/events/edit/self_order";
/// MARKET ///
import Market from "components/market/market";
import MarketQuote from "components/market/market_quote";
/// MATERIALS ///
import MarketMaterials from "components/material/market";
import MarketMaterialType from "components/material/market/type";
import MarketMaterial from "components/material/market/detail";
import ShopMaterialDashboard from "components/material/shop";
import ShopMaterialType from "components/material/shop/type";
import ShopMaterial from "components/material/shop/detail";
import CreateShopColor from "components/material/shop/create_color";
import CreateShopMaterial from "components/material/shop/create_material";
/// CUSTOMERS ///
import Customers from "components/customer/customers";
import Customer from "components/customer/customer";
import CreateCustomer from "components/customer/customers/create";
import DeleteCustomer from "components/customer/customer/delete";
import FilterCustomers from "components/customer/customers/filter";
import Job from "components/customer/job";
import UpdateCustomer from "components/customer/customer/update";
import UpdateJobPage from "components/customer/job/update";
/// MARKET ///
import MarketPl from "components/market_pl/price_list";
import MarketPls from "components/market_pl/price_lists";
import FabricatorMiters from "components/market_pl/price_list/pages/miter";
import FabricatorInstallationCharges from "components/market_pl/price_list/pages/installation";
import FabricatorFabricationCharges from "components/market_pl/price_list/pages/fabrication";
import FabricatorSplashes from "components/market_pl/price_list/pages/splash";
import FabricatorWaterfalls from "components/market_pl/price_list/pages/waterfall";
import FabricatorApplianceEdges from "components/market_pl/price_list/pages/appliance_edge";
import FabricatorFaucetCutouts from "components/market_pl/price_list/pages/faucet_cutout";
import FabricatorOutletCutouts from "components/market_pl/price_list/pages/outlet_cutout";
import FabricatorCooktopCutouts from "components/market_pl/price_list/pages/cooktop_cutout";
import FabricatorSinkCutouts from "components/market_pl/price_list/pages/sink_cutout";
import FabricatorClippedCorners from "components/market_pl/price_list/pages/clipped_corner";
import FabricatorNotchedCorners from "components/market_pl/price_list/pages/notched_corner";
import FabricatorBumpedOutCorners from "components/market_pl/price_list/pages/bumped_out_corner";
import FabricatorInsideDiagonalCornerComponent from "components/market_pl/price_list/pages/inside_diagonal_corner";
import FabricatorInsideRadiusCornerComponent from "components/market_pl/price_list/pages/inside_radius_corner";
import FabricatorRadiusCornerComponent from "components/market_pl/price_list/pages/radius_corner";
import FabricatorRecessedDiagonalCornerComponent from "components/market_pl/price_list/pages/recessed_diagonal_corner";
import FabricatorBumpIns from "components/market_pl/price_list/pages/bump_in";
import FabricatorBumpOuts from "components/market_pl/price_list/pages/bump_out";
import FabricatorFullArcs from "components/market_pl/price_list/pages/full_arc";
import FabricatorCountertopRemoval from "components/market_pl/price_list/pages/removal";
import FabricatorFinishedEdges from "components/market_pl/price_list/pages/finished_edge";
import FabricatorBumpInArcs from "components/market_pl/price_list/pages/bump_in_arc";
import FabricatorBumpOutArcs from "components/market_pl/price_list/pages/bump_out_arc";
/// ORGANIZATION ///
import Organization from "components/organization";
import OrgBilling from "components/organization/billing";
import OrgUpdate from "components/organization/update";
import TermsAndConditions from "components/organization/billing/terms";
import OrgBillingUpdate from "components/organization/billing/update";
/// PROFILE ///
import Profile from "components/profile";
import UpdatePassword from "components/profile/password";
import UpdateProfile from "components/profile/update";
/// SHOP ///
import Shops from "components/shop";
import ShopQuotes from "components/shop_quote/quotes";
import ShopQuote from "components/shop_quote/quote";
import ShopUpdateQuote from "components/shop_quote/quote_preview/update";
import UpdateShopQuoteJob from "components/shop_quote/quote/update_job";
import ShopQuotePreview from "components/shop_quote/quote_preview";
import ShopAssignCustomer from "components/shop_quote/quote_preview/customer/assign/index";
import ShopQuotePrintPreview from "components/shop_quote/print";
import CreateShopQuote from "components/shop_quote/quotes/create";
import FilterShopQuotes from "components/shop_quote/quotes/filter";
import ShopOrderList from "components/shop_order/shop_orders";
import ShopFilterOrders from "components/shop_order/shop_orders/filter";
import ShopOrder from "components/shop_order/shop_order";
import POPrint from "components/shop_order/shop_order/print";
import Shop from "components/shop/shop";
import UpdateShop from "components/shop/update";
import AddShop from "components/shop/add";
import CreateShopQuoteJob from "components/shop_quote/quote_preview/job/create";
/// STORES ///
import Stores from "components/store/stores";
import Store from "components/store/store";
import AddStore from "components/store/stores/add";
import UpdateStore from "components/store/store/update";
import StoreUpdateQuote from "components/store_quote/quote_preview/update";
import UpdateStoreQuoteJob from "components/store_quote/quote/update_job";
import StoreQuotePreview from "components/store_quote/quote_preview";
import StoreQuote from "components/store_quote/quote";
import StoreQuotes from "components/store_quote/quotes";
import StoreAssignCustomer from "components/store_quote/quote_preview/customer/assign";
import PrintStoreQuote from "components/quote/print";
import CreateStoreQuote from "components/store_quote/quotes/create";
import FilterStoreQuotes from "components/store_quote/quotes/filter";
import StoreOrderList from "components/store_order/store_orders";
import StoreFilterOrders from "components/store_order/store_orders/filter";
import StoreOrder from "components/store_order/store_order";
import StorePOPrint from "components/store_order/store_order/print";
import CreateStoreQuoteJob from "components/store_quote/quote_preview/job/create";
/// FM ///
import { AnimatePresence } from "framer-motion";
/// URLS ///
import { dashboard_url } from "./endpoints";
import { end_customer_url } from "./endpoints";
import { event_url } from "./endpoints";
import { forgot_password_url } from "./endpoints";
import { home_url } from "./endpoints";
import { job_url } from "./endpoints";
import { login_url } from "./endpoints";
import { market_url } from "./endpoints";
import { material_url } from "./endpoints";
import { order_url } from "./endpoints";
import { org_url } from "./endpoints";
import { payment_setup_url } from "./endpoints";
import { price_list_url } from "./endpoints";
import { print_url } from "./endpoints";
import { profile_url } from "./endpoints";
import { quote_url } from "./endpoints";
import { shops_url } from "./endpoints";
import { shop_url } from "./endpoints";
import { wo_url } from "./endpoints";
import { store_url } from "./endpoints";
import { stores_url } from "./endpoints";
/// SUB ROUTES ///
import { change_password_url } from "./endpoints";
import { create_url } from "./endpoints";
import { detail_url } from "./endpoints";
import { filter_url } from "./endpoints";
import { update_url } from "./endpoints";
import { preview_url } from "./endpoints";
import { delete_url } from "./endpoints";
import { assign_url } from "./endpoints";
import { terms_url } from "./endpoints";
import { billing_url } from "./endpoints";
import { week_url } from "./endpoints";
import { day_url } from "./endpoints";
import { month_url } from "./endpoints";
/// PRICE LIST ROUTES ///
import { installation_url } from "./endpoints";
import { fabrication_url } from "./endpoints";
import { miter_url } from "./endpoints";
import { splash_url } from "./endpoints";
import { waterfall_url } from "./endpoints";
import { finished_url } from "./endpoints";
import { appliance_url } from "./endpoints";
import { clipped_url } from "./endpoints";
import { notched_url } from "./endpoints";
import { bumped_out_url } from "./endpoints";
import { radius_url } from "./endpoints";
import { inside_radius_url } from "./endpoints";
import { recessed_diagonal_url } from "./endpoints";
import { inside_diagonal_url } from "./endpoints";
import { bump_in_url } from "./endpoints";
import { bump_in_arc_url } from "./endpoints";
import { bump_out_url } from "./endpoints";
import { bump_out_arc_url } from "./endpoints";
import { full_arc_url } from "./endpoints";
import { cooktop_url } from "./endpoints";
import { sink_url } from "./endpoints";
import { faucet_url } from "./endpoints";
import { outlet_url } from "./endpoints";
import { removal_url } from "./endpoints";

export default function MainRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence>
      <Routes location={location} key={location.key}>
        <Route path={login_url}>
          <Route index element={<Login />} />
        </Route>
        <Route path={forgot_password_url} element={<ForgotPassword />} />
        <Route path={home_url} element={<PrivateRoute />}>
          <Route path={dashboard_url} element={<Dashboard />} />
          <Route path={payment_setup_url}>
            <Route index element={<SubscriptionSetup />} />
            <Route path="confirmation" element={<SubscriptionSetupConfirmation />} />
            <Route path="cancellation" element={<SubscriptionSetupCancellation />} />
          </Route>
          <Route path={end_customer_url}>
            <Route index element={<Customers />} />
            <Route path={job_url}>
              <Route path=":id">
                <Route index element={<Job />} />
                <Route path={update_url} element={<UpdateJobPage />} />
              </Route>
            </Route>
            <Route path={create_url} element={<CreateCustomer />} />
            <Route path={filter_url} element={<FilterCustomers />} />
            <Route path={detail_url}>
              <Route path=":id">
                <Route index element={<Customer />} />
                <Route path={delete_url} element={<DeleteCustomer />} />
                <Route path={update_url} element={<UpdateCustomer />} />
              </Route>
            </Route>
          </Route>
          <Route path={market_url}>
            <Route path=":id">
              <Route index element={<Market />} />
              <Route path={detail_url}>
                <Route path=":market_id">
                  <Route index element={<MarketQuote />} />
                  <Route path={print_url} element={<MarketPrintPreview />} />
                </Route>
              </Route>
            </Route>
            <Route path={price_list_url}>
              <Route index element={<MarketPls />} />
              <Route path=":id">
                <Route index element={<MarketPl />} />
                <Route path={installation_url} element={<FabricatorInstallationCharges />} />
                <Route path={fabrication_url} element={<FabricatorFabricationCharges />} />
                <Route path={miter_url} element={<FabricatorMiters />} />
                <Route path={splash_url} element={<FabricatorSplashes />} />
                <Route path={waterfall_url} element={<FabricatorWaterfalls />} />
                <Route path={finished_url} element={<FabricatorFinishedEdges />} />
                <Route path={appliance_url} element={<FabricatorApplianceEdges />} />
                <Route path={faucet_url} element={<FabricatorFaucetCutouts />} />
                <Route path={outlet_url} element={<FabricatorOutletCutouts />} />
                <Route path={cooktop_url} element={<FabricatorCooktopCutouts />} />
                <Route path={sink_url} element={<FabricatorSinkCutouts />} />
                <Route path={clipped_url} element={<FabricatorClippedCorners />} />
                <Route path={notched_url} element={<FabricatorNotchedCorners />} />
                <Route path={bumped_out_url} element={<FabricatorBumpedOutCorners />} />
                <Route path={inside_diagonal_url} element={<FabricatorInsideDiagonalCornerComponent />} />
                <Route path={inside_radius_url} element={<FabricatorInsideRadiusCornerComponent />} />
                <Route path={radius_url} element={<FabricatorRadiusCornerComponent />} />
                <Route path={recessed_diagonal_url} element={<FabricatorRecessedDiagonalCornerComponent />} />
                <Route path={bump_in_url} element={<FabricatorBumpIns />} />
                <Route path={bump_out_url} element={<FabricatorBumpOuts />} />
                <Route path={bump_in_arc_url} element={<FabricatorBumpInArcs />} />
                <Route path={bump_out_arc_url} element={<FabricatorBumpOutArcs />} />
                <Route path={full_arc_url} element={<FabricatorFullArcs />} />
                <Route path={removal_url} element={<FabricatorCountertopRemoval />} />
              </Route>
            </Route>
            <Route path={material_url}>
              <Route index element={<MarketMaterials />} />
              <Route path=":type">
                <Route index element={<MarketMaterialType />} />
                <Route path=":id" element={<MarketMaterial />} />
              </Route>
            </Route>
          </Route>
          <Route path={shops_url}>
            <Route index element={<Shops />} />
            <Route path={detail_url}>
              <Route path=":id">
                <Route index element={<Shop />} />
                <Route path={update_url} element={<UpdateShop />} />
              </Route>
            </Route>
            <Route path={create_url} element={<AddShop />} />
          </Route>
          <Route path={shop_url}>
            <Route path={quote_url}>
              <Route index element={<ShopQuotes />} />
              <Route path={create_url} element={<CreateShopQuote />} />
              <Route path={filter_url} element={<FilterShopQuotes />} />
              <Route path={end_customer_url}>
                <Route path={create_url} element={<CreateCustomer return_url={`/${shop_url}/${quote_url}/${create_url}`} />} />
              </Route>
              <Route path={`${detail_url}/:id`}>
                <Route index element={<ShopQuote />} />
                <Route path={print_url} element={<ShopQuotePrintPreview />} />
                <Route path={update_url} element={<ShopUpdateQuote />} />
                <Route path={assign_url} element={<ShopAssignCustomer base_url={shop_url} />} />
                <Route path={end_customer_url}>
                  <Route path=":customer_id">
                    <Route path={job_url} element={<UpdateShopQuoteJob base_url={shop_url} />} />
                    <Route path={create_url} element={<CreateShopQuoteJob />} />
                  </Route>
                </Route>
                <Route path={preview_url}>
                  <Route index element={<ShopQuotePreview />} />
                </Route>
              </Route>
            </Route>
            <Route path={order_url}>
              <Route index element={<ShopOrderList />} />
              <Route path={filter_url} element={<ShopFilterOrders />} />
              <Route path=":id">
                <Route index element={<ShopOrder />} />
                <Route path={print_url} element={<POPrint />} />
              </Route>
            </Route>
            <Route path={material_url}>
              <Route index element={<ShopMaterialDashboard />} />
              <Route path={create_url} element={<CreateShopMaterial />} />
              <Route path=":id">
              </Route>
              <Route path=":type">
                <Route index element={<ShopMaterialType />} />
                <Route path=":id">
                  <Route index element={<ShopMaterial />} />
                  <Route path={create_url} element={<CreateShopColor />} />
                </Route>
              </Route>
            </Route>
            <Route path={price_list_url}>
              <Route index element={<MarketPls />} />
              <Route path=":id/*" element={<MarketPl />} />
            </Route>
          </Route>
          <Route path={stores_url}>
            <Route index element={<Stores />} />
            <Route path={create_url} element={<AddStore />} />
            <Route path={detail_url}>
              <Route path=":id">
                <Route index element={<Store />} />
                <Route path={update_url} element={<UpdateStore />} />
              </Route>
            </Route>
          </Route>
          <Route path={wo_url}>
            <Route index element={<WOs />} />
            <Route path={filter_url} element={<WOFilter />} />
            <Route path={detail_url}>
              <Route path=":id">
                <Route index element={<WO />} />
                <Route path={print_url} element={<WOPrint />} />
              </Route>
            </Route>
          </Route>
          <Route path={store_url}>
            <Route path={quote_url}>
              <Route index element={<StoreQuotes />} />
              <Route path={create_url} element={<CreateStoreQuote />} />
              <Route path={filter_url} element={<FilterStoreQuotes />} />
              <Route path={end_customer_url}>
                <Route path={create_url} element={<CreateCustomer return_url={`/${store_url}/${quote_url}/${create_url}`} />} />
              </Route>
              <Route path={detail_url}>
                <Route path=":id">
                  <Route index element={<StoreQuote />} />
                  <Route path={update_url} element={<StoreUpdateQuote />} />
                  <Route path={print_url} element={<PrintStoreQuote />} />
                  <Route path={end_customer_url}>
                    <Route path=":customer_id">
                      <Route path={job_url} element={<UpdateStoreQuoteJob base_url={store_url} />} />
                      <Route path={create_url} element={<CreateStoreQuoteJob />} />
                    </Route>
                  </Route>
                  <Route path={assign_url} element={<StoreAssignCustomer base_url={store_url} />} />
                  <Route path={preview_url} element={<StoreQuotePreview />} />
                </Route>
              </Route>
            </Route>
            <Route path={order_url}>
              <Route index element={<StoreOrderList />} />
              <Route path={filter_url} element={<StoreFilterOrders />} />
              <Route path=":id">
                <Route index element={<StoreOrder />} />
                <Route path={print_url} element={<StorePOPrint />} />
              </Route>
            </Route>
          </Route>
          <Route path={profile_url}>
            <Route index element={<Profile />} />
            <Route path={update_url} element={<UpdateProfile />} />
            <Route path={change_password_url} element={<UpdatePassword />} />
          </Route>
          <Route path={org_url}>
            <Route index element={<Organization />} />
            <Route path={update_url} element={<OrgUpdate />} />
            <Route path={billing_url}>
              <Route index element={<OrgBilling />} />
              <Route path={terms_url} element={<TermsAndConditions />} />
              <Route path={update_url} element={<OrgBillingUpdate />} />
            </Route>
          </Route>
          <Route path={event_url}>
            <Route path={month_url} element={<EventsMonthPage />} />
            <Route path={day_url} element={<EventsDayPage />} />
            <Route path={week_url} element={<CalendarWeek />} />
            <Route path={create_url} element={<CreateEvent />} />
            <Route path={update_url}>
              <Route path={shop_url}>
                <Route path=":id" element={<EditSelfOrderEvent />} />
              </Route>
              <Route path={market_url}>
                <Route path=":id" element={<EditOrderEvent />} />
              </Route>
              <Route path={detail_url}>
                <Route path=":id" element={<EditCustomEvent />} />
              </Route>
            </Route>
            <Route path={shop_url}>
              <Route path=":id" element={<SelfOrderEvent />} />
            </Route>
            <Route path={market_url}>
              <Route path=":id" element={<OrderEvent />} />
            </Route>
            <Route path={detail_url}>
              <Route path=":id" element={<CustomEvent />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </AnimatePresence >
  )
}
