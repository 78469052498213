/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
/// ROUTER ///
import { useParams } from "react-router-dom";
/// ACTIONS ///
import { getSelfOrderEvents } from "api/actions/event";
import { getShopOrder } from "api/actions/order/shop_order";
/// FM ///
import { motion } from "framer-motion";
/// MUI ///
import Grid from "@mui/material/Grid";
/// COMPONENTS ///
import CalendarPageBar from "../calendar_bar/page";
import CalendarSidebar from "../side_bar";
import EventOrder from "./order_detail";
import SelfOrderEventDetails from "./details/self_order";
import SelfOrderEventProgress from "./progress/self_order";
import Loading from "components/universal/loading";
import LoadingPage from "components/universal/loading_page";
import PageTitle from "components/universal/page/title";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { page_trans } from "motion/page";
/// STYLES ///
import { calendar_content_style } from "styles/universal/page";
/// VALIDATION ///
import { JSONIsEmpty } from "functions/value";

export default function SelfOrderEvent() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { self_order } = useAppSelector(state => state.self_order);
  const { self_order_event, self_order_events, updating } = useAppSelector(state => state.event);

  useEffect(() => {
    if (id) {
      dispatch(getShopOrder(id));
      dispatch(getSelfOrderEvents({ self_order_id: id }));
    }
  }, [dispatch, id]);

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={calendar_content_style}>
      <div style={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
        <CalendarPageBar />
        <LoadingPage loading={!self_order?.id}>
          <Grid container alignItems="flex-start" spacing={3}>
            <PageTitle title={self_order?.name ?? "Event"} />
            <SelfOrderEventProgress events={self_order_events} selected_event_id={self_order_event?.id} />
            {
              !JSONIsEmpty(self_order_event) ?
                <SelfOrderEventDetails event={self_order_event} /> :
                null
            }
            <EventOrder order={self_order} title="Order" hideWO />
          </Grid>
        </LoadingPage>
      </div>
      <CalendarSidebar />
      <Loading loading={updating} />
    </motion.div>
  )
}
