/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// ACTIONS ///
import { deleteArea } from "api/actions/quotes/area";
import { updateLocalCutShapesByArea } from "api/actions/sketch/cut_shape";
/// TYPES ///
import { Area } from "api/types/quote";
/// COMPONENTS ///
import DeleteArea from "components/quote/dialog/area/delete";

interface Props {
  area: Area,
  open: boolean,
  setOpen: (open: boolean) => void,
  current_area: number,
  setCurrentArea: (area: number) => void
}

export default function DeleteStoreArea({ area, open, setOpen, current_area, setCurrentArea }: Props) {
  const dispatch = useAppDispatch();
  const { areas } = useAppSelector((state) => state.quote);

  const remove = () => {
    setOpen(false);
    if (current_area === (areas?.length ?? - 1) ?? 0) {
      setCurrentArea(0);
    }

    dispatch(updateLocalCutShapesByArea([], area?.uuid));
    dispatch(deleteArea(area));
  }

  return (
    <DeleteArea
      name={area?.name ?? ""}
      open={open}
      setOpen={setOpen}
      remove={remove} />
  )
}
