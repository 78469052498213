/// REACT ///
import { Fragment } from "react";
/// MUI ///
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
/// ICONS ///
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// VALUES ///
import { BUMP_IN } from "values/values";
import { BUMP_OUT } from "values/values";
import { BUMP_IN_ARC } from "values/values";
import { BUMP_OUT_ARC } from "values/values";
import { FULL_ARC } from "values/values";
import { getBump } from "values/values";

interface Props {
  index: number,
  expanded: boolean,
  toggle: (index: number) => void,
  bump_type: string,
  setBumpType: (type: string) => void,
  distance: number,
  setDistance: (distance: number) => void,
  width: number,
  setWidth: (width: number) => void,
  depth: number,
  setDepth: (depth: number) => void,
  left_side_angle: number,
  setLeftSideAngle: (left_side_angle: number) => void,
  right_side_angle: number,
  setRightSideAngle: (right_side_angle: number) => void,
  cancel: () => void,
  close: () => void,
  save: () => void,
  add: () => void,
  remove: (index: number) => void
}

export default function UpdateBumpRow({
  index,
  expanded,
  toggle,
  bump_type,
  setBumpType,
  distance,
  setDistance,
  width,
  setWidth,
  depth,
  setDepth,
  left_side_angle,
  setLeftSideAngle,
  right_side_angle,
  setRightSideAngle,
  remove
}: Props) {
  return (
    <Accordion elevation={0} expanded={expanded} key={index}>
      <AccordionSummary
        onClick={() => toggle(index)}
        sx={{ padding: 0 }}
        expandIcon={null}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            <Typography variant="body1">
              <b>Bump #{index + 1}</b>
            </Typography>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <IconButton onClick={() => toggle(index)}>
                <FontAwesomeIcon icon={expanded ? faChevronDown : faChevronUp} size="2xs" />
              </IconButton>
              <IconButton onClick={() => remove(index)}>
                <FontAwesomeIcon icon={faTrash} size="2xs" />
              </IconButton>
            </div>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <FormControl fullWidth>
            <RadioGroup
              sx={{ paddingLeft: 1, paddingRight: 1 }}
              aria-labelledby="edge-type-selection"
              name="edge-type-selection"
              value={bump_type}
              onChange={(e) => setBumpType(e.target.value)}
            >
              <FormControlLabel
                value={BUMP_IN}
                control={<Radio />}
                label={getBump(BUMP_IN)} />
              <FormControlLabel
                value={BUMP_OUT}
                control={<Radio />}
                label={getBump(BUMP_OUT)} />
              <FormControlLabel
                value={BUMP_IN_ARC}
                control={<Radio />}
                label={getBump(BUMP_IN_ARC)} />
              <FormControlLabel
                value={BUMP_OUT_ARC}
                control={<Radio />}
                label={getBump(BUMP_OUT_ARC)} />
              <FormControlLabel
                value={FULL_ARC}
                control={<Radio />}
                label={getBump(FULL_ARC)} />
            </RadioGroup>
          </FormControl>
          {
            bump_type !== FULL_ARC ?
              <Fragment>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  sx={{ marginTop: 1 }}
                  fullWidth
                  label="Distance (Inches)"
                  size="small"
                  value={distance !== 0 ? distance : ""}
                  onChange={(e) => setDistance(Number(e.target.value))}
                  type="number">
                </TextField>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  sx={{ marginTop: 1 }}
                  fullWidth
                  label="Width (Inches)"
                  value={width > 0 ? width : ""}
                  onChange={(e) => setWidth(Number(e.target.value))}
                  type="number">
                </TextField>
              </Fragment> :
              null
          }
          <TextField
            InputLabelProps={{ shrink: true }}
            sx={{ marginTop: 1 }}
            fullWidth
            label="Depth (Inches)"
            value={depth > 0 ? depth : ""}
            onChange={(e) => setDepth(Number(e.target.value))}
            type="number">
          </TextField>
          {
            bump_type === BUMP_IN || bump_type === BUMP_OUT ?
              <Fragment>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  sx={{ marginTop: 1 }}
                  fullWidth
                  label="Side Angle A"
                  value={left_side_angle}
                  onChange={(e) => setLeftSideAngle(Number(e.target.value))}
                  select>
                  <MenuItem key="0" value="0">
                    0
                  </MenuItem>
                  <MenuItem key="22.5" value="22.5">
                    22.5
                  </MenuItem>
                  <MenuItem key="45" value="45">
                    45
                  </MenuItem>
                  <MenuItem key="67.5" value="67.5">
                    67.5
                  </MenuItem>
                </TextField>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  sx={{ marginTop: 1 }}
                  fullWidth
                  label="Side Angle B"
                  defaultValue={right_side_angle}
                  onChange={e => setRightSideAngle(Number(e.target.value))}
                  select>
                  <MenuItem key="0" value="0">
                    0
                  </MenuItem>
                  <MenuItem key="22.5" value="22.5">
                    22.5
                  </MenuItem>
                  <MenuItem key="45" value="45">
                    45
                  </MenuItem>
                  <MenuItem key="67.5" value="67.5">
                    67.5
                  </MenuItem>
                </TextField>
              </Fragment> :
              null
          }
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

