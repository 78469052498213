/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// ACTIONS ///
import { updateShopQuoteTotal } from "api/actions/shop_quote/quote/api/total";
/// MUI ///
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
/// STYLES ///
import { dialog_body_style } from "styles/dialog";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
}

export default function UpdateQuoteSubtotal({ open, setOpen }: Props) {
  const dispatch = useAppDispatch();
  const { quote } = useAppSelector((state) => state.shop_quote);
  const [subtotal, setSubtotal] = useState<number>(0);

  useEffect(() => {
    if (open) {
      setSubtotal(quote.total);
    }
  }, [open]);

  const handleUpdateSubtotal = () => {
    dispatch(updateShopQuoteTotal({ total: subtotal }, quote.id));
    setOpen(false);
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
      <DialogTitle>Update Quote Subtotal</DialogTitle>
      <DialogContent sx={dialog_body_style}>
        <TextField
          label="Subtotal"
          type="number"
          value={subtotal}
          onChange={e => setSubtotal(Number(e.target.value))}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button onClick={handleUpdateSubtotal}>Set Subtotal</Button>
      </DialogActions>
    </Dialog>
  )
}
