/// HOOKS ///
import { useNavigate } from "react-router-dom";
/// TYPES ///
import { IFabricationJob } from "api/types/customer";
/// MUI ///
import Box from "@mui/material/Grid";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
/// ICONS ///
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { title_style } from "styles/quote_preview/section";
import { btn_box_style } from "styles/quote_preview/section";
import { item_style } from "styles/quote_preview/section";
import { header_style } from "styles/universal/section_header";
/// VALIDATE ///
import { JSONIsEmpty } from "functions/value";

interface Props {
  has_customer: boolean,
  job: IFabricationJob,
  edit_url: string,
  create_url: string
}

export default function JobSite({ has_customer, job, edit_url, create_url }: Props) {
  const navigate = useNavigate();

  if (!has_customer) {
    return (
      <Grid container item xs={12} lg={6} alignItems="center" sx={{ paddingTop: "5px" }}>
        <Grid item xs={6}>
          <Typography variant="h6" sx={title_style}>
            Job Site
          </Typography>
        </Grid>
        <Grid item xs={6} sx={btn_box_style}></Grid>
        <Grid item xs={12}>
          <Box sx={header_style}></Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="error">
            Must choose a customer before entering entering the job site
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container item xs={12} lg={6} alignItems="center" sx={{ paddingTop: "5px" }}>
      <Grid item xs={6}>
        <Typography variant="h6" sx={title_style} >
          Job Site
        </Typography>
      </Grid>
      <Grid item xs={6} sx={btn_box_style}>
        <BaseButton text="Add" hide={!JSONIsEmpty(job)} icon={faAdd} clickEvent={() => navigate(create_url)} />
        <BaseButton text="Edit" hide={JSONIsEmpty(job)} icon={faEdit} clickEvent={() => navigate(edit_url)} />
      </Grid>
      <Grid item xs={12}>
        <Box sx={header_style}></Box>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" sx={item_style()}>
          <b>Site Address</b>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" align="right" sx={item_style()}>
          {job?.geo_address ?? ""}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" sx={item_style()}>
          <b>Geo Location</b>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" align="right" sx={item_style()}>
          {`(${job?.lat ?? 0}, ${job?.lng ?? 0})`}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" sx={item_style()}>
          <b>Email</b>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" align="right" sx={item_style()}>
          {job?.email ?? ""}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" sx={item_style()}>
          <b>Phone Number</b>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" align="right" sx={item_style()}>
          {job?.phone_number ?? ""}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" sx={item_style(false)}>
          <b>Notes</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" sx={item_style(false)}>
          {job?.entry_notes ?? ""}
        </Typography>
      </Grid>
    </Grid>
  )
}
