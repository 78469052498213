/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
/// ROUTER ///
import { useNavigate } from "react-router-dom";
/// ACTIONS ///
import { updateCustomEvent } from "api/actions/event";
/// HOOKS ///
import { useGetDate } from "hooks/date/get";
/// TYPES ///
import { ICustomEvent } from "api/types/event";
/// MUI ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
/// ICONS ///
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { btn_box_style } from "styles/quote_preview/section";
import { item_style } from "styles/quote_preview/section";
import { header_style } from "styles/universal/section_header";
/// URLS ///
import { detail_url } from "components/navigation/endpoints";
import { event_url } from "components/navigation/endpoints";
import { update_url } from "components/navigation/endpoints";

interface Props {
  event: ICustomEvent,
}

export default function CustomEventDetails({ event }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const date = useGetDate(event?.time);

  const completeEvent = (complete: boolean) => {
    dispatch(updateCustomEvent({ completed: complete }, event?.id));
  }

  return (
    <Grid container item xs={12} alignItems="center">
      <Grid item xs={6}>
      </Grid>
      <Grid item xs={6} sx={btn_box_style}>
        <BaseButton clickEvent={() => navigate(`/${event_url}/${update_url}/${detail_url}/${event?.id}`)} text="Edit" icon={faEdit} />
        {
          event?.completed ?
            <BaseButton clickEvent={() => completeEvent(false)} text="Mark Incomplete" error={true} icon={faXmark} /> :
            <BaseButton clickEvent={() => completeEvent(true)} text="Mark Complete" icon={faCheck} />
        }
      </Grid>
      <Grid item xs={12}>
        <Box sx={header_style}></Box>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" sx={item_style()}>
          <b>Date</b>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" align="right" sx={item_style()}>
          {date.toFormat("LLL dd")}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" sx={item_style()}>
          <b>Time</b>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" align="right" sx={item_style()}>
          {date.toFormat("h:mm a")}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" sx={item_style()}>
          <b>Assigned To</b>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" align="right" sx={item_style()}>
          {event?.notify_email ?? ""}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" sx={item_style(false)}>
          <b>Notes</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" sx={{
          whiteSpace: "pre-line", paddingLeft: "6px",
          paddingRight: "6px",
          paddingBottom: "3px",
          paddingTop: "3px",
        }}>
          {event.description ?? ""}
        </Typography>
      </Grid>
    </Grid>

  )
}
