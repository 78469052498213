/// AXIOS ///
import axios from "axios";
import { AxiosError } from "axios";
/// TYPES ///
import { IGetFabricationJobs } from "api/types/customer";
/// REDUX ///
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
/// ACTIONS ///
import { Action } from "api/action_types/end_customer";
import { Action as AuthAction } from "api/action_types/authentication";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/end_customer";
import { ActionType as AuthActionType } from "api/action_types/authentication";
/// FUNCTIONS ///
import { constructGetFabricationJobsUrl } from "./params/filter";
/// URLS ///
import { fabrication_job_url } from "../../endpoint";
import { url_prefix } from "settings";

export const getFabricationJobs = (args: IGetFabricationJobs) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
    const { user } = getState().authentication;

    dispatch({
      type: ActionType.FABRICATION_JOB_UPDATING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    let url = constructGetFabricationJobsUrl(args);

    try {
      const { data } = await axios.get(url, config);

      dispatch({
        type: ActionType.GET_FABRICATION_JOBS_SUCCESS,
        fabrication_jobs: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.FABRICATION_JOB_ERROR,
          error: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}

export const getFabricationJob = (id: string | number) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
    const { user } = getState().authentication;

    dispatch({
      type: ActionType.FABRICATION_JOB_UPDATING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    let url = `${url_prefix}${fabrication_job_url}/${id}`;

    try {
      const { data: fabrication_job } = await axios.get(url, config);

      dispatch({
        type: ActionType.GET_FABRICATION_JOB,
        fabrication_job
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.FABRICATION_JOB_ERROR,
          error: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}
