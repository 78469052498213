/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useState } from "react";
/// ACTIONS ///
import { updateLocalCounter } from "api/actions/sketch/counter";
import { replaceRestorePoint } from "api/actions/history";
import { restore } from "api/actions/history";
/// TYPES ///
import { Counter } from "api/types/sketch";
import { Corner } from "api/types/sketch";
import { IHistoryActionType } from "api/types/history";
import { Slab } from "api/types/quote";
/// MUI ///
import UpdateHeight from "components/quote/drawer/update_height";
/// FUNCTIONS ///
import { createCorners } from "functions/sketch";
import { updateHeight } from "functions/sketch";
import { getFirstSlabInAreas } from "functions/sketch";
import { handleUpdateCounter } from "handler/counter/update";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  original_value: number | null,
  point_uuid: string,
  selected_counter: Counter | null
}

export default function UpdateStoreHeight({ open, setOpen, original_value, point_uuid, selected_counter }: Props) {
  const dispatch = useAppDispatch();
  const { areas } = useAppSelector((state) => state.quote);
  const { cut_shapes } = useAppSelector((state) => state.sketch);
  const [error, setError] = useState<string>("");
  const [height, setHeight] = useState<number>(0);

  const update = (new_height: number) => {
    setHeight(Number(new_height));
    if (isNaN(Number(new_height)) === false && selected_counter && Number(new_height) >= 1 && original_value) {
      const update_counter: Counter = updateHeight(
        selected_counter,
        Number(new_height),
        original_value,
        point_uuid
      );
      const new_corners: Corner[] = createCorners(update_counter.points, [...update_counter.corners], update_counter.uuid);
      dispatch(updateLocalCounter({ ...update_counter, corners: new_corners }));
    }
  }

  const cancel = () => {
    dispatch(restore());
    setOpen(false);
    setError("");
  }

  useEffect(() => {
    if (open && original_value) {
      setHeight(original_value);
      dispatch(replaceRestorePoint(
        {
          undo_action: IHistoryActionType.UPDATE_COUNTER,
          desc: "Cancel Update Height",
          undo_data: { ...selected_counter }
        }
      ));
    }
  }, [open, original_value]);

  const save = () => {
    if (!height || isNaN(Number(height)) || Number(height) < 1) {
      setError('Measurement must be larger than 1"');
      return;
    }
    if (selected_counter && height && original_value) {
      const new_counter: Counter = updateHeight(
        selected_counter,
        Number(height),
        original_value,
        point_uuid
      );
      const new_corners: Corner[] = createCorners(new_counter.points, [...new_counter.corners], new_counter.uuid);
      const counter: Counter = { ...new_counter, corners: new_corners };
      const slab: Slab | null = getFirstSlabInAreas(areas, counter.area_uuid);
      handleUpdateCounter(
        dispatch,
        { ...selected_counter },
        counter,
        cut_shapes.filter(shape => shape.counter_uuid === selected_counter.uuid),
        slab.uuid,
        "Change Counter Height"
      );
    }
    else {
      setError("An error has occurred, please refresh the page and try again.");
    }
    setOpen(false);
    setError("");
  }

  return (
    <UpdateHeight
      open={open}
      setOpen={setOpen}
      height={height}
      update={update}
      save={save}
      cancel={cancel}
      error={error} />
  );
}
