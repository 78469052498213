/// ROUTES ///
export const dashboard_url: string = "/";
export const end_customer_url: string = "account";
export const event_url: string = "events";
export const forgot_password_url: string = "forgot_password";
export const job_url: string = "job";
export const home_url: string = "/";
export const login_url: string = "login";
export const market_url: string = "market";
export const material_url: string = "material";
export const order_url: string = "order";
export const org_url: string = "organization";
export const payment_setup_url: string = "payment_setup";
export const price_list_url: string = "price_list";
export const print_url: string = "print";
export const profile_url: string = "profile";
export const quote_url: string = "quote";
export const quote_preview_url: string = "quote_preview";
export const shop_url: string = "shop";
export const shops_url: string = "shops";
export const store_url: string = "store";
export const stores_url: string = "stores";
export const wo_url: string = "wo";


/// SUB ROUTES ///
export const create_url: string = "create";
export const update_url: string = "update";
export const detail_url: string = "d";
export const filter_url: string = "filter";
export const preview_url: string = "preview";
export const delete_url: string = "delete";
export const assign_url: string = "assign";
export const billing_url: string = "b";
export const terms_url: string = "terms";
export const change_password_url: string = "change_password";
export const week_url: string = "week";
export const day_url: string = "day";
export const month_url: string = "month";

/// PRICE LIST ROUTES ///
export const installation_url: string = "installation";
export const fabrication_url: string = "fabrication";
export const miter_url: string = "miter";
export const splash_url: string = "splash";
export const waterfall_url: string = "waterfall";
export const finished_url: string = "finished";
export const appliance_url: string = "appliance";
export const clipped_url: string = "clipped";
export const notched_url: string = "notched";
export const bumped_out_url: string = "bumped_out";
export const radius_url: string = "radius";
export const inside_radius_url: string = "inside_radius";
export const recessed_diagonal_url: string = "recessed_diag";
export const inside_diagonal_url: string = "inside_diag";
export const bump_in_url: string = "bump_in";
export const bump_in_arc_url: string = "bump_in_arc";
export const bump_out_url: string = "bump_out";
export const bump_out_arc_url: string = "bump_out_arc";
export const full_arc_url: string = "full_arc";
export const cooktop_url: string = "cooktop";
export const sink_url: string = "sink";
export const faucet_url: string = "faucet";
export const outlet_url: string = "outlet";
export const removal_url: string = "removal";
