/// HOOKS ///
import { useAppSelector } from "api/hooks/apiHook";
import { useState } from "react";
/// MUI ///
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
/// COMPONENTS ///
import CreateStoreArea from "../dialog/area/create";
import DeleteStoreArea from "../dialog/area/delete";
/// STYLES ///
import { area_bar_style } from "styles/sketch";
import { area_btn_style } from "styles/sketch";
import { area_add_style } from "styles/sketch";
import { btn_area_style } from "styles/sketch";
/// VALUES ///
import { getCounterColor } from "functions/sketch";

interface Props {
  current_area: number,
  setArea: (value: number) => void,
}

export default function StoreAreaBar({ current_area, setArea }: Props) {
  const { areas } = useAppSelector((state) => state.quote);
  const [create_open, setCreateOpen] = useState<boolean>(false);
  const [delete_open, setDeleteOpen] = useState<boolean>(false);

  return (
    <Box sx={area_bar_style}>
      <Stack justifyContent="space-between" sx={{ width: "100%", height: "100%" }}>
        <div style={btn_area_style}>
          {
            areas.map((area, index) => <Button
              key={index}
              variant="outlined"
              sx={area_btn_style(getCounterColor((area?.number ?? 1) - 1), index === current_area)}
              onClick={() => setArea(index)}>
              {area?.name ?? ""} </Button>)}
          <Button key="Add" variant="outlined" sx={area_add_style} onClick={() => setCreateOpen(true)}>Add +</Button>
        </div>
        <Button disabled={current_area === 0} variant="contained" color="error" onClick={() => setDeleteOpen(true)} sx={{ borderRadius: 0, marginBottom: "0px" }}>
          {`Delete ${areas[current_area]?.name ?? ""}`}
        </Button>
      </Stack>
      <CreateStoreArea open={create_open} setOpen={setCreateOpen} />
      <DeleteStoreArea area={areas[current_area]} open={delete_open} setOpen={setDeleteOpen} current_area={current_area} setCurrentArea={setArea} />
    </Box>
  )
}

