/// TYPES ///
import { CSSProperties } from "react";
import { Variants } from "framer-motion";

export const SRowIcon = {
  height: 16,
  width: 16
}

export const row_btn_style: CSSProperties = {
  width: "100%",
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: "3px",
  paddingBottom: "3px",
  marginLeft: 0,
  border: "none",
  borderBottom: "1px solid var(--mui-palette-action-disabledBackground)",
  backgroundColor: "white",
  cursor: "pointer"
}

export const row_text_item_style: CSSProperties = {
  paddingLeft: "5px",
  paddingBottom: "3px",
  paddingTop: "3px",
}

export const row_text_motion: Variants = {
  rest: {
    backgroundColor: "var(--mui-palette-background-paper)"
  },
  hover: {
    backgroundColor: "var(--mui-palette-action-disabled)",
    transition: {
      duration: 0.4,
      color: "1.5 ease",
      backgroundColor: "1.5s ease"
    }
  }
}

export const row_header_style: CSSProperties = {
  borderBottom: "1px solid var(--mui-palette-action-disabledBackground)",
  paddingBottom: "2px"
}

export const row_style: CSSProperties = {
  borderBottom: "1px solid var(--mui-palette-action-disabledBackground)"
}
